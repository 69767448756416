// import BookingCofirm from "@/components/bookingCofirm.vue";

export default {

  // Headers Navbar 
  headerContents: {
    myBooking: 'Mis Reservas',
    account: 'Cuenta',
    login: 'Acceso',
    register: 'Registro',
  },
  // Headers Navbar End


  // Home Page 
  homePageContent: {
    welcomeMsg: {
      text1: 'La mejor experiencia turística',
      text2: 'Encuentra y reserva la mejor',
      text3: 'Listos para despegar ? ¡Deje volar sus sueños con nuestra perfecta experiencia de reserva de vuelos!',
    },
   
    contentSection: {
      heading1: 'Increíbles ofertas de viajes y más',
      subHeading1: ' Explora lo mejor de los viajes con nuestras ofertas exclusivas para',
      heading2: 'Ofertas de vuelos destacados',
      subHeading2: ' Todo lo que quieres y más',
      heading3: 'Vuelos a los principales destinos',
      subHeading3: 'Todo lo que quieres y más',
      heading4: 'Ofertas Increíbles Mejores Precios',
      subHeading4: 'Busque y reserve en 3 sencillos pasos',
      heading5: 'Sólo Experiencias Únicas',
      subHeading5: 'Recomendaciones',
      subheading: '¿Cómo funciona?',
    }
  },
  // Home Page End


  // Forms Content Both Home and Result Page 
  formsContents: {
    labels: {
      departure: 'Origen',
      arrival: 'Destino',
      selectDate: 'Seleccione fecha',
    },
    options: {
      oneWay: 'De una sola mano',
      return: 'Devolver',
      multiCity: 'Multi ciudad',
      adult: 'Adulta',
      child: 'Niña',
      infants: 'Bebés',
      travellers: 'Viajera(s)',
      economy: 'Economía',
      premiumEconomy: 'Economia Premium',
      business: 'Negocio',
      firstClass: 'Primera clase',
      done: 'Hecho',
      addCity: 'Agregar ciudad',
      clearAll: 'Limpiar todo',
      search: 'Buscar',
    }
  },

  // Forms Content Both Home and Result Page End


  // Loader Page 
  loaderPageContent:{
    loading:'CARGANDO',
    loadMsg1:'Encuentre nuestro precio más bajo a destinos en todo el mundo garantizados',
    loadMsg2:'Buscar seleccionar y guardar. la forma más rápida de reservar tu viaje',
    loadMsg3:'Obtenga soporte galardonado y ofertas especiales',
  },
  // Loader Page End

  // Result Page 
  searchPageContent:{
    filterContent:{
      filterSearch : 'FILTRA TU BÚSQUEDA',
      stops : 'Stops',
      fareType : 'Paradas',
      priceRange : 'Rango de precios',
      avgperPax : 'Promedio por persona',
      airlines : 'aerolíneas',
      selectAll : 'Seleccionar todo',
      clearAll : 'Limpiar todo',
      more : 'Más',
      showless : 'Muestra menos',
      depFrom : 'Salida desde',
      arrivalAt : 'Llegada a',
      resetFilter : 'Restablecer filtros',
    },
    itinContent:{
      depature : 'Partida',
      return : 'Devolver',
      nonRefund : 'No reembolsable',
      refund : 'Reintegrable',
      flightDetails : 'Detalles del vuelo',
    },

    flightDetails:{
      cabin:'Cabina',
      overView:'DESCRIPCIÓN GENERAL',
      baggage:'EQUIPAJE',
      fareDetils:'DETALLES DE TARIFAS',
      depature:'Partida',
      arrival:'Llegada',
      layoverTime:'Tiempo de escala',
      bookFlight:'Reserve este vuelo',
    },
  },
  // Result Page End


  // Fare Details All Pages
  fareDetContent:{
    fareDetails : 'Detalles de tarifas',
    passenger : 'Pasajero',
    adult : 'Adulta',
    child : 'Niña',
    infrant : 'derrotado',
    checkIn : 'REGISTRARSE',
    grandTotal : 'Gran total',
    fareSummary : 'Resumen de tarifas',
    basePrice : 'Precio base',
    taxesFees : 'Taxes & Fees',
  },
   // Fare Details All Pages End


  // Flight Details All Pages
  flightDetContent:{
    contents:{
      flightDetails : 'Detalles del vuelo',
      backSearch : 'Volver a la búsqueda',
      depart : 'SALIR',
      return : 'DEVOLVER',
      changePlanes : 'Cambiar de avión en',
      connectTime : 'Tiempo de conexión',
      connectFlight : 'El vuelo de conexión puede salir de una terminal diferente',
      continue : 'Continuar',
    },
  },
  // Detalles del vuelo All Pages End


  // FLightInfo Page 
  flightInfoContent:{
    contents:{
      travellerDetails : 'Detalles del viajero',
      adult: 'Adulta',
      child: 'Niña',
      infants: 'Bebés',
      contactInfo : 'Información del contacto',
      subHaed : 'Nota: Todas las comunicaciones relacionadas con la reserva se enviarán a esta dirección de correo electrónico y móvil.',
      acknowledgement : 'Reconocimiento',
      privacyPolicy : 'política de privacidad',
      termsCondition : 'Términos y condiciones',


      labels:{
        firstName : 'Nombre de pila',
        middleName : 'Segundo nombre',
        lastName : 'Apellido',
        gender : 'Género',
        dateofBirth : 'Fecha de nacimiento',
        year : 'Año',
        month : 'Mes',
        date : 'Fecha',
        countryCode : 'Código de país',
        phoneNumber : 'Número de teléfono',
        emailId : 'Identificación de correo',
      }
    }
  },
  // FLightInfo Page End


  // Payment Page 
  billingDetContent:{
    headingContent:{
      billingHeading : 'Detalles de facturación',
      paymentHeading : 'Elija el modo de pago',
      bookingDetHeading : 'Detalles de facturación',
      paymentSub : 'Sus pagos están protegidos por certificado SSL',
    },
    labelsContent:{
      fullName : 'Nombre completo',
      country : 'País',
      region : 'Región',
      state : 'Estado',
      Province : 'Provincia',
      city : 'Ciudad',
      addressLine : 'Dirección',
      postelCode : 'Código Postal',
      countryCode : 'Código de país',
      phoneNumber : 'Número de teléfono',
      email : 'Correo electrónico',
      altContact : 'contacto alternativo',
      phone : 'Teléfono',
    },
    paymentMode:{
      heading : 'Paga con tarjeta de crédito',
      subHead : 'Tarjeta de crédito',
      payNow : 'Pagar ahora',
      payNowInfo : 'Al hacer clic en "Pagar ahora", su tarjeta se utilizará para confirmar la reserva.',
      lebelsContent:{
        cardNumber : 'Número de tarjeta',
        NameonCard : 'Nombre en la tarjeta',
        expiryDate : 'Fecha de caducidad',
        cvvCode : 'codigo CVV',
        cvvInfo : '3 dígitos impresos en el reverso de la tarjeta.'
      }
    }
  },
  // Payment Page End


  // Booking Details All Pages
  bookingDetContent:{
    traveller: 'Viajera',
    contactDetails : 'Detalles de contacto',
  },
  // Booking Details All Pages End


  // BookingCofirm Page
  bookingviewContent:{
    statusText : '¡Tu reserva está confirmada!',
    statusSubText : 'Gracias por reservar con Traveljuntions.co. Pronto recibirás una confirmación por correo electrónico.',
    headings:{
      bookingID : 'ID de reserva',
      bookedOn : 'Reservado el',
      depature : 'Partida',
      return : 'Devolver',
      flightdetails : 'Detalles del vuelo',
      tripID : 'ID de viaje',
      bookingDate : 'Fecha para registrarse',
      bookingDetails : 'Detalles de la reserva',
      manageTrip : 'Administrar viaje',
      travellerDetails : 'Detalles del viajero',
      contactDetails : 'Detalles de contacto',
    },
    buttonsText:{
      cancelBook : 'Cancelar reserva',
      modifyBook : 'Modificar reserva',
      print : 'Imprimir',
      startLive : 'Iniciar chat en vivo',
    },
    manageTripContent:{
      heading : 'Para mas información contactenos',
      subHeadings: {
        text1 : 'Cuanto cuesta',
        text2 : 'Cómo cambiar un billete',
        recommended : 'Recomendada',
        reachoutEmail : 'Comuníquese con nosotros por correo electrónico a',
      },
      chat : 'Charlar',
      checkBox : 'Al utilizar este chat, permito que mis datos se almacenen para fines de seguridad y revisión de calidad como se menciona en la Política de Privacidad.',
      emailInfo : 'Nos comunicaremos con usted lo antes posible. No dude en contactarnos para cualquier consulta o soporte.',
      chatNotes : 'Tenga en cuenta que solo se creará un ticket de soporte para las consultas enviadas a través de los canales enumerados en esta página. No responderemos a los mensajes recibidos fuera de estas opciones, incluidos, entre otros, los enviados por correo postal y enviados directamente a los empleados.'
    }
  },
    // BookingCofirm Page End


  // LoginPage 
  loginContents:{
    signIn : 'Iniciar sesión',
    subHaed : 'Inicie sesión y obtenga muchas recompensas y descuentos en reembolsos',
    labels:{
      emailAddress : 'Dirección de correo electrónico',
      password : 'Contraseña',
      rememberMe : 'Acuérdate de mí',
    },
    buttonsLink:{
      login: 'Acceso',
      or: 'O',
      createAccount: 'Crear una nueva cuenta',
      forgotPassword: 'Has olvidado tu contraseña',
    },
    successNotify : {
      text1 : 'Iniciar sesión exitosamente',
    },
    errorNotify : {
      text1 : 'Usuario no encontrada',
      text2 : 'Por favor ingrese los detalles correctos',
    },
  },

  // LoginPage End


  // Register Page
  registerContents:{
    create : 'Crear',
    account : 'Cuenta',
    subHaed : 'Por seguridad, inicie sesión para acceder a su información.',
    labels:{
      name : 'Nombre',
      emailId : 'Identificación de correo',
      password : 'Contraseña',
      confirmPassword : 'confirmar Contraseña',
      byClick : 'Al hacer clic en registrarse, entiendo y acepto viajar por el mundo.',
    },
    buttonsLink:{
      register: 'Registro',
      login: 'Acceso',
      alreadyAccount : '¿Ya tienes una cuenta?',
    },
    successNotify : {
      text1 : 'Iniciar sesión exitosamente',
    },
    errorNotify : {
      text1 : 'Usuario no encontrada',
      text2 : 'Por favor ingrese los detalles correctos',
    },
  },

  // Register Page End


  // Loader Page 
  loaderContent:{
    confirmText : 'Espere mientras confirmamos su reserva.',
    retrieveText : 'Espere mientras recuperamos su reserva.',
  },

  myBookingContent :{
    heading:'VER TU RESERVA',
    labels:{
      bookId:'ID de reserva',
      lastName:'Apellido',
    },
    buttons:{
      submit:'Entregar',
      or:'O',
      returnHome:'Vuelve a casa',
    }
  },

   // Loader Page End


  // Footer Page 
  footerContents: {
    footerText: "Manténgase actualizado con las últimas noticias de viajes y promociones continuas. Prometemos no enviar spam y no compartiremos sus datos con ningún tercero.",
    enterEmail: 'Introduce tu dirección de correo electrónico',
    erroField1: 'Este campo es obligatorio',
    erroField2: 'Por favor, introduce una dirección de correo electrónico válida',
    aboutUs: 'Sobre nosotras',
    about: 'Acerca de',
    contact: 'Contacta con nosotras',
    legal: 'Legal',
    privacyPolicy: 'Política de privacidad',
    followUs: 'SÍGANOS',
  },
   // Footer Page End


};

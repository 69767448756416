<template>


  <section class="p-5 vh-100 d-flex flex-column align-center">
    <div class="mb-5">
      <v-img :src="portalLogo" style="width:250px;margin:auto;"></v-img>
    </div>
    <div>
    <v-card class="py-4 p-3 datacard" style="max-width:600px">
      <div v-if="oneway || roundtrip">
          <div v-if="oneway || roundtrip" class="row text-center align-center">
          <div class="col-md-4 col-4">
            <p class="fw-700">{{ fromData }}</p>
            <p class="f-size-14 fw-400" style="color:grey;" v-if="oneway ? false : true">{{ dedate }}</p>
          </div>


          <div class="col-md-4 col-4">
            <v-img :src="require('@/assets/flightDotted_1.png')" width="280px" class="m-auto"></v-img>
            <!-- <v-icon size="32" style="transform:rotate(90deg)">mdi-airplane</v-icon> -->
          </div>


          <div class="col-md-4 col-4">
            <p class="fw-700">{{ toData }}</p>
            <p class="f-size-14 fw-400" style="color:grey;" v-if="roundtrip">{{ returnDate }}</p>
          </div>
        </div>

        <p class="text-center my-4 py-2 f-size-14 fw-500" 
        style="color: grey;background: aliceblue;">
        {{ !returnDate ? dedate + ' /' : null  }} {{parseFloat(adultcount) + parseFloat(childcount) + parseFloat(infantcount) }} Passenger(s)
        / {{ classType }} / {{ travelType === "RT" ? "Round" : "Oneway"}}-Trip</p>
      </div>


      <div v-if="multi">
          <div class="row text-center align-center" v-for="(data,index) of multiData.itinery" :key="index">
            <div class="col-md-4  col-4">
              <p class="fw-700">{{ data.from }}</p>
            </div>


            <div class="col-md-4  col-4">
              <v-img :src="require('@/assets/flightDotted_1.png')" width="280px" class="m-auto"></v-img>
              <p class="f-size-14 fw-500"> {{ getDate(data.depdate) }}</p>
              <!-- <v-icon size="32" style="transform:rotate(90deg)">mdi-airplane</v-icon> -->
            </div>


            <div class="col-md-4  col-4">
              <p class="fw-700">{{ data.to }}</p>
            </div>
          </div>
        

          <p class="text-center py-2 f-size-14 fw-500" 
            style="color: grey;background: aliceblue;">
            {{parseFloat(adultcount) + parseFloat(childcount) + parseFloat(infantcount) }} Passenger(s)
             / {{ classType }} / Multicity</p>
      </div>


      <div class="row mt-4">
        <div class="col-md-4 col-4 d-flex justify-center border-grey border-dotted-1 border-right">
          <div class="position-relative" style="width: 100px;">
            <div class="circle"></div>
          <div class="message">
            <p class="fw-600">L O A D I N G...</p>
          </div>
          </div>
        </div>
        <div class="col-md-8 col-8">
            <div class="slideset1">
              <div style="width: 100%" align="center">

                <p class="fw-500">{{ $t('loaderPageContent.loadMsg1') }}.</p>
              </div>

              <div style="width: 100%" align="center">
                <p class="fw-500">{{ $t('loaderPageContent.loadMsg2') }}.</p>
              </div>

              <div style="width: 100%" align="center">
                <p class="fw-500">
                  {{ $t('loaderPageContent.loadMsg3') }}.
                </p>
              </div>
            </div>
          </div>
      </div>

    </v-card>
    </div>
    </section>
</template>
<script>
import moment from "moment";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
export default {
  props: {
    searchCase: String
  },
  data() {
    return {
      portalLogo: "",
      fromData: "",
      toData: "",
      dedate: "",
      returnDate: "",
      tripType: "",
      adultcount:"", 
      childcount: "",
      infantcount: "",
      classType: "",

      multi: false,
      roundtrip: false,
      oneway: false,

      multiData:[],
      searchData:"",
      items: [
        {
          src: require("@/assets/caro1.jpg"),
        },
      ],
      
      progloader:true,
    };
  },


  methods: {

    loadSearchData(){
      const tabId = sessionStorage.getItem("tabId");
      const tripType = this.$route.query.tripType;
      console.log(this.searchCase,'storedDatasdasdasdas...1')

      let key = '';

      if (tripType === 'RT' || tripType === 'OW' ) {
        key = tripType === 'RT'? `searchedData-round-${tabId}` : `searchedData-oneway-${tabId}`;
        this.oneway = tripType === 'OW';
        this.roundtrip = tripType === 'RT';
        // console.log(key,'storedDatasdasdasdas...2');
      }
      else if (tripType === 'MC') {
        key = `searchedData-multicity-${tabId}`;
        this.multi = true;
        // console.log(key,'storedDatasdasdasdas...3');
      }

      const storedData = localStorage.getItem(key);
      // console.log(storedData,'storedDatasdasdasdas...4')


      if (storedData) {
        if (this.multi) {
          this.multiData = JSON.parse(storedData) || this.searchCase;
          this.adultcount = this.multiData.adult;
          this.childcount = this.multiData.child;
          this.infantcount = this.multiData.infant;
          this.classType = this.multiData.class;
          // console.log(this.multiData,'storedDatasdasdasdas...5');
          console.log(this.adultcount,this.childcount,this.infantcount,'storedDatasdasdasdassdsds...5');

        } else {
          this.searchData = JSON.parse(storedData) || this.searchCase;
          console.log(this.searchData,'storedDatasdasdasdas...6');

          this.fromData = this.searchData.from;
          this.toData = this.searchData.to;
          this.dedate = moment(this.searchData.dedate).format("DD MMM-YYYY");

          if(tripType === 'RT'){
            this.returnDate = moment(this.searchData.redate).format("DD MMM-YYYY");
          }
          this.adultcount = this.searchData.adult;
          this.childcount = this.searchData.child;
          this.infantcount = this.searchData.infant;
          this.classType = this.searchData.class;
          this.travelType = tripType;

        }
      } else {
        console.log('No search data found for this tab.');
      }

      console.log(this.dedate, "mutiDatamutiData");
    },

    getDate(data){
      return moment(data).format("DD MMM-YYYY")
    },
    getConfigFunc() {
      const getConfigData = getConfigDataFromLocalStorage();

      console.log(getConfigData, "LOADERRR");

      if (getConfigData) {
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;
        console.log(this.portalLogo,'asdasdaportalLogo');
        
      }
    },
  },
  watch: {
    searchCase(newValue) {
      // this.searchData = newValue;
      if (newValue) {
        this.loadSearchData(); 
      }
    },
  },

  
  mounted() {
    this.getConfigFunc();
    this.loadSearchData();

    // this.interval = setInterval(() => {
    //     if (this.loaderValue === 100) {
    //       return (this.loaderValue = 0)
    //     }
    //     this.loaderValue += 10
    //   }, 1500)
  },





  // methods: {
  //   data1() {
  //     let resul = [];
  //     resul = JSON.parse(localStorage.getItem("rountData"));
  //     // console.log(resul, "ppppppppp");
  //     if (resul.from.name) {
  //       this.oneway.from = resul.from.name;
  //     } else {
  //       this.oneway.from = resul.from;
  //     }
  //     if (resul.to.name) {
  //       this.oneway.to = resul.to.name;
  //     } else {
  //       this.oneway.to = resul.to;
  //     }
  //     this.oneway.dedate = moment(resul.dedate).format("DD MMM-YYYY");
  //     if (resul.redate) {
  //       this.oneway.redate = moment(resul.redate).format("DD MMM-YYYY");
  //     } else {
  //       this.oneway.redate = moment(resul.dedate).format("DD MMM-YYYY");
  //     }

  //     this.oneway.adult = resul.adult;
  //     this.oneway.infrant = resul.infrant;
  //     this.oneway.child = resul.child;
  //     this.oneway.class = resul.class;
  //   },
  // },
  // created() {
  //   this.data1();
  // },
};
</script>
<style scoped>
body {
  font-family: Arial, Helvetica, sans-serif;
}


p {
  margin: 0;
}

.main-section {
  width: 100%;
  /* height: 500px; */
}

/* loader main */
.con {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 30px;
  max-width: 735px;
  margin: auto;
}

.loader-main {
  border-bottom: 1px dashed rgba(17, 17, 26, 0.5);
}

.loader-main p {
  font-size: 15px;
  font-weight: 700;
}

.flight-img {
  width: 250px;
}

.info-img {
  width: 45px;
}

/* .detail-png img{
      margin-left: 50px;
  } */
.detail-png .para1 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
}

.text-center.para2 {
  font-size: 12px;
}

.border-right {
  border-right: 1px dashed rgba(17, 17, 26, 0.5);
}

.message {
  font-size: 12px;
  font-weight: 400;
}

.progress {
  height: 7px !important;
  background: none;
  border: 1px solid #002a5f !important;
  overflow: visible !important;
  z-index: -9999;
  position: relative;
  max-width: 700px !important;
  margin: 0% auto;
}

.progress-bar {
  background-color: #002a5f;
  z-index: 1;
  position: relative;
  overflow: visible !important;
  animation: progressLineTransmission 10s linear both;
}

.slideset1 {
  /* height: 10em; */
  position: relative;
  color: black;
  /* margin: auto; */
  display: flex;
  /* justify-content: center; */
  top: 24px;
  text-align: center;
}
/* .slideset1 {
  height: 10em;
  position: relative;
  color: white;
  margin: auto;
  display: flex;
  justify-content: center;
  top: 50px;
} */


.slideset1>* {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  animation: 6s autoplay1 infinite
}

@keyframes autoplay1 {
  0% {
    visibility: visible
  }

  33.33% {
    visibility: hidden
  }
}

.slideset1>*:nth-child(1) {
  animation-delay: 0s
}

.slideset1>*:nth-child(2) {
  animation-delay: 2s
}

.slideset1>*:nth-child(3) {
  animation-delay: 4s
}

>>>.v-progress-circular--indeterminate>svg {
  animation: progress-circular-rotate 100s linear infinite;
  transform-origin: center center;
  transition: all 100s ease-in-out;
}

@keyframes progressLineTransmission {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.progress::before {
  content: "";
  display: inline-block;
  font-family: "Font Awesome 5 Free";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #002a5f;
  border: 2px solid #002a5f;
  box-shadow: rgba(255, 255, 51, 0.5) 0px 25px 50px -1px;
  position: absolute;
  bottom: -3px;
  left: -5px;
  z-index: 9999999;
  overflow: visible;
}

.progress::after {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #002a5f;
  border: 2px solid #002a5f;
  box-shadow: rgba(255, 255, 51, 0.5) 0px 25px 50px -1px;
  position: absolute;
  bottom: -3px;
  right: -5px;
  z-index: 9999999;
  overflow: visible;
}

.air-plane {
  position: relative;
  bottom: 12px;
  font-size: 20px;
  font-weight: bold;
}


>>>.v-img__img,
.v-img__picture,
.v-img__gradient,
.v-img__placeholder,
.v-img__error {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 50% !important; */
}

@media only screen and (max-width:570px) {
  .content-rev {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 480px) {
  .air-plane {
    position: relative;
    bottom: 1px;
    font-size: 14px;
  }

  .air1 {
    display: flex;
    justify-content: flex-end;
  }

  .from {
    font-size: 12px;
  }

  .datacard div p{
    font-size: 10px;
  }
}


body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@keyframes spinner-rotate {
  100% {
    transform: rotate(1turn);
  }
}

.spinner {
  position: relative;
  z-index: 0;
  height: 250px;
  width: 168px;
  border-radius: 81px;
  overflow: hidden;
  padding: 10px;

  &::before {
    content: '';
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #002d5b;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#002d5b, #002d5b), linear-gradient(#002d5b, #002d5b),
      linear-gradient(#002d5b, #002d5b), linear-gradient(#fff, #fff);
    animation: spinner-rotate 1s linear infinite;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 5px;
    top: 5px;
    width: calc(100% - 9px);
    height: calc(100% - 9px);
    background: #002d5b;
    border-radius: 75px;
    /* color:#fff; */
  }
}















/* loading Style */

.circle {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* position: absolute; */
  margin: auto;
  width: 100px;
  height: 100px;
}


.message {
    top: 0px;
    /* bottom: 46px; */
    /* left: 38px; */
    right: 0;
    position: absolute;
    margin: auto;
    width: 100px;
    height: 100px;
}

.message {
  text-align: center;
  font-family: Impact, Charcoal, sans-serif;
  padding-top: 40px;
  -webkit-animation: fonthide 1.3s linear infinite;
  animation: fonthide 1.3s linear infinite;
}

.circle {
  border-radius: 100%;
  border: 6px solid white;
  border-left: 6px solid black;
  border-right: 6px solid black;
  animation: spin 1.3s linear infinite;
  -webkit-animation: spin 1.3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fonthide {
  0% {
    color: white;
  }

  100% {
    color: black;
  }
}

@-webkit-keyframes fonthide {
  0% {
    color: white;
  }

  100% {
    color: black;
  }
}


/* loading Style End */
</style>
<template>

  <v-container style="max-width:1400px !important">
    <div>
      <div class="row mb-4">
        <div class="col-md-3">
          <v-card>
            <div class="d-flex justify-center p-4">
              <div class="d-flex flex-column align-center">
                <v-avatar color="info">
                  <v-icon icon="mdi-account-circle"></v-icon>
                </v-avatar>

                <!-- <h4>Sam karan</h4> -->
                <p class="m-0">{{ usermail }}</p>
                <p class="m-0">{{ userName }}</p>
              </div>
            </div>

            <v-layout>
              <v-navigation-drawer class="bg-deep-purple" theme="dark" permanent style="width:100%;">
                <div class="p-3">
                  <v-list>
                    <v-list-item class="nav-list book-back" @click="mybooking = !mybooking">
                      <div class="d-flex justify-content-between align-items-baseline">
                        <div>
                          <v-icon size="22">mdi-card-account-details</v-icon>
                          <span class="ms-3 f-size-16 fw-600">My bookings</span>
                        </div>
                        <div>
                          <v-icon size="20">{{ mybooking ? "mdi-menu-up" : "mdi-menu-down" }}</v-icon>
                        </div>
                      </div>
                    </v-list-item>

                  </v-list>
                </div>

                <template v-slot:append>
                  <div class="pa-2">
                    <v-btn block>
                      Logout
                    </v-btn>
                  </div>
                </template>
              </v-navigation-drawer>
              <v-main style="height: 400px"></v-main>
            </v-layout>
          </v-card>
        </div>

        <div class="col-md-9">
          <div class="row">
            <div class="col-md-4" v-for="items in bookingItems" :key="items">
              <v-card>
                <div class="d-flex p-3">
                  <v-icon size="50">{{ items.icons }}</v-icon>
                  <div class="ms-3">
                    <p style="font-size:22px;" class="mb-0 fw-600">{{ items.bookHead }}</p>
                    <p style="font-size:20px;" class="mb-0 fw-600">{{ bookingdata.length }}</p>
                  </div>
                </div>
              </v-card>
            </div>
          </div>

          <div class="mt-3">
            <v-card class="p-3">
              <div class="col-md-3 my-2">
                <h3>My Bookings</h3>
              </div>

              <v-divider></v-divider>

              <div>
                <table class="table table-hover text-center table-responsive">
                  <thead>
                    <tr>
                      <th scope="col">S.no</th>
                      <th scope="col">Booking Id</th>
                      <th scope="col">Booking Date</th>
                      <th scope="col">Travel Segment</th>
                      <th scope="col"> Travel Date</th>
                      <th scope="col">Booking Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="data-list" v-for="(details, index) of bookingdata" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td style="font-size:13px">{{ details.booking_reference }}</td>
                      <td style="font-size:13px">{{ details.booking_datetime }}</td>
                      <td style="font-size:13px">{{ details.travel_segment }}</td>
                      <td style="font-size:13px">{{ details.travel_date }}</td>
                      <td style="font-size:13px">{{ details.total_fare }}</td>
                      <td style="font-size:13px">{{ details.booking_status }}</td>
                      <td style="font-size:13px"><v-icon size="20" @click="bookingview(details)">mdi-eye</v-icon></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </v-container>
  <div v-if="overlay">
    <dataloader></dataloader>
  </div>

</template>


<script>
import moment from "moment";
import axios from "axios";
import dataloader from "@/components/dataLoder.vue"
import airLogo from "@/airlinesLogo.json";
import userData from "@/airport_icao.json";
import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';
export default {
  components: {
    dataloader
  },
  data() {
    return {
      dialog: false,
      overlay: false,
      mybooking: false,
      usermail: "",
      userName: "",
      bookingItems: [
        {
          icons: "mdi-airplane-takeoff",
          bookHead: "Total Bookings",

        },

      ],


      bookingdata: [],
      userBookinglist: {
        "request_type": "user_booking_listing",
        "pax_email": "qatesting1@vibrace.com",
        "user_id": "123789",
        "portal_id": "port5678",
        "token": "jbsdjhushdj"
      },
      retreivebooking: {
        "request_type": "retreive_booking",
        "booking_id": "1234qwert",
        "pnr": "asddf12",
        "last_name": "ldkdf",
        "portal_id": "port5678"
      },
      retriveData: [],
      itineryData: [],
      routtime: [],
      flightsegmet: [],
      flightsegmetkey: [],
      roundOfferId: [],
      airlogodata: [],
      Journey1: [],
      Journey: [],
      rountresult: [],
      returnData: [],
      tripId: "",
      bookingDate: "",
      baseprice: "",
      currency: "",
      totalprice: "",
      passengerDetail: [],
      depatureData: [],
      arrivalData: [],
      fromAirports: [],
      datareurn: false,
      triptype: "",
      contactnum: "",
      passEmail: "",
      bookingStatus: "",

      bookingListingUrl: "",
    }
  },
  methods: {

    seleceprint() {
      const printContent = document.getElementById("printMe");
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContent.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
    },

    getdob($event) {
      return moment($event).format("YYYY-MMM-DD");
    },
    getdepdate(data) {
      let depdata = data.split("T")
      return moment(depdata[0]).format("MMM DD")
    },
    getdeptime(data) {
      let depdata = data.split("T")
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm")
    },
    getarrdate(data) {
      let depdata = data.split("T")
      return moment(depdata[0]).format("MMM DD")
    },
    getarrtime(data) {
      let depdata = data.split("T")
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm")
    },


    getdepcode(data) {
      console.log(data, 'pppp')
      return data[0].depart_airport
    },
    getarrcode(data) {
      console.log(data, 'pppp')
      return data[data.length - 1].arrival_airport
    },

    getstarttime(data) {
      let depdata = data[0].depart_datetime.split("T")
      return moment(depdata[0]).format("ddd,MMM DD,YYYY")
    },




    getdepdate1(data) {
      let depdata = data.split("T")
      return moment(depdata[0]).format("MMM DD")
    },
    getdeptime1(data) {
      let depdata = data.split("T")
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm")
    },
    getarrdate1(data) {

      let depdata = data.split("T")
      return moment(depdata[0]).format("MMM DD")
    },
    getarrtime1(data) {
      let depdata = data.split("T")
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm")
    },


    getdepcode1(data) {
      return data[0].depart_airport
    },
    getarrcode1(data) {
      return data[data.length - 1].arrival_airport
    },

    getstarttime1(data) {
      let depdata = data[0].depart_datetime.split("T")
      return moment(depdata[0]).format("ddd,MMM DD,YYYY")
    },




    bookinglist() {

      this.userBookinglist.pax_email = this.usermail
      this.userBookinglist.user_id = this.usermail
      console.log(this.bookingListingUrl, 'this.bookingListingUrl')
      axios.post(this.bookingListingUrl, this.userBookinglist,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          if (response.data.data) {
            this.bookingdata = response.data.data
            this.overlay = false
          }

          console.log(response.data.data, "bookinglist");
        })
        .catch((error) => {
          console.log(error, 'bookinglist')
        })
    },
    bookingview(details) {
      //  console.log(details.booking_reference, 'detailsdetails')
      console.log(details, 'pppahajhadjhdba')
      let $data = details.pax_info.split("/")
      let $name = $data[0]
      console.log($name, 'afajha')

      this.$router.push({ path: "/myBooking", query: { Booking_id: details.booking_reference, last_name: $name } })
      // this.overlay=true
      // console.log(details, index)
      // this.retreivebooking.booking_id=details.booking_reference
      // this.retreivebooking.pnr=details.pnr
      // this.tripId=details.booking_reference
      // this.bookingDate=details.booking_datetime


      // console.log( this.retreivebooking,' this.bookingView')


      //     axios.post(
      //       "https://dl.buymytrip.com/api/retrievebooking",this.retreivebooking,
      //       {
      //         headers: {
      //           "Content-Type": "application/json",
      //         },
      //       }
      //     )
      //     .then((response) => {

      //       console.log(response.data, "retrievebooking");
      //       this.overlay=false
      //       this.retriveData=[]
      //               if(response.data){
      //                 this.retriveData.push(response.data)
      //                 this.bookingretrive()
      //               this.dialog=true
      //               }


      //     })
      //     .catch((error)=>{
      //       console.log(error,'retreivebooking')
      //     }) 


    },

    bookingretrive() {
      this.airlogodata = airLogo;
      this.retriveData.forEach(v => {
        console.log(v, 'dtaswww')
        this.baseprice = (v.fare_info.itin_total_fare / v.pax_info.length).toFixed(2)
        this.currency = v.fare_info.itin_currency
        this.totalprice = v.fare_info.itin_total_fare
        this.passengerDetail = v.pax_info
        this.itineryData = v.itinerary
        this.contactnum = v.contact_info.paxphone
        this.passEmail = v.contact_info.paxemail
        this.bookingStatus = v.status
        for (let i = 0; i < this.airlogodata.length; i++) {
          this.itineryData.forEach(a => {

            if (this.airlogodata[i].id == a.carrier) {
              a.logo = this.airlogodata[i].logo;
              a.flightname = this.airlogodata[i].name;
            }
          });
        }

        console.log(this.datareurn, 'this.datareurnthis.datareurn')

        Object.keys(userData).forEach((icao) => {
          const airport = userData[icao];
          if (airport.iata !== "") {
            this.fromAirports.push({
              iata: airport.iata,
              icao: airport.icao,
              places: `${airport.name}`,
            });
          }
        })

        for (let i = 0; i < this.fromAirports.length; i++) {
          this.itineryData.forEach(a => {
            if (this.fromAirports[i].iata == a.depart_airport) {
              a.depAirportName = this.fromAirports[i].places;
            }
            if (this.fromAirports[i].iata == a.arrival_airport) {
              a.arrAirportName = this.fromAirports[i].places;
            }
          });
        }
        this.itineryData.forEach(j => {
          if (j.flight_line == 2) {
            this.triptype = "ROUND TRIP"
          }
          else {
            this.triptype = "ONEWAY TRIP"
          }
        })



        this.itineryData.forEach(s => {
          if (s.flight_line == 2) {
            this.datareurn = true
            this.arrivalData.push(s)
          }
          else if (s.flight_line == 1) {
            this.depatureData.push(s)
          }
        })
        console.log(this.depatureData, 'this.depatureData')
        console.log(this.arrivalData, 'this.depatureData')

      })
    },

    getConfig() {

      // let getConfigData1 = JSON.parse(localStorage.getItem("portalData"))
      const getConfigData = getConfigDataFromLocalStorage();
      console.log(getConfigData, 'getConfigDatagetConfigData')
      if (getConfigData) {
        this.bookingListingUrl = getConfigData.payload.portal_configuration.API_endpoints.booking_listing
        console.log(this.bookingListingUrl, 'head...reg..1..')
      }
    },
  },


  created() {
    this.getConfig();

    this.overlay = true
    let $useresult = JSON.parse(localStorage.getItem('loginuser'))
    // console.log($useresult, '$useresult $useresult ')
    this.usermail = $useresult.user.email
    this.userName = $useresult.user.name
    this.bookinglist()


    //    let datas= localStorage.getItem("bookview")
    //    this.retriveData=JSON.parse(datas) 

    //    console.log(this.retriveData,'00000000000')
    //    this.bookingretrive()
  }
}
</script>

<style scoped>
.f-size-16 {
  font-size: 16px;
}

.fw-600 {
  font-weight: 600;
}

.nav-list {
  cursor: pointer;
}

.dropdown-list {
  cursor: pointer;
}

.data-list {
  cursor: pointer;
}


/* .dropdown-list {
  transition: all 0.5s ease-in-out; 
  max-height: 0; 
  overflow: hidden;
  opacity: 0;
} */

.nav-list:hover .dropdown-list {
  max-height: 500px;
  opacity: 1;
  transition-delay: 0.5s;
}

.book-back {
  background-color: #ff5400;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.booking-subtitle {
  font-size: 18px;
  font-weight: 600;
}

/* .booking-text p{
  font-size: 17px;
  font-weight: 400;
} */

.booking-text .book-span {
  color: #0768cf;
  cursor: pointer;
}

.booking-contacts {
  background-color: #f6f6f6;
}

.booking-contacts span {
  font-size: 18px;
}

.Booking-id-head {
  font-size: 14px;
  color: gray;
}

.Booking-id {
  font-size: 26px;
  font-weight: 700;
}

.v-card {
  border-radius: 10px !important;
}

.header-btn {
  background-color: transparent;
  text-transform: capitalize;
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
}

.custom-icon {
  width: 37px;
  height: 37px;
  border: 1px solid orange;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flight-det {
  font-size: 25px;
  font-weight: 600;
}

/* Departure & Return Section */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size {
  font-size: 16px;
}

.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.contion {
  display: none;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.provide-border {
  border-left: 1px solid lightgray;
}

>>>.v-timeline--vertical.v-timeline {
  grid-row-gap: 3px;
  height: auto !important;
}

.f-family {
  font-family: sans-serif;
  font-size: 15px;
}

.BuyMyTrip1 {
  display: none;
}

/* Departure & Return Section */

@media only screen and (max-width: 992px) {
  .check-icon {
    margin-right: 40px;
  }

  .book-detail {
    text-align: left;
    padding-top: 10px;
  }

  .flight-det {
    font-size: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .provide-border {
    border-left: none;
  }

  .class-box {
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 480px) {
  .print-btn-2 {
    display: none;
  }

  .mobile-table {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .contion {
    display: block;
  }

  .BuyMyTrip {
    display: none;
  }

  .BuyMyTrip1 {
    display: block;
  }

  .mobile-table {
    display: block;
  }

  .desktop-table {
    display: none;
  }

  .print-btn-1 {
    display: none;
  }

  .print-btn-2 {
    display: flex;
  }

  .f-family {
    font-size: 12px;
  }

  .airlogo-size {
    width: 25px !important;
  }

  .airline-name {
    font-size: 10px;
  }

  .f-size-16 {
    font-size: 16px;
  }

  .TERMS {
    display: none;
  }

  .f-size-14 {
    font-size: 14px;
  }

  .f-size-13 {
    font-size: 13px;
  }

  .f-size-12 {
    font-size: 12px;
  }

  .f-size-11 {
    font-size: 11px;
  }

  .f-size-10 {
    font-size: 10px;
  }

  .f-size-9 {
    font-size: 10px;
  }

  .fw-500 {
    font-weight: 600;
  }
}

.print-btn-1 {
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
  color: green;
  outline: 1px solid;
  width: 130px;
  height: 35px;
  border-radius: 4px;
}

.print-btn-2 {
  font-size: 25px;
  height: 35px;
  width: 35px;
  box-shadow: none;
  margin-top: 4px;
  color: green;
  outline: 2px solid;
  border-radius: 4px;
}
</style>
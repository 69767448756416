import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/js/bootstrap.js';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import i18n from '@/plugins/i18n';

loadFonts();

createApp(App)
  .use(router)
  .use(vuetify)
  .use(i18n)
  .use( AOS.init())
  .mount("#app");



  

<template>
  <footer class="footer" id="footerSec">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-12 col-lg-6 pt-3">
              <img :src="portalLogo" class="img-fluid bg-img" :alt="portalSiteTitle" />
              <p class="text-justify pt-3">
                {{ $t('footerContents.footerText') }}
              </p>
              <!-- <p class="pt-lg-5">
                Get Latest Deals, Upcoming Flight Offers and Cheap Fare
              </p> -->
              <form class="form">
                <div class="row">
                  <div class="col-12">

                    <v-form ref="form" v-model="validData" @submit.prevent="subscribeData">
                    <div class="input-group">
                      <input type="text" v-model="subEmail" @keyup="subData(subEmail)"
                        class="form-control py-3 rounded-0" :placeholder="this.$t('footerContents.enterEmail')"/>

                      <button type="submit" style="width:104px" class="btn btn-outline-light rounded-0 custom-btn-subscribe btn-effect">
                        <v-progress-circular v-if="loadsubscribe" :width="5" color="white" indeterminate></v-progress-circular>
                                            {{ !loadsubscribe ? this.$t("footerContents.subscribe") : '' }}
                      </button>
                    </div>
                    <div>
                      <p v-if="formSubmitted  && !validData && subEmail.trim() === ''" 
                      class="m-0 mt-1 f-size-14 fw-400" style="color:#ffeb31;">{{ $t('footerContents.erroField1') }}</p>

                      <p v-if="formSubmitted && !validData && !/.+@.+\..+/.test(subEmail)" 
                      class="m-0 mt-1 f-size-14 fw-400" style="color:#ffeb31;">{{ $t('footerContents.erroField2') }}</p>
                    </div>
                  </v-form>
                  </div>
                </div>
              </form>
              <div class="col-12 mt-5 d-none">
                <img
                  src="assets/images/icons/tafi.svg"
                  class="img-fluid me-4"
                  alt="tafi"
                  title="tafi"
                />
                <img
                  src="assets/images/icons/taai.svg"
                  class="img-fluid"
                  alt="taai"
                  title="taai"
                />
              </div>
            </div>
            <div class="col-12 col-lg-6 pt-1 d-flex flex-column justify-content-between">
              <div style="display:none;">
                <img
                src="assets/images/icons/iata.svg"
                class="img-fluid d-inline-flex"
                alt="IATA"
                title="IATA"
              />
              <p class="fw-bold text-uppercase mb-0 ms-2 d-inline-flex">
                We are an IATA Certified travel agency
              </p>
              </div>
              <div class="row">
                <div class="col-md-3 col-6 mt-3">
                  <p class="text-uppercase fw-bold mb-1">{{ $t('footerContents.aboutUs') }}</p>
                  <ul class="fl-menu pt-1">
                    <li class="nav-item"><a href="/aboutUs" target="_blank">{{ $t('footerContents.about') }}</a></li>
                    <li class="nav-item">
                      <a href="/contactPage" target="_blank">{{ $t('footerContents.contact') }}</a>
                    </li>
                    <!-- <li class="nav-item"><a href="#">Bank Details</a></li> -->
                  </ul>
                </div>
                <div class="col-md-4 col-6 mt-3">
                  <p class="text-uppercase fw-bold mb-1">{{ $t('footerContents.legal') }}</p>
                  <ul class="fl-menu">
                    <li class="nav-item"><a href="/page/terms-of-use" target="_blank">{{ $t('footerContents.termsUse') }}</a></li>
                    <li class="nav-item"><a href="/page/privacy-policy" target="_blank">{{ $t('footerContents.privacyPolicy') }}</a></li>
                    <!-- <li class="nav-item"><a href="#">T&C</a></li>
                    <li class="nav-item"><a href="#">Disclaimer</a></li>
                    <li class="nav-item">
                      <a href="#">Privacy and Cookies</a>
                    </li>
                    <li class="nav-item"><a href="#">Legal</a></li>
                    <li class="nav-item"><a href="#">Help</a></li>
                    <li class="nav-item"><a href="#">Feedback</a></li> -->
                  </ul>
                </div>
                <!-- <div class="col-md-5 mt-3">
                  <p class="text-uppercase fw-bold mb-1">Products and services</p>
                  <p class="text-uppercase fw-bold">Company</p>
                  <ul class="fl-menu">
                    <li class="nav-item"><a href="#">Cancellation protection</a></li>
                    <li class="nav-item"><a href="#">Partner With Us</a></li>
                    <li class="nav-item"><a href="#">Services</a></li>
                    <li class="nav-item"><a href="#">Careers</a></li>
                    <li class="nav-item"><a href="#">Products</a></li>
                  </ul>
                </div> -->
              </div>
              <div class="col-12 border px-2">
              <div class="row">
                <div class="col-12 col-lg-6 text-start py-3">
                  <p class="d-inline-flex text-uppercase mb-0">{{ $t('footerContents.followUs') }}:</p>
                  <div class="d-inline-flex social ms-2">
                    <a v-for="(data,index) of social_media" :key="index" target="_blank" class="btn-link btn-floating btn-lg text-dark me-2 social-icons"
                      :href="data.url" role="button" data-mdb-ripple-color="dark">
                      <v-icon color="white" size="19"> {{ data.icon_class }} </v-icon>
                    </a>
                    <!-- <a href="#" class="ps-3"><i class="bi bi-facebook"></i></a>
                    <a href="#" class="ps-3"><i class="bi bi-twitter"></i></a>
                    <a href="#" class="ps-3"><i class="bi bi-linkedin"></i></a>
                    <a href="#" class="ps-3"><i class="bi bi-instagram"></i></a> -->
                  </div>
                </div>
                <div class="col-12 col-lg-4 text-lg-center social py-3 d-none">
                  <p class="d-inline-flex text-uppercase mb-0">
                    Support Center:
                  </p>
                  <a :href="'tel:' + phonePortal" class="botom-link"> {{ phonePortal }}</a>
                </div>
                <div class="col-12 col-lg-6 text-lg-end social py-3 ps-0">
                  <p class="d-inline-flex text-uppercase mb-0">
                    <a :href="'mailto:' + emailPortal" class="text-lowercase botom-link ms-2"> {{ emailPortal }}</a>
                  </p>
                  
                </div>
              </div>
            </div>
            </div>
          </div>
          <div class="row g-0 pt-2">
            <div class="col-12 border-top border-bottom d-none">
              <div class="row">
                <div class="col-12 col-lg-4 text-start py-3">
                  <p class="d-inline-flex text-uppercase mb-0">Follow Us</p>
                  <div class="d-inline-flex social">
                    <a href="#" class="ps-3"><i class="bi bi-facebook"></i></a>
                    <a href="#" class="ps-3"><i class="bi bi-twitter"></i></a>
                    <a href="#" class="ps-3"><i class="bi bi-linkedin"></i></a>
                    <a href="#" class="ps-3"><i class="bi bi-instagram"></i></a>
                  </div>
                </div>
                <div class="col-12 col-lg-4 text-lg-center social py-3">
                  <p class="d-inline-flex text-uppercase mb-0">
                    Support Center:
                  </p>
                  <a :href="'tel:' + phonePortal" class="botom-link"> {{ phonePortal }}</a>
                </div>
                <div class="col-12 col-lg-4 text-lg-end social py-3">
                  <a :href="'mailto:' + emailPortal" class="botom-link ms-2"> {{ emailPortal }}</a>
                </div>
              </div>
            </div>
            <div class="col-12 py-2 d-flex flex-row justify-content-between">
              <div class="text-start">
                <p class=" mb-0 font-small">{{ footerAdd1 }}</p>
                <p class="font-small d-none">{{ footerAdd2 }}</p>
              </div>
              <div>
              <p class="text-center mb-0 font-small">
                {{ $t('footerContents.copyright') }}©{{ year }}, {{ footerLegalName }}. {{ $t('footerContents.allRights') }}
              </p></div>
            </div>
          </div>
        </div>
        <p id="back-top" class="back-to-top bg-dark" v-if="showScrollToTop">
          <a ><i class="bi bi-chevron-up" @click="scrollTop"></i></a>
        </p>
      </footer>


      <v-dialog v-model="dialog1">
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">{{ $t("errorContent.dialog1.text1") }}</h3>
        <h5>{{ $t("errorContent.dialog1.text2") }}</h5>
        <h6>{{ $t("errorContent.dialog1.text5") }} {{ emailPortal }}</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <!-- <v-btn @click="(dialog1 = false), (loader = false)" color="#ff6b0a">Try Again</v-btn> -->
          <button type="button" @click="(dialog1 = false), (loader = false)"  class="btn btn-effect btn-book">
            {{ $t("errorContent.button.tryAgain") }}</button>
          <!-- <v-btn to="/" color="#ff6b0a">Return to Home</v-btn> -->
        </div>
      </v-card>
    </div>
  </v-dialog>




      <v-dialog v-model="dialog2">
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">{{ $t("errorContent.dialog3.text1") }}</h3>

        <h6 class="text-center">{{ $t("errorContent.dialog3.text2") }}</h6>

        <div class="d-flex justify-center mt-3">
          <!-- <v-btn @click="(subEmail = null), (subPhonenumber = null), (dialog2 = false)" color="#ff6b0a">Ok</v-btn> -->
          <button type="button" @click="(subEmail = null), (subPhonenumber = null), (dialog2 = false)" class="btn btn-effect btn-book">
            {{ $t("errorContent.button.ok") }}</button>
        </div>
      </v-card>
    </div>
  </v-dialog>



            <!-- <div class="modal fade" :class="dialog2 ? 'show' : ''" id="exampleModal" tabindex="-1" 
                aria-labelledby="exampleModalLabel" aria-hidden="true" :role="dialog2 ? 'dialog' : ''">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Are you sure ?</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="p-5 pb-0 text-center">
                            <p class="font-medium">By clicking on Okay, all your seat, meal and
                                extra baggage selections will be discarded.</p>
                        </div>
                        <div class="modal-footer border-0 justify-content-center pb-5">
                            <button type="button" class="btn btn-effect btn-book" data-bs-dismiss="modal">Yes
                                Sure</button>
                        </div>
                    </div>
                </div>
            </div> -->



</template>

<script>
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

import axios from "axios";

export default {
  data() {
    return {
      portalLogo: "",
      portalSiteTitle: "",
      getInstagram: "",
      getFacebook: "",

      footerMenus: [],
      footMenu: "",
      year: "",
      phonePortal: "",
      emailPortal: "",
      footerAdd1: "",
      footerAdd2: "",
      footerLegalName: "",


      dialog1: false,
      dialog2: false,
      loadsubscribe:false,

      validData:false,
      formSubmitted:false,
      subEmail:"",
      showScrollToTop: false,

      subEmailvalid: [
        (v) => !!v || "This field is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],
      subscribeApi:"",
      social_media:[],
    };
  },

  methods: {
     handleScroll() {
      this.showScrollToTop = window.scrollY > 60;
    },
    scrollTop(){
      window.scrollTo({ top: 0, behavior: "smooth" });
      document.getElementById('back-top').style.display = 'none'
    },

    subscribeData() {


      if (this.subEmail.trim() === '') {
        this.formSubmitted=true;
        return;
      }
      if (!/.+@.+\..+/.test(this.subEmail)) {
        this.formSubmitted=true;
        return;
      }


      let subscribeData = {
        request_type: "subscriber_form",
        email: this.subEmail,
        whatsapp: false,
        token: "djfkdjkf",
      };

      this.formSubmitted = true;

      this.loadsubscribe = true;
      if(this.subEmail) this.validData = true;


      if (this.validData) {
        console.log(this.validData,this.subEmail, "subscribeDatasubscribeData...2");
        this.loader = true;
        axios
          .post(this.subscribeApi, subscribeData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "response");
            this.loader = false;
            this.dialog2 = true;
            this.loadsubscribe = false;
          })
          .catch((error) => {
            console.log(error, "error");
            setTimeout(() => {
              this.dialog1 = true;
              this.loadsubscribe = false;
            }, 500);
          });
      }

      else{
        this.loadsubscribe = false;
        this.formSubmitted = false;
      }
    },
    

    subData($data) {
      console.log($data, "tytttt");
      if ($data.includes("com")) {
        this.formSubmitted=false;
      } else {
        this.formSubmitted=true;
      }
    },


    getConfig() {
      // let getConfigData = this.$store.state.data

      const getConfigData = getConfigDataFromLocalStorage();

      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)
      if (getConfigData) {
        this.portalLogo = getConfigData.payload.portal_configuration.logo_path;
        this.portalSiteTitle = getConfigData.payload.portal_configuration.site_title;
        // this.getInstagram =
        //   getConfigData.payload.portal_configuration.social_media_links.instagram;
        // this.getFacebook =
        //   getConfigData.payload.portal_configuration.social_media_links.facebook;

        let social_icons = getConfigData.payload.portal_configuration.social_icons
          if(social_icons){
            this.social_media = social_icons;
          }

        this.phonePortal = getConfigData.payload.portal_configuration.phone;
        this.emailPortal = getConfigData.payload.portal_configuration.email;

        this.footerAdd1 = getConfigData.payload.portal_configuration.portal_footer_address1;
        this.footerAdd2 = getConfigData.payload.portal_configuration.portal_footer_address2;
        this.footerLegalName = getConfigData.payload.portal_configuration.portal_legal_name;

        // let footMenu=""
        this.footMenu =
          getConfigData.payload.portal_configuration.menus.enabled.footer;
        this.subscribeApi = getConfigData.payload.portal_configuration.API_endpoints.subscriber_form
        // this.footerMenus = footMenu.split(',')

        // console.log(this.footMenu, 'fmfmfmfmfm...')
      }
    },
  },

  mounted() {
    // this.getConfig();
    window.addEventListener('scroll', this.handleScroll);
  },
//   beforeUnmount() {
//     window.removeEventListener('scroll', this.handleScroll);
// },

  created() {
    this.getConfig();
    const d = new Date();
    this.year = d.getFullYear();
  },
};
</script>

<style scoped>
/* footer */
.social-icons {
  color: var(--primary-color) !important;
}

.fs-14 {
  font-size: 14px;
  list-style-type: none;
}

.fs-14 li {
  padding: 6px;
}

section {
  padding: 10px 5px;
}

.slide-card {
  border-radius: 10%;
}

.card-test {
  position: relative;
  padding: 10px 15px;
  border-radius: 8%;
  max-height: 500px;
}

.card-img {
  position: absolute;
  width: 100px;
  height: 100px;
  top: -50%;
  right: 0;
  transform: translateY(-50%);
}

.radio {
  background-color: #e2e7ff;
  border-radius: 14px;
  color: #007bff;
  width: 120px;
  font-size: 15px !important;
  font-weight: 400px;
}

.normal-radio {
  font-size: 14px !important;
  font-weight: 400px;
  height: 30px;
  width: 130px;
}

.cart-adult {
  padding: 2px 15px 15px 15px;
  width: 450px;
  cursor: pointer;
}

.adult {
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

.p-datepicker-group-container {
  background-color: #ffffff !important;
  width: 200px;
  padding: 5px 3px;
  border: 1px solid lightgray;
  position: relative;
  right: 14px;
}

.calendar-container {
  display: flex;
  align-items: center;
}

.icon-container {
  margin-right: 10px;
}

.calendar-wrapper {
  border-bottom: 1px solid #a9a1a1;
  max-width: 500px;
}

@media only screen and (max-width: 992px) {
  .car-right {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .session-bg {
    background-image: none;
  }

  .back {
    background-image: none;
  }

  .flight-right {
    width: 80% !important;
  }

  .bannar-bg {
    background: none;
  }

  .form-btn {
    padding: 10px 0px 0px 0px !important;
    /* color: white; */
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    /* color: white !important; */
  }
}

.mdi-airplane-takeoff::before {
  content: "\F05D5";
  margin-top: 10px;
}

.mdi-airplane-landing::before {
  content: "\F05D4";
  margin-top: 10px;
}

@media only screen and (max-width: 1200px) {
  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
  }
}
.f-head {
  font-weight: 700;
  font-size: 16px;
}
.f-side {
  text-decoration: underline;
  font-size: 15px;
}
.f-img {
  height: 50px;
  width: 70px;
  filter: contrast(0.5);
}
.bg-img{
    width: 180px;
    height:42px !important;
    background: white;
    padding: 7px;
    border-radius: 15px;
    height:60px;
}
</style>

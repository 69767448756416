import { createRouter, createWebHistory } from "vue-router";
// import parentCompo from '../components/parentCompo.vue'
import pagenot from "../components/pageNot.vue";
import flightInfoPage from "../components/flightInfoPage.vue";
import confirmBook from "../components/confirmBook.vue";
// import TelInput from '../components/telInput.vue';
import bookingCofirm from "../components/bookingCofirm.vue";
import hello from "../components/HomeComponent.vue";
import search from "../components/SearchResult.vue";
import loader from "../components/loaderComponent.vue";
import test from "@/components/testCom.vue";
import termUse from "@/components/termUse.vue";
import aboutUs from "@/components/aboutUs.vue";
import privacyPolicy from "@/components/privacyPolicy.vue";
import contactPage from "@/components/contactPage.vue";
import pageError from "@/components/pageError.vue";
import dashBoard from "@/components/dashBoard.vue";
import myBooking from "@/components/myBooking.vue";
import dataloader from "@/components/dataLoder.vue";
import dotLoader from "@/components/dotLoader.vue";
import modifyPassword from "@/components/modifyPassword.vue";
import bookingView from "@/components/bookingView.vue";
import loginCom from "@/components/loginCom.vue";
import registerCom from "@/components/registerCom.vue";
import flightDetailsCom from "@/components/flightDetailsCom.vue";
import pageLoader from "@/components/pageLoader.vue";
import myProfile from "../components/myProfile.vue";
import viewBooking from "../components/viewBooking.vue";
import seatmap from "../components/seatMapping.vue"
import brantfare from "../components/brantFare.vue"
const routes = [
  {
    path: "/",
    component: hello,
  },
  {
    path: "/home",
    component: hello,
  },
  {
    path: "/seatmap",
    component: seatmap,
  },
  {
    path: "/brantfare",
    component: brantfare,
  },
  {
    path: "/pageNot",
    component: pagenot,
  },
  {
    path: "/dataloader",
    component: dataloader,
  },
  {
    path: "/pageLoader",
    component: pageLoader,
  },
  {
    path: "/dotloader",
    component: dotLoader,
  },
  {
    path: "/flight/search",
    component: search,
  },
  {
    path: "/loginPage",
    component: loginCom,
  },
  {
    path: "/registerPage",
    component: registerCom,
  },
  {
    path: "/loader",
    component: loader,
  },
  {
    path: "/flightInfo",
    component: flightInfoPage,
  },
  {
    path: "/flightDetailsCom",
    component: flightDetailsCom,
  },

  // {
  //     path: '/flightInfo/:pass_id', // Define a dynamic route parameter ':pass_id'
  //     name: 'flightInfo',
  //     component: flightInfo, // Use your FlightInfo component
  //   },
  {
    path: "/confirmBook",
    component: confirmBook,
  },
  {
    path: "/test",
    component: test,
  },
  {
    path: "/bookingCofirm",
    component: bookingCofirm,
  },
  {
    path: "/page/terms-of-use",
    component: termUse,
  },
  {
    path: "/aboutUs",
    component: aboutUs,
  },
  {
    path: "/page/privacy-policy",
    component: privacyPolicy,
  },

  {
    path: "/contactPage",
    component: contactPage,
  },

  {
    path: "/pageError",
    component: pageError,
  },
  {
    path: "/dashboard",
    component: dashBoard,
  },
  {
    path: "/mybooking",
    component: myBooking,
  },
  {
    path: "/myProfile",
    component: myProfile,
  },
  {
    path: "/viewBooking",
    component: viewBooking,
  },
  {
    path: "/modifyPassword",
    component: modifyPassword,
  },
  {
    path: "/bookingView",
    component: bookingView,
  },
  { path: "/:pathMatch(.*)*", name: "NotFound", component: pagenot },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

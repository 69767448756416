<template>

  <div class="container py-3" :class="{'vh-100 invisible' : !pageloader && seatmapError}">
      <div class="row" v-if="!pageloader">
        <div class="col-12 col-md-9">
          <div>
            <div class="d-lg-flex d-none justify-content-between align-items-start mb-3">
              <div class="fw-bold">Request Your Seats (Optional)</div>
            </div>
  
            <div class="col-12">
              <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3 pb-0">
              
                <div v-if="totalSeat.length > 0" class="py-3">
                  <div class="mt-3">
                    <div class="d-flex flex-wrap">
                      <div
                        class="pe-2 mt-1"
                        v-for="(data, index) in totalSeat"
                        :key="index"
                      >
                        <v-btn
                          variant="outlined"
                          :class="
                            flightSelect == index ? 'tru-flight' : 'tru-flightfalse'
                          "
                          @click="selectdata(data, index)"
                        >
                          <div>{{ data.origin }}-{{ data.destination }}</div>
                        </v-btn>
                      </div>
                    </div>
                  
                    <!-- <div class="d-flex flex-wrap mt-2">
                      <div
                        class="pe-2 mt-1"
                        v-for="(data, index) of totalpassanger"
                        :key="index"
                      >
                        <v-btn
                          style="font-size: 13px"
                          variant="outlined"
                          @click="totalpassData(data, index)"
                          :class="passSelect == index ? 'tru-pass' : ''"
                        >
                          Passenger{{ index + 1 }}</v-btn
                        >
                      </div>
                    </div> -->
                  
                  <div style="overflow-x: scroll">
                    <table>
                        <tr>
                          <th></th>
                          <th  v-for="(data, index) in totalSeat" :key="index">
                          <div style="visibility: hidden;">
                          flight{{index+1}}
                          </div>
                          </th>
                        
                          </tr>
                        <tr v-for="(data, index) of totalpassanger" :key="index">
                          {{console.log(data,'ppp00000')}}
                          <td>
                            <div>
                              <span>
                                  <v-btn
                            style="font-size: 13px"
                            variant="outlined"
                            @click="totalpassData(data, index)"
                            :class="passSelect == index ? 'tru-pass' : ''"
                          >
                          {{ index + 1 }}.  {{ data.name}}</v-btn
                          >
                              </span>
                            </div>
                          </td>
                          <td v-for="(seat, index) of data.seat_number" :key="index">
                            {{ console.log(seat, "seat") }}
                            <div style="font-size: 14px; letter-spacing: 1px">
                              {{ seat }}
                            </div>
                          </td>
                        </tr>
                    </table>
                  </div> 
                
                
                
                
                    <div
                      style="overflow-x: scroll; border: 1px solid lightgrey"
                      class="mt-2 pa-2"
                    >
                      <div
                        v-for="(data, index) in totalSeat[indexData].cabins[0].columns"
                        :key="index"
                        class="d-flex mt-2"
                      >
                        <div class="px-1 text-center">
                          <div style="width: 25px; font-weight: 500">
                            {{ data.column }}
                          </div>
                        </div>
                        <div
                          class="px-1 text-center"
                          v-for="(datas, index1) in totalData[data.column]"
                          :key="index1"
                        >
                          <div>
                            <div
                              :class="{
                                'false-sheet': datas.seats[0].occupied === 'true',
                                'true-sheet': datas.seats[0].occupied === 'false',
                                'tru-icon':
                                  datas.seats[0].occupied === 'false' && datas.color,
                              }"
                              @click="seatSelectdata(datas, index1, data.column)"
                            >
                              <v-icon
                                :color="
                                  datas.seats[0].occupied === 'false'
                                    ? 'white'
                                    : 'white'
                                "
                                size="15"
                              >
                                mdi-seat-passenger
                              </v-icon>
                            </div>
                          </div>
                        
                          <div>
                            <div v-if="data.space" style="font-size: 13px">
                              {{ datas.row_number }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                  <div
                    class="d-flex justify-content-between flex-wrap mt-2 py-2"
                    style="border: 1px solid lightgray"
                  >
                    <div class="d-flex flex-wrap m-1nt" style="font-size: 13px">
                      <div class="d-flex ms-1">
                        <div class="av-sheet"></div>
                        <div class="mt-1">Available Seat</div>
                      </div>
                      <div class="d-flex ms-2">
                        <div class="oc-sheet"></div>
                        <div class="mt-1">Occupied Seat</div>
                      </div>
                      <div class="d-flex ms-2">
                        <div class="sel-sheet"></div>
                        <div class="mt-1">Selected Seat</div>
                      </div>
                    </div>
                    <div>
                      <!-- fdjkfjdj -->
                    </div>
                  </div>
                
                
                
                
                </div>
              
              </div>
            </div>
  
            <div class="row">
              <div class="col-12">
                  <button @click="submitform" type="submit" class="btn btn-effect btn-book px-5">{{ $t("flightInfoContent.contents.continue") }}</button>
                      <!-- <v-btn
                          width="25%"
                          height="40px"
                          class="continue-btn"
                          type="submit"
                          style="
                              color: white;
                              text-transform: capitalize;
                              background-color: #212529;
                          " @click="submitform"
                          >Continue</v-btn> -->
              </div>
            </div>
          </div>
        </div>
      <div class="col-12 col-md-3"></div>
    </div>
  </div>
  
   
  
    <div v-if="pageloader">
      <pageLoader></pageLoader>
    </div>
  
    <v-dialog v-model="seatmapError" persistent>
      <div class="d-flex justify-center">
        <v-card class="p-3" style="max-width: 450px;">
          <!-- <h3 class="text-center">{{ $t("errorContent.dialog1.text1") }}</h3> -->
          <h5 class="text-center">Sorry, the seat selection is unavailable. </h5>
          <h6 class="text-center">Please proceed without seat selection, and an automatic seat will be assigned to you. Thank you!</h6>
          <div class="d-flex justify-space-evenly mt-3">
            <v-btn @click="continuePayment()" color="#002d5b">Continue to Payment</v-btn>
          </div>
        </v-card>
      </div>
    </v-dialog>
  </template>
  
  <script>
  import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
  import axios from "axios";
  import pageLoader from "@/components/pageLoader.vue";
  export default{
      components: {
      pageLoader,
    },
      data(){
      return{
          portal_id:"",
        seatmapApi:"",
        pageloader:true,
        totalSeat: [],
        totalData: [], 
         totalpassanger:[], 
         seatSelect:[],
        passSelect:0,
        flightSelect:0,
        indexData: 0,
        local_id :"",
        getTotalData:"",
        routerData:"",
        seatmapError:false,
        getroutequery:"",
      }
      
  },
  methods: {
    continuePayment(){
        this.$router.push({
          path: "/confirmBook",
          query: { [this.routerData]: this.local_id }
        });
      },
  
      getConfig() {
        const getConfigData1 = getConfigDataFromLocalStorage();
        if (getConfigData1) {  
          this.seatmapApi = getConfigData1.payload.portal_configuration.API_endpoints.seatmap;
          console.log(this.seatmapApi,'this.seatmapApi')
        }
      }, 
  
  async  getseatMapping() {
   this.dataLoder=true;
   this.pageloader=true;
   
    let seat = {
      request_type: "seatmap",
      offer_id: "",
      shopping_response_id: "",
      hit_id: "",
      search_cabin_type: "",
      trip_type: "",
      pointofsale:"",
      adult: "",
      child: "",
      paxInfant: "",
    };
  
  
  //   if (this.roundresult.length > 0) {
  //     $data = this.roundresult;
  //     triptype = "RoundTrip";
  //     // console.log(this.roundresult, "seatmapping");
  //   } else if (this.onewayresult.length > 0) {
  //     $data = this.onewayresult;
  //     triptype = "OneWay";
  //   } else if (this.multiresult.length > 0) {
  //     $data = this.multiresult;
  //     triptype = "MultiCity";
  //   }
  console.log(this.getTotalData,'this.getTotalData')
   
    seat.shopping_response_id =this.getTotalData.ShoppingResponseId  ;
    seat.offer_id =this.getTotalData.selectId;
    seat.hit_id =this.getTotalData.hitId;
    seat.search_cabin_type =this.getTotalData.cabinClass;
    seat.pointofsale =this.getTotalData.pointofSale;
    seat.adult =this.getTotalData.Adult;
    seat.child =this.getTotalData.Child || 0;
    seat.paxInfant =this.getTotalData.Inf || 0;
    seat.trip_type =this.getTotalData.triptype;
  
    console.log(seat,this.seatmapApi,'4567')
  
   await axios.post(this.seatmapApi, seat, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((responce) => {
        this.pageloader = false;
        // console.log(responce,this.seatSelect, "seatmapdata223");
        this.seatSelect = responce.data.data;
        console.log(this.seatSelect,'this.seatSelect')
        this.getseatselect();
  
        
      })
      .catch((err) => {
        this.pageloader=false;
        console.log(err, "ppppp52222");
        this.seatmapError = true;
      });
  
    // console.log(seat, "kkkk3333333333");
  },
  totalpassData(data, index) {
    // console.log(data, index, "data, index");
    this.flightSelect=0
    this.passSelect = index;
    
  },
  
  
  getseatselect() {
    let $event = [];
    this.seatSelect.forEach((v, i) => {
      // console.log(v, i, "keerthikannan123");
      this.seatSelect[i].forEach((c) => {
        // console.log(c,c.cabins.length, "vasanthaganesh");
        $event.push(c);
  //           if (c && Array.isArray(c.cabins) && c.cabins.length > 0) {
  //   $event.push(c);
  // }
  
      });
    });
    this.totalSeat = $event;
  
    if (this.totalSeat.length > 0) {
     
      this.totalSeat[0].cabins.forEach((c) => {
        // console.log(c, "ppppppppppppppppp");
        let arrayData = [];
        arrayData = c.columns;
  
  
        let data = 0;
        arrayData.forEach((a, l) => {
          console.log(a, "rowsrows");
          if (a.type == "AisleA") {
            data = data + 1;
            if (data == 2) {
              arrayData[l - 1].space = true;
              arrayData[l].space = false;
            }
          } else {
            data = 0;
            arrayData[l].space = false;
          }
          c.seat_rows.forEach((d) => {
            console.log(d,a,'000000')
            if (d.seats[0].seat_number == a.column) {
              console.log(this.totalData , d,a.column,'lololol')
  
              if (!this.totalData[a.column]) {
                this.totalData[a.column] = [];
              }
  
              this.totalData[a.column].push(d);
            }
          });
          this.totalData[a.column].forEach((w) => {
            w.color = false;
            console.log(w, "dddwwwwdddd");
          });
         
        });
        console.log(arrayData, "arrayData");
      });
      console.log(this.totalData, "$data$data$data");
      this.pageloader=false
    }
  },
  
  
  selectdata(data, index) {
    console.log(data, index,'000000');
    // this.passSelect = 0;
    this.flightSelect=index
    this.indexData = index;
    this.totalData = [];
    let falData = false;
    if (this.totalSeat.length > 0) {
      this.totalSeat[index].cabins.forEach((c, k) => {
        c.seat_rows[k].color = falData[k];
  
        let arrayData = [];
        arrayData = c.columns;
        let data = 0;
        arrayData.forEach((a, l) => {
          if (a.type == "AisleA") {
            data = data + 1;
            if (data == 2) {
              arrayData[l - 1].space = true;
              arrayData[l].space = false;
            }
          } else {
            data = 0;
            arrayData[l].space = false;
          }
  
          c.seat_rows.forEach((d) => {
            // console.log(d, "rowsrows");
            if (d.seats[d.seats.length-1].seat_number == a.column) {
              if (!this.totalData[a.column]) {
                this.totalData[a.column] = [];
              }
              this.totalData[a.column].push(d);
            }
          });
  
          // this.totalData[a.column].forEach((w) => {
          //   console.log(w, "dddwwwwdddd");
          // });
        });
      });
    }
  },
  seatSelectdata(datas, index1, index) {
    console.log(this.passSelect,this.flightSelect,datas,'this.passSelect')
  
  if( datas.seats[0].occupied === 'false'){
  
  
    for (let key in this.totalData) {
      this.totalData[key].forEach(item => (item.color = false));
    }
  if (this.totalData[index] && this.totalData[index][index1]) {
  this.totalData[index][index1].color = true;
  }
  
  
  this.totalpassanger[this.passSelect].seat_number[this.flightSelect] =
      `${this.totalSeat[this.flightSelect].origin}-${this.totalSeat[this.flightSelect].destination}:${datas.row_number}${index}` || "";
  
      if(this.totalSeat.length-1 != this.flightSelect){
  this.flightSelect=this.flightSelect+1
  this.selectdata(this.totalSeat[this.flightSelect], this.flightSelect)
  }
  
  }
  
  
  },
  
  
  async submitform() {
  this.pageloader=true
  setTimeout(()=>{
      this.$router.push({
                path: "/confirmBook",
                query: { [this.routerData]: this.local_id }
              });
  },3000)
      
  },
  
  },
  
  created(){
    this.getroutequery = this.$route.query.pass_id1;
    console.log(this.getroutequery,'getroutequerygetroutequery');
      this.totalpassanger= JSON.parse(localStorage.getItem("totalpassanger"))
      if(this.$route.query.pass_id ){
          this.local_id = this.$route.query.pass_id 
          this.routerData="pass_id"
      }
      else if(this.$route.query.pass_id1){
          this.local_id =this.$route.query.pass_id1;
          this.routerData="pass_id1"
      }
      
     this.getTotalData = JSON.parse(
          localStorage.getItem(`${this.local_id}`)
        );  
      console.log(this.totalpassanger,this.local_id,this.routerData,this.getTotalData,'this.totalpassanger')
      this.getConfig();
      this.getseatMapping();
  }
  
  }
  
  </script>
  
  <style scoped>
  @import "@/assets/main.css";
  
  .true-sheet {
    border: 2px solid green;
    border-radius: 5px;
    padding: 1px 5px;
    cursor: pointer;
    background: green;
  }
  .true-sheet:focus {
    background: green !important;
    border: 2px solid green !important;
  }
  .false-sheet {
    border: 2px solid #e2e2e2;
    border-radius: 5px;
    background: #e2e2e2;
    padding: 1px 5px;
  }
  .tru-icon {
    background: #103769 !important;
    border: 2px solid #103769 !important;
  }
  .tru-pass {
    background: #103769 !important;
    color: white !important;
    /* font-size:13px; */
  }
  .tru-flight {
    background: #103769 !important;
    color: white !important;
    padding: 5px;
  }
  .tru-flightfalse {
    padding: 5px;
    border: 1px solid gray;
  }
  .av-sheet {
    background: green;
    width: 17px;
    height: 17px;
    border: 2px solid green;
    margin: 3px;
    border-radius: 3px;
  }
  .sel-sheet {
    background: #103769;
    width: 17px;
    height: 17px;
    border: 2px solid #103769;
    margin: 3px;
    border-radius: 3px;
  }
  .oc-sheet {
    background: lightgray;
    width: 17px;
    height: 17px;
    border: 2px solid lightgray;
    margin: 3px;
    border-radius: 3px;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    /* border: 1px solid lightgray; */
    /* table-layout: fixed; */
  }
  td,
  th {
    text-align: left;
    padding: 8px;
    word-wrap: break-word;
  }
  </style>
  
<template>

  <body>
    <!-- preloader area -->

    <div class="pagewrap" id="video-page">
      <div class="head-wrapper">
        <div class="video-image">
          <!-- <video
            autoplay
            muted
            loop
            class="background-video"
            :src="require('@/assets/video.mp4')"
          ></video> -->
        </div>
        <!-- page header section -->
        <!-- Search engine section -->
         <div>
        
        <section class="position-relative z-in-2 content">
          <div class="container">
            <div class="row" data-aos="fade-up">
              <div class="col-12 mt-5">
                <h2 class="h4 text-center text-white mb-4">
                  <!-- The best tour experience -->
                  {{ $t('homePageContent.welcomeMsg.text1') }}
                </h2>
                <h1 class="h1 text-center theme-text-white fw-bold theme-text-shadow mb-4">
                  <span class="me-2">{{ $t('homePageContent.welcomeMsg.text2') }} </span>
                  <!-- Find and book best -->
                  <span class="theme-text-primary" ref="changingWord" id="changingword"> flights</span>
                </h1>
                <p class="font-small text-white text-center px-5 max-1">
                  {{ $t('homePageContent.welcomeMsg.text3') }}
                </p>
              </div>
            </div>
          </div>
        </section>
        <!-- search engine flight-->
      </div>
      
        <div>
          <searchForm/>
        </div>
        <div class="flight-search" data-aos="fade-up" style="margin-top: 180px; display:none !important;">
          <div class="container">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs border-0" role="tablist">
              <li class="nav-item">
                <button @click="activeType('Roundtrip')"
                  class="nav-link"
                  id="return-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#return"
                  type="button"
                  role="tab"
                  aria-controls="return"
                  aria-selected="false">
                    <span class="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"></span>
                    {{ $t('formsContents.options.oneWay') }}
                  </button>
              </li>

              <li class="nav-item">
                <button @click="activeType('Oneway')"
                  class="nav-link active"
                  id="oneway-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#oneway"
                  type="button"
                  role="tab"
                  aria-controls="oneway"
                  aria-selected="true"
                >
                  <span
                    class="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"
                  ></span>
                  {{ $t('formsContents.options.return') }}
                </button>
              </li>

              <li class="nav-item">
                <button @click="activeType('Multicity')"
                  class="nav-link"
                  id="multiCity-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#multiCity"
                  type="button"
                  role="tab"
                  aria-controls="multiCity"
                  aria-selected="false"
                >
                  <span
                    class="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"
                  ></span
                  >{{ $t('formsContents.options.multiCity') }}
                </button>
              </li>
            </ul>
            <!-- Tab content -->
            <div class="tab-content">
              <!-- oneway search -->
              <div id="return" class="tab-pane fade">
                <div class="row">
                  <div class="col-12">
                    <div class="search-pan row mx-0 theme-border-radius">
                      <div
                        class="col-12 col-md-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2"
                      >
                        <div
                          class="form-group1"
                          :class="{ focused: isFocused1 }"
                          @focusin="handleFocus(1)"
                          @focusout="handleBlur(1)">
                          <div class="form-groupicon">
                            <i
                              class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                            ></i>
                            <!-- <v-icon color="grey" size="40">
                              mdi-map-marker
                            </v-icon> -->
                          </div>

                          <!-- <AutoComplete
                            v-model="oneway.from"
                            optionLabel="name"
                            dropdown
                            placeholder="Origin"
                            forceSelection
                            :suggestions="filteredCountries"
                            @complete="search"
                            ref="fromAutoComplete"
                            @change="onChangeFrom()"
                            @click="
                              fromClick(oneway), (focusedElement = 'from')
                            "
                            class="autoComInput"
                          >
                            <template #option="slotProps">
                              <div
                                class="flex align-items-center dropdown-box"
                                @click="focus1()"
                              >
                                <div>{{ slotProps.option.originalName }}</div>
                              </div>
                            </template>
</AutoComplete> -->

                          <div class="vas-autocomplete">
                            <input
                              type="text"
                              :placeholder="departureLabel()"
                              v-model="inputCity"
                              @keyup="typeCity(inputCity, 1)"
                              class="inter-input"
                              @click="inputCity='', formres=true, formres1=false"/>
                              {{ console.log(inputCity,'inputCityinputCity') }}
                            <div @click="onewaySwap" class="pos-swap">
                              <v-icon
                                size="30"
                                color="white"
                                :class="{
                                  rotateSwap: !swapData,
                                  normalSwap: swapData,
                                }"
                                >mdi-swap-horizontal</v-icon
                              >
                            </div>
                            <!-- {{ console.log(this.swapData, "this.formres") }} -->
                            <div v-if="typedrop">
                              <div
                                v-if="
                                  inputCity.length > 2 && this.city.length > 0
                                    ? true
                                    : ((this.city = []), false)
                                "
                                class="autocompete-menual-dropdown"
                              >
                                <div v-for="(data, index) in city" :key="index">
                                  <div
                                    @click="datachange(data)"
                                    :style="
                                      index == city.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                    "
                                    class="d-flex p-2"
                                  >
                                    <div
                                      style="display: flex; align-items: center"
                                    >
                                      <v-icon
                                        style="transform: rotate(90deg)"
                                        color="gray"
                                        size="20"
                                        >mdi-airplane</v-icon
                                      >
                                    </div>

                                    <div class="m-1">
                                      {{ data.originalName }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-if="fromloader">
                            <v-progress-circular
                              color="#002d5b" size="24"
                              indeterminate style="position:absolute;top:15px;right:19px;"
                            ></v-progress-circular>
                          </div>

                          <!-- <div class="pos-swap" @click="onewaySwap()">
                            <v-icon size="30" color="white">mdi-swap-horizontal</v-icon>
                          </div> -->
                        </div>

                        <div v-if="formres && resultFrom.length > 0" v-click-outside="onClickOutside">
                          <div class="recent_style">
                            <v-card
                              style="
                                max-height: 300px;
                                border-radius: 10px;
                                box-shadow: none;
                              "
                            >
                              <h5
                                style="
                                  text-align: left;
                                  margin: 12px;
                                  font-weight: 500;
                                  font-size: 20px;
                                "
                              >
                                {{ $t("homePageContent.label.recentSearches") }}
                              </h5>
                              <div
                                class="row resu-form mt-1"
                                v-for="data in resultFrom"
                                :key="data"
                              >
                                <div class="col-2">
                                  <v-icon> mdi-clock-outline </v-icon>
                                </div>

                                <div class="col-10" @click="getrecent(data)">
                                  <div
                                    style="font-size: 16px; text-align: left"
                                  >
                                    <!-- {{
                                                  getrecentData(
                                                    data.from.originalName
                                                  )
                                                }} -->
                                    {{ data.from }}
                                  </div>
                                </div>
                              </div>
                            </v-card>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                        <div class="form-group1"
                          :class="{ focused: isFocused2 }"
                          @focusin="handleFocus(2)"
                          @focusout="handleBlur(2)"
                        >
                          <div class="form-groupicon">
                            <i
                              class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                            ></i>
                            <!-- <v-icon color="grey" size="40">
                              mdi-map-marker
                            </v-icon> -->
                          </div>

                          <!-- <AutoComplete
                            v-model="oneway.to"
                            optionLabel="name"
                            dropdown
                            :placeholder="arrivalLabel()"
                            forceSelection
                            :suggestions="filteredCountries"
                            @complete="search"
                            ref="onewaysecondAuto"
                            @change="onChangeTo()"
                            @click="toClick(oneway), (focusedElement = 'to')"
                            class="autoComInput"
                          >
                            <template #option="slotProps">
                              <div
                                class="flex align-items-center dropdown-box"
                                @click="focus2()"
                              >
                                <div>{{ slotProps.option.originalName }}</div>
                              </div>
                            </template>
                          </AutoComplete> -->

                          <div class="vas-autocomplete">
                            <input
                              type="text"
                              :placeholder="arrivalLabel()"
                              v-model="inputCityto"
                              @keyup="typeCity(inputCityto, 2)"
                              class="inter-input"
                              @click="inputCityto = '',formres1 = true, formres = false"/>

                            <div v-if="typedrop1">
                              <div
                                v-if="
                                  inputCityto.length > 2 &&
                                  this.city1.length > 0
                                    ? true
                                    : ((this.city1 = []), false)
                                "
                                class="autocompete-menual-dropdown"
                              >
                                <div
                                  v-for="(data, index) in city1"
                                  :key="index"
                                >
                                  <div
                                    @click="datachangeTo(data)"
                                    :style="
                                      index == city1.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                    "
                                    class="d-flex p-2"
                                  >
                                    <div
                                      style="display: flex; align-items: center"
                                    >
                                      <v-icon
                                        style="transform: rotate(90deg)"
                                        color="gray"
                                        size="20"
                                        >mdi-airplane</v-icon
                                      >
                                    </div>

                                    <div class="ms-1">
                                      {{ data.originalName }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-if="toloader">
                            <v-progress-circular
                              color="#002d5b" size="24"
                              indeterminate style="position:absolute;top:15px;right:10px;"
                            ></v-progress-circular>
                          </div>

                          <!-- <button class="pos-swap">
                            <i class="bi bi-arrow-left-right pl-1"></i>
                          </button> -->
                        </div>

                        <div class="recent_style"
                          v-if="formres1 && resultTo.length > 0" v-click-outside="onClickOutside"
                        >
                          <v-card
                            style="
                              max-height: 300px;
                              border-radius: 10px;
                              box-shadow: none;
                            "
                          >
                            <h5
                              style="
                                text-align: left;
                                margin: 12px;
                                font-weight: 500;
                                font-size: 20px;
                              "
                            >
                             {{ $t("homePageContent.label.recentSearches")}}
                            </h5>
                            <div
                              class="row resu-form mt-1"
                              v-for="data in resultTo"
                              :key="data"
                            >
                              <div class="col-2">
                                <v-icon> mdi-clock-outline </v-icon>
                              </div>

                              <div class="col-10" @click="getrecent1(data)">
                                <div style="font-size: 16px; text-align: left">
                                  <!-- {{
                                                getrecentData(
                                                  data.to.originalName
                                                )
                                              }} -->

                                  {{ data.to }}
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2 DatecolumnSizing"
                      >
                        <div class="form-control form-group d-flex">
                          <div class="d-flex align-center">
                            <i
                              class="bi bi-calendar3 h2 icon-pos mb-0 me-2"
                            ></i>
                            <!-- <v-icon color="grey" size="40"
                              >mdi-calendar-range-outline</v-icon
                            > -->
                          </div>

                          <VueDatePicker
                            ref="dpRef1"
                            v-model="oneway.depdate"
                            :min-date="new Date()"
                            auto-apply
                            @mousedown="focusedElement = 'calendar1'"
                            :format="dateFormat"
                            :hide-navigation="['time']"
                            @focus="
                              (focusEvent3 = true),
                                (focusEvent1 = false),
                                (focusEvent2 = false)
                            "
                            :placeholder="selectLabel()"
                            @blur="dateUnfocus"
                            @closed="closeDateMenu"
                            @update:modelValue="fromDateChanged"
                            class="datePickInput px-0"
                            :on-click-outside="ClickOutDepat"
                            :month-change-on-scroll="false"
                          >
                            <template #clear-icon="{}">
                              <v-icon
                                class="input-slot-image me-2"
                                v-if="focusEvent3"
                                @click="clearDate"
                                >mdi-close-circle</v-icon
                              >
                            </template>
                          </VueDatePicker>
                        </div>
                      </div>

                      <div class="col-12 col-md-3 paxcolumnSizing">
                        <div class="dropdown form-control1" id="myDD">
                          <button
                            class="dropdown-toggle form-control"
                            type="button"
                            id="travellerInfoOneway"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="display: flex; align-items: center"
                          >
                            <div>
                              <i
                                class="bi bi-person-lines-fill h2 icon-pos me-2"
                              ></i>
                              <!-- <v-icon color="grey" size="40"
                                >mdi-account</v-icon
                              > -->
                            </div>

                            <span class="text-truncate"
                              >{{ aduls + aduls1 + aduls2 }} {{ $t('formsContents.options.travellers') }},
                              {{ oneway.class }}
                            </span>
                          </button>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="travellerInfoOneway"
                          >
                            <ul class="drop-rest">
                              <li>
                                <div class="d-flex">{{ $t('formsContents.options.adult') }}</div>
                                <div
                                  class="ms-auto input-group plus-minus-input"
                                >
                                  <div class="addbtn" @click.stop="deg1()">
                                    -
                                  </div>
                                  <div class="adddata">{{ aduls }}</div>
                                  <div class="addbtn" @click.stop="add1()">
                                    +
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="d-flex">{{ $t('formsContents.options.child') }}</div>
                                <div
                                  class="ms-auto input-group plus-minus-input"
                                >
                                  <div class="addbtn" @click.stop="deg2()">
                                    -
                                  </div>
                                  <div class="adddata">{{ aduls1 }}</div>
                                  <div class="addbtn" @click.stop="add2()">
                                    +
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="d-flex">{{ $t('formsContents.options.infants') }}</div>
                                <div
                                  class="ms-auto input-group plus-minus-input"
                                >
                                  <div class="addbtn" @click.stop="deg3()">
                                    -
                                  </div>
                                  <div class="adddata">{{ aduls2 }}</div>
                                  <div class="addbtn" @click.stop="add3()">
                                    +
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <v-radio-group v-model="oneway.class">
                              <v-radio
                                :label="$t('formsContents.options.economy')"
                                value="Economy"
                              ></v-radio>
                              <v-radio
                                :label="$t('formsContents.options.premiumEconomy')"
                                value="Premium Economy"
                              ></v-radio>
                              <v-radio
                                :label="$t('formsContents.options.business')"
                                value="Business"
                              ></v-radio>
                              <v-radio
                                :label="$t('formsContents.options.firstClass')"
                                value="First Class"
                              ></v-radio>
                            </v-radio-group>

                            <ul class="drop-rest">
                              <li class="justify-end">
                                <button
                                  type="button"
                                  class="btn btn"
                                  onclick=""
                                >
                                {{ $t('formsContents.options.done') }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-1 px-0" @click="sended()">
                        <button type="submit" class="btn btn-search">
                          <v-icon>mdi-magnify </v-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-4 d-none">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-check-inline">
                          <label class="check-wrap"
                            >Refundable Flights
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="check-wrap">
                            Non Stop Flights
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="form-check-inline">
                          <label class="check-wrap">
                            GDS Special Return
                            <input type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Return search -->
              <div id="oneway" class="tab-pane active">
                <div class="row">
                  <div class="col-sm-12 col-md-12">
                    <div class="search-pan row mx-0 theme-border-radius">
                      <div
                        class="col-12 col-md-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2"
                      >
                        <div
                          class="form-group1"
                          :class="{ focused: isFocused1 }"
                          @focusin="handleFocus(1)"
                          @focusout="handleBlur(1)"
                        >
                          <div class="form-groupicon">
                            <i
                              class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                            ></i>
                            <!-- <v-icon color="grey" size="40">
                              mdi-map-marker
                            </v-icon> -->
                          </div>

                          <!-- <AutoComplete
                            v-model="oneway.from"
                            optionLabel="name"
                            dropdown
                            placeholder="Origin"
                            forceSelection
                            :suggestions="filteredCountries"
                            @complete="search"
                            ref="fromAutoComplete"
                            @change="onChangeFrom()"
                            @click="
                              fromClick(oneway), (focusedElement = 'from')
                            "
                            class="autoComInput"
                          >
                            <template #option="slotProps">
                              <div
                                class="flex align-items-center dropdown-box"
                                @click="focus1()"
                              >
                                <div>{{ slotProps.option.originalName }}</div>
                              </div>
                            </template>
                          </AutoComplete> -->

                          <div class="vas-autocomplete">
                            <input
                              type="text"
                              :placeholder="departureLabel()"
                              v-model="inputCity"
                              @keyup="typeCity(inputCity, 1)"
                              class="inter-input"
                              @click="inputCity = '', this.formres = true, this.formres1 = false"/>

                            <div @click="onewaySwap" class="pos-swap">
                              <v-icon
                                size="30"
                                color="white"
                                :class="{
                                  rotateSwap: !swapData,
                                  normalSwap: swapData,
                                }"
                                >mdi-swap-horizontal</v-icon
                              >
                            </div>

                            <div v-if="typedrop">
                              <div
                                v-if="
                                  inputCity.length > 2 && this.city.length > 0
                                    ? true
                                    : ((this.city = []), false)
                                "
                                class="autocompete-menual-dropdown"
                              >
                                <div v-for="(data, index) in city" :key="index">
                                  <div
                                    @click="datachange(data)"
                                    :style="
                                      index == city.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                    "
                                    class="d-flex p-2"
                                  >
                                    <div
                                      style="display: flex; align-items: center"
                                    >
                                      <v-icon
                                        style="transform: rotate(90deg)"
                                        color="gray"
                                        size="20"
                                        >mdi-airplane</v-icon
                                      >
                                    </div>

                                    <div class="m-1">
                                      {{ data.originalName }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-if="fromloader">
                            <v-progress-circular
                              color="#002d5b" size="24"
                              indeterminate style="position:absolute;top:15px;right:19px;"
                            ></v-progress-circular>
                          </div>
                        </div>

                        <div v-if="formres && resultFrom.length > 0" v-click-outside="onClickOutside">
                          <div class="recent_style">
                            <v-card
                              style="
                                max-height: 300px;
                                border-radius: 10px;
                                box-shadow: none;
                              "
                            >
                              <h5
                                style="
                                  text-align: left;
                                  margin: 12px;
                                  font-weight: 500;
                                  font-size: 20px;
                                "
                              >
                                {{ $t("homePageContent.label.recentSearches") }}
                              </h5>
                              <div
                                class="row resu-form mt-1"
                                v-for="data in resultFrom"
                                :key="data"
                              >
                                <div class="col-2">
                                  <v-icon> mdi-clock-outline </v-icon>
                                </div>

                                <div class="col-10" @click="getrecent(data)">
                                  <div
                                    style="font-size: 16px; text-align: left"
                                  >
                                    <!-- {{
                                                  getrecentData(
                                                    data.from.originalName
                                                  )
                                                }} -->
                                    {{ data.from }}
                                  </div>
                                </div>
                              </div>
                            </v-card>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2"
                      >
                        <div
                          class="form-group1"
                          :class="{ focused: isFocused2 }"
                          @focusin="handleFocus(2)"
                          @focusout="handleBlur(2)"
                        >
                          <div class="form-groupicon">
                            <i
                              class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                            ></i>
                            <!-- <v-icon color="grey" size="40">
                              mdi-map-marker
                            </v-icon> -->
                          </div>

                          <!-- <AutoComplete
                            v-model="oneway.to"
                            optionLabel="name"
                            dropdown
                            :placeholder="arrivalLabel()"
                            forceSelection
                            :suggestions="filteredCountries"
                            @complete="search"
                            ref="secondAutoComplete"
                            @change="onChangeFrom()"
                            @click="toClick(oneway), (focusedElement = 'to')"
                            class="autoComInput"
                          >
                            <template #option="slotProps">
                              <div
                                class="flex align-items-center dropdown-box"
                                @click="focus2()"
                              >
                                <div>{{ slotProps.option.originalName }}</div>
                              </div>
                            </template>
                          </AutoComplete> -->

                          <div class="vas-autocomplete">
                            <input
                              type="text"
                              :placeholder="arrivalLabel()"
                              v-model="inputCityto"
                              @keyup="typeCity(inputCityto, 2)"
                              class="inter-input"
                              @click="inputCityto = '', this.formres1 = true, this.formres = false"/>

                            <div v-if="typedrop1">
                              <div
                                v-if="
                                  inputCityto.length > 2 &&
                                  this.city1.length > 0
                                    ? true
                                    : ((this.city1 = []), false)
                                "
                                class="autocompete-menual-dropdown"
                              >
                                <div
                                  v-for="(data, index) in city1"
                                  :key="index"
                                >
                                  <div
                                    @click="datachangeTo(data)"
                                    :style="
                                      index == city1.length - 1
                                        ? ''
                                        : 'border-bottom:1px solid lightgray'
                                    "
                                    class="d-flex p-2"
                                  >
                                    <div
                                      style="display: flex; align-items: center"
                                    >
                                      <v-icon
                                        style="transform: rotate(90deg)"
                                        color="gray"
                                        size="20"
                                        >mdi-airplane</v-icon
                                      >
                                    </div>

                                    <div class="ms-1">
                                      {{ data.originalName }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-if="toloader">
                            <v-progress-circular
                              color="#002d5b" size="24"
                              indeterminate style="position:absolute;top:15px;right:10px;"
                            ></v-progress-circular>
                          </div>
                        </div>

                        <div
                          class="recent_style"
                          v-if="formres1 && resultTo.length > 0" v-click-outside="onClickOutside"
                        >
                          <v-card
                            style="
                              max-height: 300px;
                              border-radius: 10px;
                              box-shadow: none;
                            "
                          >
                            <h5
                              style="
                                text-align: left;
                                margin: 12px;
                                font-weight: 500;
                                font-size: 20px;
                              "
                            >
                              {{ $t("homePageContent.label.recentSearches") }}
                            </h5>
                            <div
                              class="row resu-form mt-1"
                              v-for="data in resultTo"
                              :key="data"
                            >
                              <div class="col-2">
                                <v-icon> mdi-clock-outline </v-icon>
                              </div>

                              <div class="col-10" @click="getrecent1(data)">
                                <div style="font-size: 16px; text-align: left">
                                  <!-- {{
                                                getrecentData(
                                                  data.to.originalName
                                                )
                                              }} -->

                                  {{ data.to }}
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2 DatecolumnSizing"
                      >
                        <div class="form-control form-group d-flex">
                          <div class="d-flex align-center">
                            <i
                              class="bi bi-calendar3 h2 icon-pos mb-0 me-2"
                            ></i>
                          </div>

                          <VueDatePicker
                            ref="dpRef2"
                            v-model="oneway.travelDate"
                            range
                            :min-date="new Date()"
                            :six-weeks="sixWeekMode"
                            @mousedown="focusedElement = 'calendar1'"
                            :format="dateFormat"
                            :max-date="
                              new Date(
                                new Date().getFullYear() + 1,
                                new Date().getMonth(),
                                new Date().getDate()
                              )
                            "
                            :hide-navigation="['time']"
                            auto-apply
                            :month-change-on-scroll="false"
                            :placeholder="selectLabel()"
                            @focus="
                              (focusEvent3 = true),
                                (focusEvent1 = false),
                                (focusEvent2 = false)
                            "
                            @blur="dateUnfocus"
                            @closed="closeDateMenu"
                            :multi-calendars="multiCalender"
                            @update:modelValue="fromDateChanged"
                            :on-click-outside="ClickOutDepat"
                            class="px-0 datePickInput"
                          >
                            <template #clear-icon="{}">
                              <v-icon
                                class="input-slot-image me-2"
                                v-if="focusEvent3"
                                @click="clearDate"
                                >mdi-close-circle</v-icon
                              >
                            </template>
                          </VueDatePicker>

                          <!-- <VueDatePicker  v-model="oneway.depdate" range placeholder="Departure" /> -->
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-3 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-2 paxcolumnSizing"
                      >
                        <div class="dropdown" id="myDDReturn">
                          <button
                            class="dropdown-toggle form-control d-flex align-center"
                            type="button"
                            id="travellerInfoReturn"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i
                              class="bi bi-person-lines-fill h2 icon-pos mb-0 me-2"
                            ></i>
                            <span class="text-truncate"
                              >{{ aduls + aduls1 + aduls2 }} {{ $t('formsContents.options.travellers') }},
                              {{ oneway.class }}
                            </span>
                          </button>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="travellerInfoReturn"
                          >
                            <ul class="drop-rest">
                              <li>
                                <div class="d-flex">{{ $t('formsContents.options.adult') }}</div>
                                <div
                                  class="ms-auto input-group plus-minus-input"
                                >
                                  <div class="addbtn" @click.stop="deg1()">
                                    -
                                  </div>
                                  <div class="adddata">{{ aduls }}</div>
                                  <div class="addbtn" @click.stop="add1()">
                                    +
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="d-flex">{{ $t('formsContents.options.child') }}</div>
                                <div
                                  class="ms-auto input-group plus-minus-input"
                                >
                                  <div class="addbtn" @click.stop="deg2()">
                                    -
                                  </div>
                                  <div class="adddata">{{ aduls1 }}</div>
                                  <div class="addbtn" @click.stop="add2()">
                                    +
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="d-flex">{{ $t('formsContents.options.infants') }}</div>
                                <div
                                  class="ms-auto input-group plus-minus-input"
                                >
                                  <div class="addbtn" @click.stop="deg3()">
                                    -
                                  </div>
                                  <div class="adddata">{{ aduls2 }}</div>
                                  <div class="addbtn" @click.stop="add3()">
                                    +
                                  </div>
                                </div>
                              </li>
                            </ul>

                            <v-radio-group v-model="oneway.class">
                              <v-radio
                                :label="$t('formsContents.options.economy')"
                                value="Economy"
                              ></v-radio>
                              <v-radio
                                :label="$t('formsContents.options.premiumEconomy')"
                                value="Premium Economy"
                              ></v-radio>
                              <v-radio
                                :label="$t('formsContents.options.business')"
                                value="Business"
                              ></v-radio>
                              <v-radio
                                :label="$t('formsContents.options.firstClass')"
                                value="First Class"
                              ></v-radio>
                            </v-radio-group>

                            <ul class="drop-rest">
                              <li class="justify-end">
                                <button
                                  type="button"
                                  class="btn btn"
                                  onclick=""
                                >
                                  {{ $t('formsContents.options.done') }}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-1 px-0">
                        <button
                          type="submit"
                          class="btn btn-search"
                          @click="sended()"
                        >
                          <v-icon>mdi-magnify </v-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Multicity search -->

              <div
                id="multiCity"
                class="tab-pane fade"
                style="background: #ffffff; border-radius: 10px"
              >
                <div
                  class="row"
                  v-for="(data, index) in multiData.itinery"
                  :key="index"
                >
                  <div class="col-sm-12 col-md-12">
                    <div class="search-pan row mx-0 theme-border-radius">
                      <div class="col-12">
                        <div class="row">
                          <div
                            class="col-12 col-md-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2"
                          >
                            <div class="form-group1">
                              <div class="form-groupicon">
                                <i
                                  class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                                ></i>
                                <!-- <v-icon color="grey" size="40">
                                  mdi-map-marker
                                </v-icon> -->
                              </div>

                              <div class="vas-autocomplete">
                                <input
                                  type="text"
                                  :placeholder="departureLabel()"
                                  v-model="data.from"
                                  @keyup="typeMultiCity(data.from, index, 1)"
                                  class="inter-input"
                                />
                                <!-- {{console.log(data,'data.autocomfrom')}} -->
                                <div
                                  v-if="
                                    multiData.itinery[index].autocomfrom
                                      ? true
                                      : false
                                  "
                                >
                                  <div
                                    class="autocompete-menual-dropdown"
                                    v-if="multidrop.length > 0 ? true : false"
                                  >
                                    <div
                                      v-for="(data1, index1) in multidrop"
                                      :key="index1"
                                    >
                                      <!-- {{console.log(data1,'data1data1')}} -->
                                      <div
                                        @click="datachangeMuti(data1, index)"
                                        :style="
                                          index1 == multidrop.length - 1
                                            ? ''
                                            : 'border-bottom:1px solid lightgray'
                                        "
                                        class="d-flex p-2"
                                      >
                                        <div
                                          style="
                                            display: flex;
                                            align-items: center;
                                          "
                                        >
                                          <v-icon
                                            style="transform: rotate(90deg)"
                                            color="gray"
                                            size="20"
                                            >mdi-airplane</v-icon
                                          >
                                        </div>

                                        <div class="ms-1">
                                          {{ data1.originalName }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div v-if="data.fromloader">
                                <v-progress-circular
                                  color="#002d5b" size="24"
                                  indeterminate style="position:absolute;top:15px;right:19px;"
                                ></v-progress-circular>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-12 col-md-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2"
                          >
                            <div class="form-group1">
                              <div class="form-groupicon">
                                <i
                                  class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                                ></i>
                                <!-- <v-icon color="grey" size="40">
                                  mdi-map-marker
                                </v-icon> -->
                              </div>

                              <!-- <AutoComplete
                                v-model="data.to"
                                optionLabel="name"
                                dropdown
                                :placeholder="arrivalLabel()"
                                forceSelection
                                :suggestions="filteredCountries"
                                @complete="search"
                                ref="multiSecond"
                                @change="onChangeMultiTo()"
                                @click="
                                  fromClick(oneway), (focusedElement = 'from')
                                "
                                class="autoComInput"
                              >
                                <template #option="slotProps">
                                  <div
                                    class="flex align-items-center dropdown-box"
                                    @click="multifocus2(index)"
                                  >
                                    <div>
                                      {{ slotProps.option.originalName }}
                                    </div>
                                  </div>
                                </template>
                              </AutoComplete> -->

                              <div class="vas-autocomplete">
                                <input
                                  type="text"
                                  :placeholder="arrivalLabel()"
                                  v-model="data.to"
                                  @keyup="typeMultiCity(data.to, index, 2)"
                                  class="inter-input"
                                />

                                <div v-if="data.autocomTo">
                                  <div
                                    class="autocompete-menual-dropdown"
                                    v-if="multidrop1.length > 0 ? true : false"
                                  >
                                    <div
                                      v-for="(data, index1) in multidrop1"
                                      :key="index1"
                                    >
                                      <div
                                        @click="datachangeMutiTo(data, index)"
                                        :style="
                                          index1 == multidrop1.length - 1
                                            ? ''
                                            : 'border-bottom:1px solid lightgray'
                                        "
                                        class="d-flex p-2"
                                      >
                                        <div
                                          style="
                                            display: flex;
                                            align-items: center;
                                          "
                                        >
                                          <v-icon
                                            style="transform: rotate(90deg)"
                                            color="gray"
                                            size="20"
                                            >mdi-airplane</v-icon
                                          >
                                        </div>

                                        <div class="ms-1">
                                          {{ data.originalName }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div v-if="data.toloader">
                                <v-progress-circular
                                  color="#002d5b" size="24"
                                  indeterminate style="position:absolute;top:15px;right:19px;"
                                ></v-progress-circular>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-12 col-md-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2 pe-xl-2"
                          >
                            <div class="form-control form-group d-flex">
                              <div class="d-flex align-center">
                                <i
                                  class="bi bi-calendar3 h2 icon-pos mb-0 me-2"
                                ></i>
                              </div>

                              <VueDatePicker
                                ref="dpRef4"
                                v-model="data.departDate"
                                :min-date="getdate1(multiData.itinery, index)"
                                :start-date="getdate1(multiData.itinery, index)"
                                :max-date="
                                  new Date(
                                    new Date().getFullYear() + 1,
                                    new Date().getMonth(),
                                    new Date().getDate()
                                  )
                                "
                                auto-apply
                                @mousedown="focusedElement = 'calendar1'"
                                :format="dateFormat"
                                :hide-navigation="['time']"
                                @focus="
                                  (focusEvent3 = true),
                                    (focusEvent1 = false),
                                    (focusEvent2 = false)
                                "
                                :placeholder="selectLabel()"
                                @blur="dateUnfocus"
                                @closed="closeDateMenu"
                                @update:modelValue="fromDateChanged"
                                class="datePickInput px-0"
                                :on-click-outside="ClickOutDepat"
                                :month-change-on-scroll="false"
                              >
                                <template #clear-icon="{}">
                                  <v-icon
                                    class="input-slot-image me-2"
                                    v-if="focusEvent3"
                                    @click="clearDate"
                                    >mdi-close-circle</v-icon
                                  >
                                </template>
                              </VueDatePicker>

                              <div
                                @click="removemulti(index)"
                                style="
                                  position: relative;
                                  top: 13px;
                                  left: 38px;
                                  cursor: pointer;
                                "
                              >
                                <v-icon
                                  color="red"
                                  size="25"
                                  v-if="index > 1 ? true : false"
                                >
                                  mdi-close-circle-outline
                                </v-icon>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-12 col-md-4 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-0 pe-xl-0"
                          >
                            <div
                              class="dropdown form-control1"
                              id="myDD"
                              v-if="index == 0"
                            >
                              <button
                                class="dropdown-toggle form-control"
                                type="button"
                                id="travellerInfoOneway"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                style="display: flex; align-items: center"
                              >
                                <div>
                                  <i
                                    class="bi bi-person-lines-fill h2 icon-pos me-2"
                                  ></i>
                                </div>

                                <span class="text-truncate"
                                  >{{ aduls + aduls1 + aduls2 }} {{ $t('formsContents.options.travellers') }},
                                  {{ multiData.class }}
                                </span>
                              </button>
                              <div
                                class="dropdown-menu"
                                aria-labelledby="travellerInfoOneway"
                              >
                                <ul class="drop-rest">
                                  <li>
                                    <div class="d-flex">{{ $t('formsContents.options.adult') }}</div>
                                    <div
                                      class="ms-auto input-group plus-minus-input"
                                    >
                                      <div class="addbtn" @click.stop="deg1()">
                                        -
                                      </div>
                                      <div class="adddata">{{ adultValue }}</div>
                                      <div class="addbtn" @click.stop="add1()">
                                        +
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="d-flex">{{ $t('formsContents.options.child') }}</div>
                                    <div
                                      class="ms-auto input-group plus-minus-input"
                                    >
                                      <div class="addbtn" @click.stop="deg2()">
                                        -
                                      </div>
                                      <div class="adddata">{{ aduls1 }}</div>
                                      <div class="addbtn" @click.stop="add2()">
                                        +
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="d-flex">{{ $t('formsContents.options.infants') }}</div>
                                    <div
                                      class="ms-auto input-group plus-minus-input"
                                    >
                                      <div class="addbtn" @click.stop="deg3()">
                                        -
                                      </div>
                                      <div class="adddata">{{ aduls2 }}</div>
                                      <div class="addbtn" @click.stop="add3()">
                                        +
                                      </div>
                                    </div>
                                  </li>
                                </ul>

                                <v-radio-group v-model="multiData.class">
                                  <v-radio
                                    :label="$t('formsContents.options.economy')"
                                    value="Economy"
                                  ></v-radio>
                                  <v-radio
                                    :label="$t('formsContents.options.premiumEconomy')"
                                    value="Premium Economy"
                                  ></v-radio>
                                  <v-radio
                                    :label="$t('formsContents.options.business')"
                                    value="Business"
                                  ></v-radio>
                                  <v-radio
                                    :label="$t('formsContents.options.firstClass')"
                                    value="First Class"
                                  ></v-radio>
                                </v-radio-group>

                                <ul class="drop-rest">
                                  <li class="justify-end">
                                    <button
                                      type="button"
                                      class="btn btn"
                                      onclick=""
                                    >
                                      {{ $t('formsContents.options.done') }}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div
                              class="row"
                              v-if="index == multiData.itinery.length - 1"
                            >
                              <div
                                class="col-12 col-lg-3 col-xl-5 mb-2 mb-md-2 mb-lg-0 d-flex justify-content-center align-items-center"
                                id="wrapper"
                              >
                                <button
                                  v-if="index !== 3 ? true : false"
                                  type="submit"
                                  class="btn btn-light font-small"
                                  id="addMulticityRow"
                                  @click="addTrip(index)"
                                >
                                  <span class="fw-bold">{{ $t('formsContents.options.addCity') }}</span>
                                </button>

                                <button
                                  v-if="index == 3 ? true : false"
                                  type="submit"
                                  class="btn btn-light font-small"
                                  id="addMulticityRow"
                                  @click="clearall1()"
                                >
                                  <span class="fw-bold">{{ $t('formsContents.options.clearAll') }}</span>
                                </button>
                              </div>
                              <div class="col-12 col-md-6">
                                <button
                                  type="submit"
                                  class="btn btn-search"
                                  @click="sendedMulticity()"
                                >
                                  <v-icon>mdi-magnify </v-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="multi_city_form_wrapper"></div>
                        <div class="row mt-0 mt-md-0 mt-lg-0 mt-xl-2">
                          <div class="col-12 col-lg-12 col-xl-8">
                            <div class="row">
                              <div
                                class="col-12 col-lg-4 col-xl-4 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2"
                              >
                                <div class="form-group">
                                  <i
                                    class="bi bi-geo-alt-fill position-absolute h2 icon-pos"
                                  ></i>
                                  <input
                                    type="text"
                                    class="form-control ps-5"
                                    id="multiOrigin2"
                                    placeholder="Origin"
                                  />
                                </div>
                              </div>
                              <div
                                class="col-12 col-lg-4 col-xl-4 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2"
                              >
                                <div class="form-group">
                                  <i
                                    class="bi bi-geo-alt-fill position-absolute h2 icon-pos"
                                  ></i>
                                  <input
                                    type="text"
                                    class="form-control ps-5"
                                    id="multiDestination2"
                                    :placeholder="arrivalLabel()"
                                  />
                                </div>
                              </div>
                              <div
                                class="col-12 col-lg-4 col-xl-4 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2"
                              >
                                <div class="form-control form-group d-flex">
                                  <i
                                    class="bi bi-calendar3 position-absolute h2 icon-pos"
                                  ></i>
                                  <span class="dep-date-input">
                                    <input
                                      type="text"
                                      class="cal-input"
                                      placeholder="Depart Date"
                                      id="datepicker4"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-12 col-xl-4 px-0">
                            <div class="row">
                              <div
                                class="col-12 col-lg-6 col-xl-5 mb-2 mb-md-2 mb-lg-0 d-flex justify-content-center align-items-center"
                                id="wrapper"
                              >
                                <button
                                  type="submit"
                                  class="btn btn-light font-small"
                                  id="addMulticityRow"
                                >
                                  <span class="fw-bold">+ Add City</span>
                                </button>
                              </div>
                              <div class="col-12 col-lg-6 col-xl-7">
                                <button
                                  type="submit"
                                  class="btn btn-search"
                                  onclick="window.location.href='flight-listing-multicity.html';"
                                >
                                  <span class="fw-bold">Search</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Advanced Options -->

               <div class="advanceOpt" style="background-color:white;padding:10px 15px;border-bottom-left-radius:0.5rem;border-bottom-right-radius:0.5rem;">
                  <div class="d-flex flex-wrap">
                    <div class="d-flex flex-wrap">
                      <v-switch v-model="directFlight" :label="this.$t('advancedOptions.directFlight')" color="rgb(38 34 94)" class="me-4"></v-switch>
                      <v-switch v-model="baggageInclusive" :label="this.$t('advancedOptions.BaggageInclusive')" color="rgb(38 34 94)" class="me-4"></v-switch>
                      <v-switch v-model="nearbyAirport" :label="this.$t('advancedOptions.nearbyAirport')" color="rgb(38 34 94)" class="me-4"></v-switch>
                      <div class="d-md-none d-flex align-items-center justify-content-end custom-font ms-auto">
                        <span class="not-collapsed collapsed f-size-14" data-bs-toggle="collapse"
                          href="#collapse-Example" aria-expanded="false"
                          aria-controls="collapse-Example"
                          style="padding-right: 5px;cursor: pointer;letter-spacing: 1px;" @click="toggleIcon">
                          <span class="text-decoration-underline" style="color:rgb(38 34 94);">{{ $t("advancedOptions.heading") }}</span>
                        </span>
                                  
                        <v-icon v-model="isAdvanceOpt" size="18" class="not-collapsed collapsed text-white" data-bs-toggle="collapse" 
                          href="#collapse-Example" aria-expanded="false" aria-controls="collapse-Example"                                
                          style="cursor: pointer; overflow-anchor: none;color:rgb(38 34 94) !important;">
                          {{ isAdvanceOpt ? "mdi-chevron-up-box-outline" : "mdi-chevron-down-box-outline"}}</v-icon>
                      </div>
                    </div>
                    <div class="d-md-flex d-none align-items-center justify-content-end custom-font ms-auto">
                      <span class="not-collapsed collapsed f-size-14" data-bs-toggle="collapse"
                        href="#collapse-Example" aria-expanded="false" aria-controls="collapse-Example"
                        style="padding-right: 5px;cursor: pointer;letter-spacing: 1px;" @click="toggleIcon">
                        <span class="text-decoration-underline" style="color:rgb(38 34 94);">{{ $t("advancedOptions.heading") }}</span>
                      </span>
                                  
                      <v-icon v-model="isAdvanceOpt" size="18" class="not-collapsed collapsed text-white" data-bs-toggle="collapse" 
                        href="#collapse-Example" aria-expanded="false" aria-controls="collapse-Example"                                
                        style="cursor: pointer; overflow-anchor: none;color:rgb(38 34 94) !important;">
                        {{ isAdvanceOpt ? "mdi-chevron-up-box-outline" : "mdi-chevron-down-box-outline"}}</v-icon>
                    </div>
                  </div>

                  <div class="displayedAdvanceOptions border-1 border-top px-2 pt-2" v-if="getallAdvanceOptions.length > 0">
                    <div class="d-flex flex-wrap">
                      <div v-for="(data, index) of getallAdvanceOptions" :key="index" class="me-1 d-flex">
                        {{ console.log(getallAdvanceOptions,'getallAdvanceOptionss') }}
                        <span v-if="data.selectedAirline" class="d-flex align-center">
                          <i class="mdi mdi-checkbox-marked-circle f-size-14" style="color:#94c20e;"></i>
                          <span class="f-size-12 fw-400 ms-1">
                            {{ airlineSelect == 'includeAirline' ? this.$t('advancedOptions.include') : this.$t('advancedOptions.exclude') }} {{ $t("advancedOptions.airline") }}: {{ data.selectedAirline.join(', ') }}
                          </span> 
                        </span>
                        <span v-if="data.stopsSelect && data.stopsSelect !== 'anyStop'" class="d-flex align-center">
                          <i class="mdi mdi-checkbox-marked-circle f-size-14" style="color:#94c20e;"></i>
                          <span class="f-size-12 fw-400 ms-1">{{ $t("advancedOptions.stops") }}: {{ data.stopsSelect }}</span> 
                        </span>
                        <span v-if="data.adlayoverTime && data.adlayoverTime !=='noPreference'" class="d-flex align-center">
                          <i class="mdi mdi-checkbox-marked-circle f-size-14" style="color:#94c20e;"></i>
                          <span class="f-size-12 fw-400 ms-1">{{ $t("advancedOptions.layoverTime") }}: Min: {{ data.adlayoverTime.min }}, Max: {{ data.adlayoverTime.max }}</span> 
                        </span>
                        <span v-if="data.selectedAirport" class="d-flex align-center">
                          <i class="mdi mdi-checkbox-marked-circle f-size-14" style="color:#94c20e;"></i>
                          <span class="f-size-12 fw-400 ms-1">
                            {{ adviaAirport == 'includeAirport' ? this.$t('advancedOptions.include') : this.$t('advancedOptions.exclude') }} {{ $t("advancedOptions.airport") }}: {{ data.selectedAirport.join(', ') }}
                          </span> 
                        </span>
                        <span v-if="data.avoidCountry" class="d-flex align-center">
                          <i class="mdi mdi-checkbox-marked-circle f-size-14" style="color:#94c20e;"></i>
                          <span class="f-size-12 fw-400 ms-1">{{ $t("advancedOptions.avoidCountry") }}: {{ data.avoidCountry.join(', ') }}</span> 
                        </span>
                        <span v-if="index < getallAdvanceOptions.length - 1" class="ms-1" style="color:rgb(38 34 94) !important"> | </span>
                      </div>
                      <v-icon @click="resetOptions" v-if="getallAdvanceOptions.length > 0" size="16" class="ms-2 m-auto rounded-3 bg-white" color="red" style="cursor: pointer;">
                        mdi-close-circle
                      </v-icon>
                    </div>
                  </div>

                  <div class="collapse border-1 border-top p-1 pt-2 mt-2" :class="{ show: isAdvanceOpt }" id="collapse-Example">
                    <div class="row g-4 dropdownSection">
                      <div class="col-lg-2 col-12">
                        <v-menu v-model="adAirlineMenu" :close-on-content-click="false" location="bottom center">
                          <template v-slot:activator="{ props }">
                            <div @click="openAdvanceMenu('adAirline')" class="input-styles align-center rounded-border d-flex justify-content-between" v-bind="props">
                              <span class="f-size-14 fw-500 text-capitalize me-2" style="color:#808080">{{ $t('advancedOptions.airline') }}</span>
                                <v-icon size="25" :class="{ 'rotate-icon': adAirlineMenu }" right dark >mdi-menu-down</v-icon>
                            </div>
                            </template>
                              <v-card class="pa-3">
                                <div>
                                  <v-radio-group v-model="airlineSelect" inline class="advanceRadio1 f-size-12">
                                    <v-radio value="includeAirline" :label="this.$t('advancedOptions.include')" class="me-2" @click="changeSelected(airlineSelect)"></v-radio>
                                    <v-radio value="excludeAirline" :label="this.$t('advancedOptions.exclude')" @click="changeSelected(airlineSelect)"></v-radio>
                                  </v-radio-group>
                                </div>
                                <div class="mt-2 mb-3">
                                  <div>
                                    <v-text-field v-model="airlineVal" :placeholder="this.$t('advancedOptions.airlineLabel')" @input="searchAirline(airlineVal)" 
                                      class="airlineCode" dense solo hide-details rounded-lg>
                                      <template v-slot:prepend-inner>
                                        <v-icon icon="mdi-magnify" size="20"></v-icon>
                                      </template>
                                      <template v-slot:append-inner>
                                        <div v-if="airlineVal && airlineList ? airlineVal.length >= 2 && !airlineList.length > 0 : false">
                                          <div class="spinner-border spinner-border-sm" role="status"></div>
                                        </div>
                                        <!-- <v-progress-circular v-if="!airlineVal.length >= 3 && !airlineList.length > 0" color="primary" indeterminate></v-progress-circular> -->
                                      </template>
                                    </v-text-field>
                                    <v-card v-if="airlineList.length > 0 && airlineVal.length > 1" max-height="200px" class="pa-3 pt-0 overflow-auto airlineCard" > 
                                      <div v-for="(item) of airlineList" :key="item">
                                        <v-checkbox v-model="selectedAirline" :value="`${item.name} - (${item.iata})`" :label="`${item.name} - (${item.iata})`" class="adcheckBox"></v-checkbox>
                                      </div>
                                    </v-card>
                                  </div>
                                </div>
                                <v-card-actions class="p-0" style="min-height:fit-content;">
                                  <v-btn color="danger" height="24px" class="f-size-12" variant="text" @click="resetAirlines()" v-if="selectedAirline.length > 0">
                                    <span>{{ $t('advancedOptions.resetBtn') }}</span>
                                      <span><i class="fa-solid fa-rotate-right"></i></span></v-btn>
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" height="24px" class="f-size-12" variant="text" @click="adAirlineMenu = false">{{ $t('advancedOptions.doneBtn') }}</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-menu>
                          </div>

                          <div class="col-lg-2 col-12 ps-0 advanceStops">
                            <v-menu v-model="adStopMenu" :close-on-content-click="false">
                              <template v-slot:activator="{ props }">
                                <div @click="openAdvanceMenu('adStops')" class="input-styles align-center rounded-border d-flex justify-content-between" v-bind="props">
                                  <span class="f-size-14 fw-500 text-capitalize me-2" style="color:#808080">{{ $t('advancedOptions.stops') }}</span>
                                    <v-icon size="25" :class="{ 'rotate-icon': adStopMenu }" right dark >mdi-menu-down</v-icon>
                                </div>
                              </template>
                              <v-card class="pa-3" style="width:160px; max-width:160px;">
                                <div>
                                  <v-radio-group v-model="stopsSelect" class="advanceRadio f-size-12">
                                    <v-radio value="anyStop" :label="this.$t('advancedOptions.anyStop')" :disabled="stopsSelect == 'anyStop'"></v-radio>
                                    <v-radio value="0" :label="this.$t('advancedOptions.nonStop')"></v-radio>
                                    <v-radio value="1" :label="this.$t('advancedOptions.upto1Stop')"></v-radio>
                                    <v-radio value="2" :label="this.$t('advancedOptions.upto2Stop')"></v-radio>
                                    <v-radio value="3" :label="this.$t('advancedOptions.upto3Stop')"></v-radio>
                                    <v-radio value="4" :label="this.$t('advancedOptions.fourStops')"></v-radio>
                                  </v-radio-group>
                                </div>
                                <v-card-actions class="p-0" style="min-height:fit-content;">
                                  <v-spacer></v-spacer>
                                    <v-btn color="primary" height="24px" class="f-size-12" variant="text" @click="adStopMenu = false">{{ $t('advancedOptions.doneBtn') }}</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-menu>
                          </div>

                          <div class="col-lg-2 col-12 ps-0 layoverStops">
                            <v-menu v-model="adlayoverMenu" :close-on-content-click="false">
                              <template v-slot:activator="{ props }">
                                <div @click="openAdvanceMenu('adlayover')" class="input-styles align-center rounded-border d-flex justify-content-between" v-bind="props">
                                  <span class="f-size-14 fw-500 text-capitalize me-2" style="color:#808080">{{ $t('advancedOptions.layoverTime') }}</span>
                                    <v-icon size="25" :class="{ 'rotate-icon': adlayoverMenu }" right dark >mdi-menu-down</v-icon>
                                </div>
                              </template>
                              <v-card class="pa-3" style="width:160px; max-width:160px;">
                                <div>
                                  <v-radio-group v-model="adlayoverTime" class="advanceRadio f-size-12">
                                    <v-radio value="noPreference" :label="this.$t('advancedOptions.nopreference')" :disabled="adlayoverTime == 'noPreference'"></v-radio>
                                    <v-radio :value="{ min: 1, max: 3 }" :label="this.$t('advancedOptions.hoursData1')"></v-radio>
                                    <v-radio :value="{ min: 3, max: 6 }" :label="this.$t('advancedOptions.hoursData2')"></v-radio>
                                    <v-radio :value="{ min: 6, max: 12 }" :label="this.$t('advancedOptions.hoursData3')"></v-radio>
                                    <v-radio :value="{ min: 12, max: 24 }" :label="this.$t('advancedOptions.hoursData4')"></v-radio>
                                  </v-radio-group>
                                </div>
                                <v-card-actions class="p-0" style="min-height:fit-content;">
                                  <v-spacer></v-spacer>
                                    <v-btn color="primary" height="24px" class="f-size-12" variant="text" @click="adlayoverMenu = false">{{ $t('advancedOptions.doneBtn') }}</v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-menu>
                          </div>

                          <div class="col-lg-2 col-12 p-0">
                            <v-menu v-model="adviaAirportMenu" :close-on-content-click="false">
                              <template v-slot:activator="{ props }">
                                <div @click="openAdvanceMenu('adviaAirport')" class="input-styles rounded-border d-flex justify-content-between align-center" v-bind="props">
                                  <span class="f-size-14 fw-500 text-capitalize" style="color:#808080">{{ $t('advancedOptions.viaAirport') }}</span>
                                    <v-icon size="25" :class="{ 'rotate-icon': adviaAirportMenu }" right dark >mdi-menu-down</v-icon>
                                </div>
                                </template>
                                  <v-card class="pa-3">
                                    <div>
                                      <v-radio-group v-model="adviaAirport" inline class="advanceRadio1 f-size-12">
                                        <v-radio value="includeAirport" :label="this.$t('advancedOptions.include')" class="me-2" @click="changeSelected(adviaAirport)"></v-radio>
                                        <v-radio value="excludeAirport" :label="this.$t('advancedOptions.exclude')" @click="changeSelected(adviaAirport)"></v-radio>
                                      </v-radio-group>
                                    </div>

                                    <div class="mt-2 mb-3">
                                      <div>
                                        <v-text-field v-model="airportVal" :placeholder="this.$t('advancedOptions.airportLabel')" @input="searchAirport(airportVal)" 
                                          class="airlineCode" dense solo hide-details rounded-lg>
                                          <template v-slot:prepend-inner>
                                            <v-icon icon="mdi-magnify" size="20"></v-icon>
                                          </template>
                                          <template v-slot:append-inner>
                                            <div v-if="airportVal && airportList ? airportVal.length >= 3 && !airportList.length > 0 : false">
                                              <div class="spinner-border spinner-border-sm" role="status"></div>
                                            </div>
                                          </template>
                                        </v-text-field>
                                        <v-card v-if="airportList.length > 0 && airportVal.length > 1" max-height="200px" class="pa-3 pt-0 overflow-auto airlineCard" > 
                                          <div v-for="(item) of airportList" :key="item">
                                            <v-checkbox v-model="selectedAirport" :value="`${item.city} - (${item.iata})`" :label="`${item.name} - (${item.iata})`" class="adcheckBox"></v-checkbox>
                                          </div>
                                        </v-card>
                                      </div>
                                    </div>
                                    <v-card-actions class="p-0" style="min-height:fit-content;">
                                      <v-btn color="danger" height="24px" class="f-size-12" variant="text" @click="resetAirport()" v-if="selectedAirport.length > 0">
                                        <span>{{ $t('advancedOptions.resetBtn') }}</span>
                                          <span><i class="fa-solid fa-rotate-right"></i></span></v-btn>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" height="24px" class="f-size-12" variant="text" @click="adviaAirportMenu = false">{{ $t('advancedOptions.doneBtn') }}</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>

                              <div class="col-lg-2 col-12 advanceStops">
                                <v-menu v-model="adavoidCountryMenu" :close-on-content-click="false">
                                  <template v-slot:activator="{ props }">
                                    <div @click="openAdvanceMenu('adavoidCountry')" class="input-styles rounded-border d-flex justify-content-between align-center" v-bind="props">
                                      <span class="f-size-14 fw-500 text-capitalize me-2" style="color:#808080">{{ $t('advancedOptions.avoidCountry') }}</span>
                                        <v-icon size="25" :class="{ 'rotate-icon': adavoidCountryMenu }" right dark >mdi-menu-down</v-icon>
                                    </div>
                                  </template>
                                  <v-card class="pa-3" style="width:160px; max-width:230px;">
                                    <div>
                                      <v-checkbox v-model="avoidCountry" label="US" value="US" class="adcheckBox"></v-checkbox>
                                      <v-checkbox v-model="avoidCountry" label="CA" value="CA" class="adcheckBox"></v-checkbox>
                                      <v-checkbox v-model="avoidCountry" label="UK" value="UK" class="adcheckBox"></v-checkbox>
                                    </div>
                                    <v-card-actions class="p-0" style="min-height:fit-content;">
                                      <v-spacer></v-spacer>
                                      <v-btn color="primary" height="24px" class="f-size-12" variant="text" @click="adavoidCountryMenu = false">{{ $t('advancedOptions.doneBtn') }}</v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>
                            </div>
                          </div>
                        </div>  
            </div>
          </div>
        </div>
      </div>
      <!-- body section -->
       
      <div class="content-section">
        <!-- Promotion banner section -->
        <!-- <section class="promo-ban" id="specialsDeals">
          <div class="container">
            <div class="row my-5" data-aos="fade-up">
              <div class="col-12">
                <h2 class="h3 text-center theme-text-secondary fw-bold mb-3">
                  Flight World Specials Deals
                </h2>
                <p class="text-center theme-text-accent-two mb-0">
                  Get the best deals on selected flights
                </p>
              </div>
            </div>
             promotional Slider 
            <div class="row">
              <div class="col-12" data-aos="fade-down">
                <div class="owl-carousel owl-theme" id="carouselPromo">
                  <div class="item">
                    <span class="position-absolute px-3 py-3">
                      <img
                        src="assets/images/icons/6E.jpg"
                        class="rounded-circle"
                        alt="airline-logo-6E"
                        title="airline-logo-6E"
                      />
                    </span>
                    <a href="#" class="d-block promo">
                      <img
                        src="assets/images/promotional/flihgt-offer01.png"
                        class="img-fluid"
                        alt="banner"
                      />
                      <div class="w-100">
                        <p class="font-small text-center promo-hover">
                          Flash Sale! Fares starting at $89
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="item">
                    <span class="position-absolute px-3 py-3">
                      <img
                        src="assets/images/icons/airasia.jpg"
                        class="rounded-circle"
                        alt="airline-logo-airasia"
                        title="airline-logo-airasia"
                      />
                    </span>
                    <a href="#" class="d-block promo">
                      <img
                        src="assets/images/promotional/flihgt-offer02.png"
                        class="img-fluid"
                        alt="banner"
                      />
                      <div class="w-100">
                        <p class="font-small text-center promo-hover">
                          Pre Book Sale off 40%
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="item">
                    <span class="position-absolute px-3 py-3">
                      <img
                        src="assets/images/icons/spicejet.jpg"
                        class="rounded-circle"
                        alt="airline-logo-spicejet"
                        title="airline-logo-spicejet"
                      />
                    </span>
                    <a href="#" class="d-block promo">
                      <img
                        src="assets/images/promotional/flihgt-offer03.png"
                        class="img-fluid"
                        alt="banner"
                      />
                      <div class="w-100">
                        <p class="font-small text-center promo-hover">
                          Book a deal in selected flight
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="item">
                    <span class="position-absolute px-3 py-3">
                      <img
                        src="assets/images/icons/vistara.jpg"
                        class="rounded-circle"
                        alt="airline-logo-vistara"
                        title="airline-logo-vistara"
                      />
                    </span>
                    <a href="#" class="d-block promo">
                      <img
                        src="assets/images/promotional/flihgt-offer04.png"
                        class="img-fluid"
                        alt="banner"
                      />
                      <div class="w-100">
                        <p class="font-small text-center promo-hover">
                          Fresh Sale! Fares starting at $30
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="item">
                    <span class="position-absolute px-3 py-3">
                      <img
                        src="assets/images/icons/thaiAir.jpg"
                        class="rounded-circle"
                        alt="airline-logo"
                        title="airline-logo"
                      />
                    </span>
                    <a href="#" class="d-block promo">
                      <img
                        src="assets/images/promotional/flihgt-offer05.png"
                        class="img-fluid"
                        alt="banner"
                      />
                      <div class="w-100">
                        <p class="font-small text-center promo-hover">
                          Flash Sale! upto 25% off
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> -->
        <!-- Travel deals & more start -->
        <section class="travel-deal py-5" id="travelDeals">
          <div class="container">
            <div class="row my-5" data-aos="fade-up">
              <div class="col-12">
                <h3 class="h3 text-center theme-text-secondary fw-bold mb-3">
                  {{ $t('homePageContent.contentSection.heading1') }}
                </h3>
                <p class="text-center theme-text-accent-two mb-0">
                  {{ $t('homePageContent.contentSection.subHeading1') }}
                </p>
              </div>
            </div>
            <div class="row" data-aos="fade-down">
              <div
                class="col-12 col-md-6 col-xl-4 mb-3"
                v-for="data in traveldealsData"
                :key="data"
              >
                <div
                  class="p-3 d-flex flex-row border theme-box-shadow theme-bg-white theme-border-radius cardeffect"
                >
                  <div class="flex-grow-1">
                    <h6 class="fw-bold">{{ data.route }}</h6>
                    <!-- <h6 class="fw-bold">
                      {{ data.from }}<i class="bi bi-arrow-right mx-2"></i
                      >{{ data.to }}
                    </h6> -->
                    <!-- <p class="mb-3">{{ data.dates }}</p> -->
                    <div class="float-start align-center">
                      <v-img
                        :src="data.image"
                        class="img-fluid theme-border-radius"
                        style="width: 30px; height: 30px; border-radius: 50px"
                      ></v-img>
                      <p
                        class="font-small d-inline-flex mb-0 align-middle lh-1 ms-2"
                      >
                        {{ data.airline }} {{ data.tripType }}<br />{{ data.class }}
                      </p>
                    </div>
                    <div class="float-end">
                      <span class="float-end h5 fw-bold theme-text-secondary">
                        <i class="bi bi-currency-dollar"></i>{{data.currencydisplay}} {{ data.price }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Travel deals -->
        <section class="featured-deal border-top py-5" id="featuredFlights">
          <div class="container">
            <div class="row my-5" data-aos="fade-up">
              <div class="col-12">
                <h4 class="h3 text-center theme-text-secondary fw-bold mb-3">
                  {{ $t('homePageContent.contentSection.heading2') }}
                </h4>
                <p class="text-center theme-text-accent-two mb-0">
                  {{ $t('homePageContent.contentSection.subHeading2') }}
                </p>
              </div>
            </div>
            <div class="row" data-aos="fade-down">
              {{ console.log(flightdealsData,'flightdealsDataflightdealsData...1') }}
              <div class="col-12 col-md-6 col-lg-3 mb-3" v-for="data in flightdealsData" :key="data">
                <div
                  class="d-flex flex-column theme-box-shadow theme-border-radius"
                >
                  <div
                    class="picHeight overflow-hidden hoverShine theme-border-radius-top"
                  >
                    <figure class="mb-0 img-effect">
                      <v-img :src="data.image"></v-img>
                    </figure>
                    <div class="airline-tags position-absolute">
                      <div
                        class="flood-effect airline-icon d-flex flex-row align-items-center"
                      >
                        <v-img
                          :src="data.airlineimg"
                          width="20px"
                          alt="airline"
                          class="img-fluid rounded-pill"
                        />
                        <a href="#" class="font-small pe-2 ms-1"
                          >{{ data.airline }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <a
                    href="#"
                    class="position-relative py-3 px-3 mb-0 theme-border-radius-bottom card-effect theme-bg-white"
                  >
                    <div class="card-box"></div>
                    <span class="mb-0 pb-2 theme-text-accent-one"
                      >{{ data.route }}</span
                    >
                    <span class="d-flex pb-2 font-small theme-text-accent-two"
                      >{{ data.tripType }},
                      <span class="d-inline-flex">{{ data.class }}</span>
                    </span>
                    <span class="d-flex theme-text-accent-one">
                        <span>{{$t('homePageContent.contentSection.from')}}</span>&nbsp;
                       <span class="fw-bold price theme-text-secondary"> {{ currencydisplay }} {{ data.price }} {{ currencyIsocode }}</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- Flight top destination -->
        <section class="flight-destination py-5 border-top" id="topDestination">
          <div class="container">
            <div class="row my-5" data-aos="fade-down">
              <div class="col-12">
                <h4 class="h3 text-center fw-bold theme-text-secondary mb-3">
                  {{ $t('homePageContent.contentSection.heading3') }}
                </h4>
                <p class="text-center theme-text-accent-two mb-0">
                  {{ $t('homePageContent.contentSection.subHeading3') }}
                </p>
              </div>
            </div>
            <div class="row" data-aos="fade-up">
              <!-- repetable -->
              <div class="col-12 col-md-4 mb-3" v-for="data in getTopdestinationdata" :key="data">
                <div
                  class="theme-box-shadow theme-border-radius px-4 py-4 theme-bg-white"
                >
                  <div class="row g-0">
                    <div
                      class="col-12 col-xxl-6 overflow-hidden theme-border-radius"
                    >
                      <div class="overflow-hidden hoverShine">
                        <figure class="mb-0 img-effect">
                          <v-img :src="data.image"></v-img>
                        </figure>
                      </div>
                    </div>
                    <div class="col-12 col-xxl-6">
                      <div class="mt-2 mt-xxl-0 ps-md-0 ps-xxl-3">
                        <span class="d-flex fw-bold"
                          >{{ data.headline }}</span
                        >
                        <span
                          class="d-flex font-small fw-normal theme-text-accent-two"
                          >{{ data.subheadline }}</span
                        >
                        <a
                          href="#"
                          class="d-inline-flex mt-2 text-link text-link-effect"
                          ><span>{{ $t('homePageContent.contentSection.bookNow') }}</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- how it work -->
        <section class="py-5 theme-bg-white border-top" id="howWork">
          <div class="container">
            <div class="row overflow-hidden py-5">
              <div class="col-12 col-lg-3 align-self-center" data-aos="fade-right">
                <p class="fw-bold theme-text-accent-two">{{ $t('homePageContent.contentSection.subheading') }}</p>
                <h3 class="h3 fw-bold theme-text-secondary mb-3">
                  {{ $t('homePageContent.contentSection.heading4') }}
                </h3>
                <p class="font-small fw-bold theme-text-accent-one mb-0">
                  {{ $t('homePageContent.contentSection.subHeading4') }}
                </p>
              </div>
              <div v-for="(data,index) in gethowitworksData" :key="data" class="col-12 col-lg-3 mb-4 mb-lg-0" data-aos="fade-left">
                <div
                  class="text-center theme-box-shadow theme-border-radius process-card"
                >
                  <v-img :src="data.image" width="70px" height="70px" style="margin:auto;margin-top:3rem !important;"></v-img>
                  <p class="pt-4 pb-2 mb-0">{{ data.step }}</p>
                  <p class="mb-0 pb-5 theme-text-accent-two">
                    {{ data.description }}
                  </p>
                  <div class="step-corner">
                    <div class="step-arrow">{{ index+1 }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- recommendations content -->
        <section class="recom-sec py-5 border-top" id="Recommendations">
          <div class="container">
            <div class="row my-5">
              <div class="col-12" data-aos="fade-up" data-aos-delay="200">
                <p class="text-center theme-text-accent-two mb-3">
                  <span class="ribbon">{{ $t('homePageContent.contentSection.heading5') }}</span>
                </p>
                <h5 class="theme-text-secondary h3 fw-bold text-center mb-0">
                  {{ $t('homePageContent.contentSection.subHeading5') }}
                </h5>
              </div>
            </div>
            <div class="row">
              <div v-for="data in getrecommendationsData" :key="data" class="col-12 col-md-4 mb-4" data-aos="fade-down" data-aos-delay="300">
                <div
                  class="position-relative theme-border-radius theme-box-shadow item-effect"
                >
                  <div class="con theme-text-white theme-text-shadow">
                    <h2 class="h6 fw-bold d-inline-flex border-bottom pb-2">
                      {{ data.location }}
                    </h2>
                    <span class="d-flex font-14">{{ $t('homePageContent.contentSection.getPrices') }}</span>
                  </div>

                  <!-- <v-img :src="require('@/assets/images/articles08.png')"></v-img> -->
                  <img
                    :src="data.image"
                    class="img-fluid"
                    alt="Unique Experience One"
                    title="Unique Experience One"
                  />
                  <a href="#" class="text-center"
                    ><v-icon class="bi bi-search mt-2" size="30"></v-icon
                  ></a>
                </div>
              </div>
            </div>
            <!-- more button-->
            <!-- <div class="row my-4">
              <div class="col-12 text-center">
                <a
                  href="blog.html"
                  class="d-inline-flex mt-2 text-link text-link-effect"
                >
                  <span class="py-4 px-4">See more Popular Experiences</span>
                </a>
              </div>
            </div> -->
          </div>
        </section>
      </div>
     
     
      <!-- page footer section -->
    </div>


     <!-- <div v-if="alert" role="alert" aria-live="assertive" aria-atomic="true" class="toast bg-error" data-bs-autohide="false"
      style="display:block !important; z-index:9999 !important; top:20%;">
        <div class="toast-body d-flex justify-content-between f-size-16 fw-500">
          Please Fill Your Details
          <button
                    type="button"
                    @click="alert = false"
                    class="text-reset"
                    data-bs-dismiss="toast"
                    aria-label="Close"
                    color="white"
                  >
                    <v-icon>mdi-close</v-icon>
                  </button>
        </div>
      </div> -->



      <div class="toast" :class="isNotActive ? 'active border-danger' : ''">
        <div class="toast-content">
          <i class="bi bi-x-lg toast-check" :class="isNotActive ? 'bg-danger' : 'bg-danger'"></i>
          <div class="message">
            <!-- <span class="message-text text-1">Please Enter Correct Details</span> -->
            <!-- <span class="message-text text-1">{{ ErrorMessage }}</span> -->
            <span class="message-text text-2" style="font-size:16px; font-weight: 500; color:black;">
              Please enter valid details</span>
          </div>
        </div>

        <i class="bi bi-x-lg toast-close" @click="closeToast"></i>
        <div class="progress" :class="isNotActive ? 'active' : ''" style="color:#ff0000;"></div>
    </div>



  </body>
</template>

<script>
import moment from "moment";
// import multicityCom from "@/components/multicityCom.vue";
import axios from "axios";
// import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import AutoComplete from "primevue/autocomplete";
// import dataloader from "@/components/dataLoder.vue";
// import userData from '@/airport_icao.json';
// import headerCom1 from "@/components/headerCom1.vue";
import searchForm from "@/components/searchForm.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
import { ref } from "vue";

// import { ref, onMounted } from 'vue';

// const datepickerDate = ref();

// For demo purposes assign range from the current date
// onMounted(() => {
//   const startDate = new Date();
//   const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
//   datepickerDate.value = [startDate, endDate];
//   console.log(datepickerDate,'datepickerDatedatepickerDate')

// })

export default {
  components: {
    searchForm,
    // multicityCom,
    // VueDatePicker,
    // AutoComplete,
    // headerCom1,
    // dataloader,
  },

  data() {
    return {
      isDropdown: false,
      alert:false,
      getLangname:"",
      countries: [{ name: "Australia", code: "AU" }],

      imgItems: [
        {
          src: require("@/assets/homesl1.png"),
        },
        {
          src: require("@/assets/homesl2.png"),
        },
        {
          src: require("@/assets/homesl3.png"),
        },
        {
          src: require("@/assets/homesl4.png"),
        },
        {
          src: require("@/assets/homesl5.png"),
        },
      ],

      amezingData: [
        {
          from: "Toronto YYZ",
          to: "Japan AXT",
          fromDate: "Wed, 15/05 ",
          toDate: "Thu, 30/05",
          airline: "Japan Airlines - Roundtrip",
          cabin: "Economy",
          price: "1,096",
          logo: "https://dl.buymytrip.com/resources/airlines_logos/JL.png",
        },

        {
          from: "Ottawa YOW",
          to: "London LHR",
          fromDate: "Wed, 15/05",
          toDate: "Thu, 30/05",
          airline: "British Airways - Roundtrip",
          cabin: "Economy",
          price: "1,196",
          logo: "https://dl.buymytrip.com/resources/airlines_logos/BA.png",
        },
        {
          from: "Vancouver YVR",
          to: "Norway AES",
          fromDate: "Wed, 15/05",
          toDate: "Thu, 30/05",
          airline: "Air Canada - Roundtrip",
          cabin: "Economy",
          price: "1,085",
          logo: "https://dl.buymytrip.com/resources/airlines_logos/AC.png",
        },

        {
          from: "Montreal YUL ",
          to: "Australia ADL",
          fromDate: "Wed, 15/05",
          toDate: "Thu, 30/05",
          airline: "Qantas- Roundtrip",
          cabin: "Economy",
          price: "1,296",
          logo: "https://dl.buymytrip.com/resources/airlines_logos/QR.png",
        },

        {
          from: "Toronto YYZ ",
          to: "Greece AXD",
          fromDate: "Wed, 15/05",
          toDate: "Thu, 30/05",
          airline: "Lufthansa - Roundtrip",
          cabin: "Economy",
          price: "1,296",
          logo: "https://dl.buymytrip.com/resources/airlines_logos/LH.png",
        },

        {
          from: "Winnipeg YWG",
          to: "Spain LEI",
          fromDate: "Wed, 15/05",
          toDate: "Thu, 30/05",
          airline: "Air France  - Roundtrip",
          cabin: "Economy",
          price: "1,596",
          logo: "https://dl.buymytrip.com/resources/airlines_logos/AF.png",
        },
      ],

      passSection: false,
      fromloader: false,
      toloader: false,
      fromlabel: false,
      tolabel: false,
      loader: false,
      dialog1: false,
      dialog2: false,
      dateFormat: "MMM dd",
      sixWeekMode: true,
      classSelect: false,
      subEmail: "",
      subPhonenumber: "",
      multiCalender: true,
      validData: false,
      selectedCountry: "",
      maximumletter: false,
      maximumletter1: false,
      fromDate: "",
      name1: "John Doe",
      source: "vasanth",
      trav: true,
      loginbox: false,
      registerbox: false,
      terms: false,
      visible: false,

      // showCookieInfo: false,

      selectAirports: "",
      autocompleteApi:"",
      red: "1",
      red1: 1,
      rount: true,
      Oneway: false,
      multicity: false,
      // select: "Economy",
      travels: false,
      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      classType: "Economy",
      addition: 0,
      customer: [],
      buton: true,
      avath: false,
      error: false,
      viewbox: false,
      isVisible: true,
      // selectAirports: "",
      token: "Tc7RQaX79Hrx5CFK",
      swapData: false,
      // Autocomplete
      loading: false,
      fromAirports: [],
      airitems: [],
      airitems1: [],

      inputCity: "",
      inputCityto: "",
      typedrop: false,
      typedrop1: false,
      city: [],
      city1: [],

      // Autocomplete End

      items: [],

      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,
      selectedOption: null,
      disButton: false,
      loadSearch: false,
      valid: false,
      Errormsg: "",
      agreeMsg: "",
      subscribe: {
        email: "",
        phone: "",
        checkAgree: false,
      },

      roundedShadow: false,
      emailBox: false,
      phoneNumber: true,

      subEmailvalid: [
        (v) => !!v || "This field is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],
      phoneValidate: [
        (v) => /^\+?[0-9]{1,16}$/.test(v) || "Please enter a phone number ",
        // (v) => /^(\+?[0-9]{1,12})?$/.test(v) || "Please enter a valid phone number (up to 12 digits, starting with +)",
      ],

      input: [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ],

      addmore: true,
      clearall: false,
      isFocused1: false,
      isFocused2: false,
      activetrip: 'Roundtrip',
      oneway: {
        from: null,
        to: null,
        travelDate: ref([]),
        depdate: null,
        redate: null,
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        tripType: "",
      },

      multiData: {
        itinery: [
          {
            from: null,
            to: null,
            departDate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom: false,
            autocomTo: false,
            fromloader: false,
            toloader: false,
          },
          {
            from: null,
            to: null,
            departDate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocomfrom: false,
            autocomTo: false,
            fromloader: false,
            toloader: false,
          },
        ],
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        tripType:"",
      },

      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ONEWAY",
              CabinType: "Y",
              Currency: "CAD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },
      multinumber1: 1,
      multinumber2: 1,
      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ROUND",
              CabinType: "Y",
              Currency: "CAD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      array: [
        {
          name: "keerthi",
          age: 90,
        },
        {
          name: "keerthi",
          age: 90,
        },
      ],

      Adult: [],
      Child: [],
      recendFrom: [],
      recendTo: [],
      resultFrom: [],
      resultTo: [],
      valid1: false,
      forData: false,
      formres: false,
      formres1: false,
      Infrant: [],
      urldedate: "",
      urlredate: "",
      roundfrom: "",
      roundto: "",
      concatenatedArray: [],
      loadingData: true,
      dataloaded: false,
      randomAlphabets: "",
      cacheData: "",
      fromRecent: "",
      toRecent: "",
      multi: {
        from: null,
        to: null,
      },
      deatail: {
        name: "",
        email: "",
        password: "",
        repassword: "",
      },
      loginuser: {
        email: "",
        password: "",
      },
      userName: [(v) => !!v || "Please enter your name"],
      email: [(v) => !!v || "Please enter your Email"],
      password: [(v) => !!v || "Please enter your Password"],
      repassword: [
        (v) => !!v || "Please enter your Re-Password",
        (v) => v === this.deatail.password || "Passwords do not match",
      ],
      pnr: [(v) => !!v || "Please enter your PNR/Booking Ref no"],
      emaillast: [(v) => !!v || "Please enter your Email/Lastname"],
      Destination: [
        {
          name: "Andaman",
          rate: "$480",
          url: require("@/assets/andaman.jpg"),
        },
        {
          name: "SriLanka",
          rate: "$890",
          url: require("@/assets/SriLanka.jpg"),
        },
        {
          name: "Dubai",
          rate: "$2310",
          url: require("@/assets/DUBAI.jpg"),
        },
        {
          name: "Thailand",
          rate: "$760",
          url: require("@/assets/Thailand.jpg"),
        },
      ],

      service: [
        {
          url: require("@/assets/choose-location.png"),
          name: "Best Price Promise",
          letter: "Get best fares to destinations worldwide.",
        },
        {
          url: require("@/assets/plane.png"),
          name: "Get Great Deals! ",
          letter: "Best fares in 500+ airlines for low airfares!",
        },
        {
          url: require("@/assets/star2.png"),
          name: "Expert Guidance",
          letter: "Get personalized assistance from our travel experts.",
        },
      ],
      profile: [
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Alex Feder",
        },
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Elly Forb",
        },
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Mia Nest",
        },
        {
          url: require("@/assets/double-quotes.png"),
          url2: require("@/assets/profile.png"),
          name: "Dan Dorno",
        },
      ],

      slide: [
        {
          url: require("@/assets/slide1.jpg"),
          type: "Exploration",
        },
        {
          url: require("@/assets/slide2.jpg"),
          type: "Vacation",
        },
        {
          url: require("@/assets/slide3.jpg"),
          type: "Business",
        },
        {
          url: require("@/assets/slide4.jpg"),
          type: "Journey",
        },
      ],

      airlineIcao: {},

      // uniqueId:[],

      airlineIcaoUrl: "",
      airlineIcaoType: "",

      focusedElement: null,
      changeTextFrom: "",
      changeTextTo: "",
      focusEvent1: false,
      focusEvent2: false,
      focusEvent3: false,
      dateUnfocus: false,
      closeDateMenu: false,

      focusFalse: true,

      pluginWhatspp: false,
      whatsappApi: "",

      words: [ this.$t("homePageContent.contentSection.flights"), 
      this.$t("homePageContent.contentSection.ticket"), 
      this.$t("homePageContent.contentSection.seat"), 
      this.$t("homePageContent.contentSection.seat")],
      currentIndex: 0,
      intervalId: null,
      multidrop: [],
      multidrop1: [],
      

      isNotActive: false,

      // Advanced Options
      isAdvanceOpt:false,

      adAirlineMenu:false,
      adStopMenu:false,
      adlayoverMenu:false,
      adviaAirportMenu:false,
      adavoidCountryMenu:false,

      directFlight:false,
      baggageInclusive:false,
      nearbyAirport:false,
      airlineSelect:"includeAirline",
      stopsSelect:'anyStop', 
      adlayoverTime:'noPreference',
      adviaAirport:"includeAirport",
      selectedAirline:[],
      selectedAirport:[],
      avoidCountry:[],

      airlineVal:"",
      airportVal:"",
      airlineList:[],
      airportList:[],

      getallAdvanceOptions : [],
      // selectAirports: "",
      airlineApi:"",
      airportdataApi:"",
      subscribeApi:"",
      currencyIsocode:"",
      currencydisplay:"",


      traveldealsApi:"",
      flightdealsApi:"",
      topDestinationApi:"",
      howitworksApi:"",
      recommendationsApi:"",

      traveldealsData:[],
      flightdealsData:[],
      getTopdestinationdata:[],
      gethowitworksData:[],
      getrecommendationsData:[],
    };
  },

  beforeUnmount() {
    document.body.removeEventListener("click", this.handleOutsideClick);
    clearInterval(this.intervalId);
  },

  methods: {
    openAdvanceMenu(menu){
      this.adAirlineMenu = menu === 'adAirline';
      this.adStopMenu = menu === 'adStops';
      this.adlayoverMenu = menu === 'adlayover';
      this.adviaAirportMenu = menu === 'adviaAirport';
      this.adavoidCountryMenu = menu === 'adavoidCountry';
    },  
    resetOptions() {
      this.getallAdvanceOptions = [];
      this.directFlight = false;
      this.baggageInclusive = false;
      this.nearbyAirport = false;
      this.airlineSelect = "includeAirline";
      this.stopsSelect = 'anyStop'; 
      this.adlayoverTime = 'noPreference';
      this.adviaAirport = "includeAirport";
      this.selectedAirline = [];
      this.selectedAirport = [];
      this.avoidCountry = [];

      this.airlineVal = "";
      this.airportVal = "";
      this.airlineList = [];
      this.airportList = [];
    },

    resetAirlines(){
      this.airlineSelect = "includeAirline";
      this.selectedAirline = [];
      this.airlineVal = "";
      this.airlineList = [];
    },
    resetAirport(){
      this.adviaAirport = "includeAirport";
      this.selectedAirport = [];
      this.airportVal = "";
      this.airportList = [];
    },
    
    changeSelected(data){
      if(data == 'includeAirline' || data == 'excludeAirline'){
        this.airlineVal = null;
        this.airlineList = [];
        this.selectedAirline = [];
      }
      else{
        this.airportVal = null
        this.airportList = [];
        this.selectedAirport = [];
      }
    },
     loadSearchData(){
      const tabId = sessionStorage.getItem("tabId");
      const tripType = this.$route.query.tripType;
      // console.log(this.searchCase,'storedDatasdasdasdas...1')

      let key = '';

      if (tripType === 'RT' || tripType === 'OW' ) {
        key = tripType === 'RT'? `searchedData-round-${tabId}` : `searchedData-oneway-${tabId}`;
        this.Oneway = tripType === 'OW';
        this.rount = tripType === 'RT';

        this.activetrip = tripType === 'RT' ? 'Roundtrip' : 'Oneway';
        console.log(key,'storedDatasdasdasdas...2');
      }
      else if (tripType === 'MC') {
        key = `searchedData-multicity-${tabId}`;
        this.multicity = true;
        this.activetrip = 'Multicity';
        console.log(key,'storedDatasdasdasdas...3');
      }

      console.log(this.activetrip,'sdasdactivetripasdad');
      this.act(this.activetrip);

      const storedData = localStorage.getItem(key);
      console.log(storedData,'storedDatasdasdasdas...4')


      if (storedData) {
        if (this.multicity) {
          this.multiData = JSON.parse(storedData) || this.searchCase;
          console.log(storedData,'storedDatasdasdasdas...44');
          console.log(this.searchCase,'storedDatasdasdasdas...55');
          console.log(this.multiData,'storedDatasdasdasdas...5');
        } else {
          this.searchData = JSON.parse(storedData) || this.searchCase;
          console.log(this.searchData,'storedDatasdasdasdas...6');
          
          this.oneway = this.searchData;
          this.inputCity = this.searchData.from;
          this.inputCityto = this.searchData.to;
        console.log(this.searchData,'storedDatasdasdasdas...7');
    }
    
        this.classType = this.searchData ? this.searchData.class : this.multiData.class;
        console.log(this.classType,'asdasdclagetClasTypessTypeasdasda');

        this.adultValue = this.searchData ? parseInt(this.searchData.adult) : parseInt(this.multiData.adult);
        this.childValue = this.searchData ? parseInt(this.searchData.child) : parseInt(this.multiData.child);
        this.infantValue = this.searchData ? parseInt(this.searchData.infant) : parseInt(this.multiData.infant);
        this.passengerData();
        // this.addedPassenger();

      } else {
        console.log('No search data found for this tab.');
      }

    },
    dataAssign(){
      let onewayObj = {}
      // if(this.directFlight) onewayObj.directFlight = this.directFlight;
      if(this.baggageInclusive) onewayObj.baggageInclusive = this.baggageInclusive;
      if(this.nearbyAirport) onewayObj.nearbyAirport = this.nearbyAirport;
      if(this.avoidCountry && this.avoidCountry.length > 0) onewayObj.avoidCountry = this.avoidCountry;
      if(this.adlayoverTime && this.adlayoverTime !== 'noPreference') onewayObj.adlayoverTime = this.adlayoverTime;
      this.airlineSelect == 'includeAirline' && this.selectedAirline.length > 0 ? onewayObj.includeAirline = this.selectedAirline : [];
      this.airlineSelect == 'excludeAirline' && this.selectedAirline.length > 0 ? onewayObj.excludeAirline = this.selectedAirline : [];
      this.adviaAirport == 'includeAirport' && this.selectedAirport.length > 0 ? onewayObj.includeAirport = this.selectedAirport : [];
      this.adviaAirport == 'excludeAirport' && this.selectedAirport.length > 0 ? onewayObj.excludeAirport = this.selectedAirport : [];

      console.log(this.stopsSelect,'sdstopsSsadsdelectasd...1');
      if(this.stopsSelect.length > 0 && (this.stopsSelect !== 'anyStop' || !this.stopsSelect.includes('anyStop'))) {
        console.log(this.stopsSelect,'sdstopsSsadsdelectasd...2');
        if(this.directFlight || this.stopsSelect == 0 || this.stopsSelect == '0'){
          this.stopsSelect = '0';
          onewayObj.stopsSelect = this.stopsSelect;
        }
        else{
          onewayObj.stopsSelect = this.stopsSelect;
        }
      }

      this.rount || this.Oneway ? this.oneway.advanceOptions = onewayObj : this.multiData.advanceOptions = onewayObj;
      console.log(this.oneway,'onewayonrounttripewayoneway...2');
      console.log(this.multiData,'multiDataonrounttripewaymultiData...2');
    },
   getadvanceOption(){
      // console.log(this.searchCase,'sadsdasdasdsearchCasea...1');
      this.localSearchCase = JSON.parse(this.searchCase);

      if (this.localSearchCase && this.localSearchCase.advanceOptions) {
        let getSearchedData = this.localSearchCase.advanceOptions;

        this.baggageInclusive = getSearchedData.baggageInclusive ? true : false;
        this.nearbyAirport = getSearchedData.nearbyAirport ? true : false;
        this.stopsSelect = getSearchedData.stopsSelect ? getSearchedData.stopsSelect : 'anyStop';
        this.directFlight = getSearchedData.stopsSelect ? getSearchedData.stopsSelect == '0' : false;
        this.adlayoverTime = getSearchedData.adlayoverTime ? getSearchedData.adlayoverTime : 'noPreference';
        this.avoidCountry = getSearchedData.avoidCountry ? [getSearchedData.avoidCountry] : [];
        this.airlineSelect = (getSearchedData.includeAirline) ? 'includeAirline' : (getSearchedData.excludeAirline) ? 'excludeAirline' : 'includeAirline';
        this.adviaAirport = getSearchedData.includeAirport ? 'includeAirport' : (getSearchedData.excludeAirport) ? 'excludeAirport' : 'includeAirport';
        this.selectedAirline = (getSearchedData.includeAirline) ? getSearchedData.includeAirline : (getSearchedData.excludeAirline) ? getSearchedData.excludeAirline : [];
        this.selectedAirport = (getSearchedData.includeAirport) ? getSearchedData.includeAirport : (getSearchedData.excludeAirport) ? getSearchedData.excludeAirport : [];
        }
      },
      activeAdvanceSearch() {

        console.log('yessactiveAdvanceSearch')
        const hasAvoidCountry = Array.isArray(this.avoidCountry) && this.avoidCountry.length > 0;
        const hasSelectedAirline = Array.isArray(this.selectedAirline) && this.selectedAirline.length > 0;
        const hasSelectedAirport = Array.isArray(this.selectedAirport) && this.selectedAirport.length > 0;

        // Ensure stopsSelect is treated correctly based on its type
        const isStopValid = 
          (Array.isArray(this.stopsSelect) && this.stopsSelect.length > 0 && 
          (this.stopsSelect[0] !== 'anyStop')) || 
          (this.stopsSelect !== 'anyStop' && this.stopsSelect);

        const hasAdlayoverTime = this.adlayoverTime && this.adlayoverTime !== 'noPreference';

        console.log(hasAvoidCountry,hasSelectedAirline,hasSelectedAirport,isStopValid,hasAdlayoverTime,'sadsdasdasdsearchCasea...12')

        if (
          this.directFlight || 
          this.baggageInclusive || 
          this.nearbyAirport || 
          hasAvoidCountry || 
          isStopValid || 
          hasAdlayoverTime || 
          hasSelectedAirline || 
          hasSelectedAirport
        ) {
          console.log(this.directFlight,this.baggageInclusive,this.nearbyAirport,hasAvoidCountry,isStopValid,hasAdlayoverTime,
            hasSelectedAirline,hasSelectedAirport,'sadsdasdasdsearchCasea...2');
          return true;
        } else {
          console.log(this.directFlight,this.baggageInclusive,this.nearbyAirport,hasAvoidCountry,isStopValid,hasAdlayoverTime,
            hasSelectedAirline,hasSelectedAirport,'sadsdasdasdsearchCasea...3');
          delete this.oneway.advanceOptions; 
          delete this.multiData.advanceOptions; 
          return false;
        }
    },


      handleOptionChange(optionKey, newValue) {
          const removableValues = ["includeAirline", "excludeAirline","includeAirport", "excludeAirport","anyStop",'0', "noPreference"];

        const isRemovable = !newValue || (Array.isArray(newValue) && newValue.length === 0);
        const isSpecialOption = removableValues.includes(newValue);

        if (isRemovable || isSpecialOption) {
          this.getallAdvanceOptions = this.getallAdvanceOptions.filter(option => !Object.prototype.hasOwnProperty.call(option, optionKey));
          return;
        }

        const existingIndex = this.getallAdvanceOptions.findIndex(option => 
          Object.prototype.hasOwnProperty.call(option, optionKey)
        );

        if (newValue && newValue.length !== 0) {
          if (existingIndex !== -1) {
            this.getallAdvanceOptions[existingIndex][optionKey] = newValue;
          } else {
            this.getallAdvanceOptions.push({ [optionKey]: newValue });
          }
        } else {
          if (existingIndex !== -1) {
            this.getallAdvanceOptions.splice(existingIndex, 1);
          }
        }
        console.log(this.getallAdvanceOptions,'getallAsadvancsadaeOptionssdasdasda');
      },
      async searchAirline(event) {

      if (event.length > 1) {
        await axios.get(`${this.airlineApi}?airlineString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response,"responseairline");
            this.airlineList = response.data;
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });
      }
    },

    async searchAirport(event) {

      if (event.length >= 3) {
        await axios.get(`${this.airportdataApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response,"responseeairportdata");
            this.airportList = response.data;
          })

          .catch((error) => {
            console.log(error, "erroroorrrr.....");
          });
      }
    },
    departureLabel() {
      return this.$t('formsContents.labels.departure');
    },
    arrivalLabel() {
      return this.$t('formsContents.labels.arrival');
    },
    selectLabel() {
      return this.$t('formsContents.labels.selectDate');
    },
    onClickOutside() {
          this.formres = false;
          this.formres1 = false;
        },
    closeToast(){
      this.isNotActive = false;
      // this.alert = false
    },
    toggleDropdown() {
      this.isDropdown = !this.isDropdown;
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    changeWord() {
      this.currentIndex = (this.currentIndex + 1) % this.words.length;
      this.$refs.changingWord.innerText = this.words[this.currentIndex];
      // const changingword = this.$refs.changingWord;
      // changingword.style.opacity = 0;

      // setTimeout(() => {
      //   changingword.innerText = this.words[this.currentIndex];
      //   changingword.style.opacity = 1;
      //   this.currentIndex = (this.currentIndex + 1) % this.words.length;
      // }, 500);
    },

    // closeCookieInfo() {
    //     this.showCookieInfo = false;
    //   },

    getrecent(data) {
      console.log(data, "pppp");
      this.formres = false;
      this.oneway.from = data.from;
      this.inputCity = data.from;
      this.fromRecent = data.from;
      // this.oneway.from= $data[$data.length - 1].replace(")", '')
    },

    getrecent1(data) {
      this.formres1 = false;
      this.oneway.to = data.to;
      this.inputCityto = data.to;
      this.toRecent = data.to;
      //
    },

    getrecentData(data) {
      console.log(data, "ahahakkaaj");
      // let res = data.split(",");
      // let result = "";
      // res.forEach((v, i) => {
      //   if (i !== res.length - 1) result += v + ",";
      // });
      // return result.slice(0, -1);
    },

    generateRandomAlphabets() {
      const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let result = "";

      for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * alphabets.length);
        result += alphabets[randomIndex];
      }

      this.randomAlphabets = result;
      // this.updateCanvas();
    },
    // updateCanvas() {
    //   const canvas = this.$refs.myCanvas;
    //   const ctx = canvas.getContext("2d");
    //   ctx.clearRect(0, 0, canvas.width, canvas.height);
    //   ctx.font = "20px Arial";
    //   ctx.fillStyle = "white";
    //   ctx.fillText(this.randomAlphabets, 10, 40);
    // },
    generate() {
      this.generateRandomAlphabets();
    },
    oneData(value) {
      console.log(value, "lllll");
      if (value.length == 3) {
        this.search(value);
      }
    },
    keyData($data) {
      if (this.randomAlphabets == $data.toUpperCase()) {
        this.loadingData = false;
      } else {
        this.loadingData = true;
      }
    },
    subData($data) {
      console.log($data, "tytttt");
      if ($data.includes("com")) {
        document.getElementById("visible").style.visibility = "visible";
        this.dataloaded = true;
      } else {
        this.dataloaded = false;
      }
    },

    focusAutocomplete() {
      // this.focusedElement = element;
      // if (this.focusedElement === "from") {
      //   this.formres = true;
      //   this.formres1 = false;
      //   this.focusEvent1 = true;
      //   this.focusEvent2 = false;
      //   this.focusEvent3 = false;
      //   this.$refs.fromAutoComplete.$el.querySelector("input").focus();
      // } else if (this.focusedElement === "to") {
      //   this.formres1 = true;
      //   this.formres = false;
      //   this.focusEvent3 = false;
      //   this.focusEvent1 = false;
      //   this.focusEvent2 = false;
      //   this.$nextTick(() => {
      //     this.$refs.secondAutoComplete.$el.querySelector("input").focus();
      //   });
      // }
    },

    // focusMulti_AutoComplete(element, index) {
    //   this.multiData.itinery.map((v, i) => {
    //     if (index == i) {
    //       if (!this.focusFalse) {
    //         this.focusedElement = null;
    //         v.focusMultiEvent3 = false;
    //       } else {
    //         this.focusedElement = element;
    //       }
    //     }
    //   });

    //   this.multiData.itinery.map((v, i) => {
    //     if (index == i) {
    //       v.focusMultiEvent3 = false;
    //       console.log(v.focusMultiEvent3, "checkcheckcheck");
    //     }
    //   });

    //   if (this.focusedElement === "from") {
    //     this.multiData.itinery.map((v, i) => {

    //       if (i == index) {
    //         v.formres = true;
    //         v.formres1 = false;

    //         v.focusMultiEvent1 = true;
    //         v.focusMultiEvent2 = false;
    //         v.focusMultiEvent3 = false;
    //         const inputElement =
    //           this.$refs.fromAutoComplete[index].$el.querySelector("input");
    //         if (inputElement) {
    //           inputElement.focus();
    //         }
    //       } else {
    //         v.focusMultiEvent1 = false;
    //         v.focusMultiEvent2 = false;
    //         v.focusMultiEvent3 = false;
    //       }
    //     });
    //   } else if (this.focusedElement === "to") {
    //     this.multiData.itinery.map((v, i) => {
    //       if (i == index) {
    //         v.formres = false;
    //         v.formres1 = true;

    //         v.focusMultiEvent1 = false;
    //         v.focusMultiEvent2 = true;
    //         v.focusMultiEvent3 = false;

    //         const inputElement =
    //           this.$refs.secondAutoComplete[index].$el.querySelector("input");
    //         if (inputElement) {
    //           inputElement.focus();
    //         }
    //       } else {
    //         v.focusMultiEvent1 = false;
    //         v.focusMultiEvent2 = false;
    //         v.focusMultiEvent3 = false;
    //       }
    //     });
    //   } else if (this.focusedElement == "calendar1") {
    //     this.multiData.itinery.map((v, i) => {
    //       if (i == index) {
    //         v.formres = false;
    //         v.formres1 = true;

    //         v.focusMultiEvent1 = false;
    //         v.focusMultiEvent2 = false;
    //         v.focusMultiEvent3 = true;

    //         const inputElement = this.$refs.dpRef4[index];
    //         if (inputElement) {
    //           inputElement.openMenu();
    //         }

    //       } else {
    //         v.focusMultiEvent1 = false;
    //         v.focusMultiEvent2 = false;
    //         v.focusMultiEvent3 = false;
    //       }
    //     });
    //   }
    // },

    // focusCalendar(element) {
    //   if (!this.focusFalse) {
    //     this.focusedElement = null;
    //     this.focusEvent3 = false;
    //     this.focusEvent2 = false;
    //     this.focusEvent1 = false;
    //     this.$refs.dpRef1.closeMenu();
    //   } else {
    //     this.focusedElement = element;
    //   }

    //   if (this.focusedElement == "calendar1") {
    //     this.$nextTick(() => {
    //       this.$refs.dpRef1.openMenu();
    //       this.focusEvent3 = true;
    //       this.focusEvent2 = false;
    //       this.focusEvent1 = false;
    //       this.formres = false;
    //       this.formres1 = false;
    //     });
    //   } else {
    //     this.focusEvent3 = false;
    //     this.focusEvent2 = false;
    //     this.focusEvent1 = false;
    //   }
    // },

    handleOutsideClick(event) {
      if (!event.target.closest(".input-styles")) {
        // this.formres = false;
        // this.formres1 = false;
        this.focusedElement = null;
        this.focusEvent1 = false;
        this.focusEvent2 = false;
        this.focusEvent3 = false;

        this.multiData.itinery.map((v) => {
          // console.log(v, i, "focusMultiEvent3focusMultiEvent3");
          v.focusMultiEvent1 = false;
          v.focusMultiEvent2 = false;
          v.focusMultiEvent3 = false;
        });
      }

      if (!event.target.closest(".subscribe-inputFields")) {
        this.focusedElement = null;
        this.roundedShadow = false;
      }
    },
    // subRadio() {

    //   this.phoneNumber = !this.phoneNumber
    // },
    adulted() {
      this.Adult.push({
        PassengerID: "T",
        PTC: "ADT",
      });
      //console.log(this.Adult, 'add')
    },
    adulted1() {
      this.Adult.pop();
      //console.log(this.Adult, 'remove')
    },

    Childed() {
      this.Child.push({
        PassengerID: "T",
        PTC: "CHD",
      });
    },
    Childed1() {
      this.Child.pop();
    },
    Infranted() {
      this.Infrant.push({
        PassengerID: "T",
        PTC: "INF",
      });
    },
    Infranted1() {
      this.Infrant.pop();
    },

    activeType(data) {
      this.activetrip = data;
      console.log(this.activetrip, 'asadsdasdpppppppsdfsfsfaA');
      this.oneway.tripType = this.activetrip;
      if (this.activetrip == "Oneway") {
        this.Oneway = true;
        (this.roundTrip = false), (this.multicity = false);
        this.oneway.redate = null;
      }
      if (data == "Roundtrip") {
        this.Oneway = false;
        this.roundTrip = true;
        this.multicity = false;
      }
      if (data == "Multicity") {
        this.Oneway = false;
        this.roundTrip = false;
        this.multicity = true;
      }
    },
    roundData() {
      let result = "";
      result = localStorage.getItem("rountData");

      let result1 = [];
      result1 = JSON.parse(result);
      this.oneway.class = result1.class;
      if (result1.from.name) {
        this.oneway.from = result1.from.name;
      } else {
        this.oneway.from = result1.from;
      }
      if (result1.to.name) {
        this.oneway.to = result1.to.name;
      } else {
        this.oneway.to = result1.to;
      }
      this.oneway.depdate = moment(result1.depdate).format("DD MMM-YYYY");

      this.oneway.redate = moment(result1.redate).format("DD MMM-YYYY");

      this.oneway.class = result1.class;

      this.aduls = result1.adult;

      this.aduls2 = result1.infrant;

      this.aduls1 = result1.child;

      this.select = result1.class;
    },

    addTrip() {
      if (this.multiData.itinery.length < 4) {
        this.multiData.itinery.push({
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          multiSearchBtn: true,
          focusMultiEvent1: false,
          focusMultiEvent2: false,
          focusMultiEvent3: false,
          autocomfrom: false,
          autocomTo: false,
          fromloader: false,
          toloader: false,
        });

        if (this.multiData.itinery.length == 4) {
          this.addmore = false;
        }
      }

      // if(this.multiData.itinery.length-1){
      //   this.multiSearchBtn = true
      // }

      if (this.multiData.itinery.length > 2) {
        this.clearall = true;
      }

      for (let i = 1; i < this.multiData.itinery.length; i++) {
        this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      }

      // for (let i = 0; i <= this.multiData.length - 1; i++) {
      //     //console.log(this.multiData.length,'this.multiData.length')
      //     if(this.multiData.length==2){
      //       this.multiData[1].from = this.multiData[0].to
      //     }
      //     else if(this.multiData.length==3){
      //       this.multiData[2].from = this.multiData[1].to
      //     }
      //     else if(this.multiData.length==4){
      //       this.multiData[3].from = this.multiData[2].to
      //     }
      //   }
      //console.log(this.multiData, 'ooooo')
    },

    clearall1() {
      this.multiData.itinery.splice(2, 5);
      (this.multiData.itinery = [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter: false,
          maximumletter1: false,
          clicked3: false,
          autocomfrom: false,
          autocomTo: false,
          fromloader: false,
          toloader: false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter1: false,
          clicked3: false,
          autocomfrom: false,
          autocomTo: false,
          fromloader: false,
          toloader: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },
    delet(index) {
      this.multiData.itinery.splice(index, 1);
    },

    deg1() {
      this.adulted1();
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3();
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1;

        this.added();
      }
      // //console.log( this.Adult,'concatenatedArray')
    },
    add1() {
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls += 1;
        //console.log(this.aduls, '1111')

        this.adulted();
        this.added();
      }

      // //console.log( this.Adult,'concatenatedArray')
    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1;

        this.Childed1();
        this.added();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1;
        //console.log(this.aduls1, '2222')

        this.Childed();
        this.added();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1;

        this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1;
        //console.log(this.aduls2, '3333')

        this.Infranted();
        this.added();
      }
    },

    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );
      //console.log(this.concatenatedArray, 'concatenatedArray')
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls");
    },

    selectFunction() {
      this.classSelect = !this.classSelect;
      //this.$refs.mySelect.focus();

      // if (!this.classSelect) {
      // this.$refs.countrySelect.focus();
      //}
    },

    closeDropdown() {
      this.$refs.countrySelect.blur();
      // this.$refs.mySelect.blur();
    },

    fromDateChanged(newValue) {
      this.oneway.depdate = newValue;
      // this.$refs.dpRef2.openMenu();
      this.focusedElement = "calendar2";

      if (this.oneway.depdate) {
        this.clicked1 = true;
        this.fromDateError = false;
      } else {
        this.clicked1 = false;
        this.fromDateError = true;
        this.$refs.dpRef1.openMenu();

        // setTimeout(() => {
        //   this.$refs.dpRef2.closeMenu();
        // }, 10)
      }
    },

    clearDate() {
      this.oneway.depdate = null;
      this.oneway.travelDate = null;
      this.focusEvent3 = false;
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();
      // console.log(v.clicked3,"checking....")

      if (this.oneway.depdate) {
        this.clicked1 = true;
        // this.fromlabel=true
      } else {
        this.clicked1 = false;
        // this.fromlabel=false;
      }
    },

    toDateChanged(newValue) {
      this.oneway.redate = newValue;
      // this.$refs.mySelect.focus();

      if (this.oneway.redate) {
        this.clicked2 = true;
        this.toDateError = false;
        this.focusedElement = null;

        // this.tolabel=true
      } else {
        this.clicked2 = false;
        this.toDateError = true;
        // this.tolabel=false;/
        this.$refs.dpRef2.openMenu();
      }
    },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();

      // this.$refs.mySelect.focus();

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.depdate = newValue;
      //console.log(this.oneway.depdate, "kkkkkkkkk");

      // this.$refs.select1.focus();

      if (this.oneway.depdate) {
        this.clicked1 = true;
        this.fromDateError = false;
      } else {
        this.clicked1 = false;
        this.fromDateError = true;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();

      if (this.oneway.depdate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },
    // Oneway datePicker End

    // Multicity datepicker
    logInput() {
      console.log("Typed value:", this.oneway.from);
    },
    multiDateChanged(newValue, index) {
      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          if (v.departDate) {
            v.focusMultiEvent3 = false;
          } else {
            v.focusMultiEvent3 = true;
          }
        }
      });

      this.multiData.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.depdata)) {
            v.depdata = null;
          }
        }
      });
    },

    getdate1($data, index) {
      if (index > 0) {
        return $data[index - 1].departDate;
      } else {
        return `${new Date()}`;
      }
    },

    multiClickOutside() {
      this.input.clicked3 = false;
      this.datePickerIsOpen = false;
    },


    login() {
      let user1 = this.customer.findIndex(
        (v) => v.email === this.loginuser.email
      );
      //console.log(user1, '23442234')
      let user2 = this.customer.findIndex(
        (v) => v.password === this.loginuser.password
      );
      //console.log(user2, '23442234')
      if (user1 === 0 && user2 === 0) {
        this.avath = true;
        this.buton = false;
      } else {
        this.avath = false;
        this.buton = true;
        this.error = true;
      }
    },

    onChangeMultiFrom(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
            v.maximumletter = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
            v.maximumletter1 = true;
          } else {
            v.multiToError = true;
            v.maximumletter1 = false;
          }
        }
      });

      for (let i = 1; i < this.multiData.itinery.length; i++) {
        this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      }
    },

    // console.log($data, index,'$data, index$data, index')
    // let data1=$data
    // for(let i=0;i<$data.length;i++){
    //     console.log(data1[i],'v[i].departDate')
    //     for(let j=0;j<data1.length;j++){
    //      if($data[i].departDate > data1[j].departDate || $data[i].departDate > data1[j].departDate){
    //        return $data[i-1].departDate=null
    //        console.log(data1[j].departDate,'data1[j].departDatedata1[j].departDate')
    //      }
    //     }
    // }

    // $data.forEach((v,i)=>{
    //     console.log(v[i],'v[i].departDate')
    //     if(v[i].departDate > v[i+1].departDate){

    //        return $data.departDate.splice(v[i],6)
    //     }
    //     else{
    //         return v[i].departDate
    //     }
    // })

    //      if (index == 0 && (($data[index].departDate > $data[index + 1].departDate) || (!$data[index + 1].departDate))) {
    //         console.log("Partially WOrkinggg...1.")
    //         $data[index + 1].departDate = null
    //     }

    //     else if ($data[index].departDate > $data[index + 1].departDate) {
    //             $data[index + 1].departDate = null
    //             console.log("Partially WOrkinggg...2.")
    //         $data[index].departDate.splice()
    //     }

    //     else{
    //     console.log('Not workinfgkkkk')
    // }

    // getdate1($data, index) {
    //     if (index > 0) {
    //         return $data[index - 1].departDate
    //     }
    //     else {
    //         return `${new Date}`
    //     }
    // },

    // multiClickOutside(index) {
    //     console.log(index,"checking....")

    //     this.multiData.clicked3 = false
    //     this.datePickerIsOpen = false;

    //     this.multiData.map((v, i) => {
    //         if (index == i) {
    //             console.log(i,v,"checking...1.")
    //             this.$refs.dpRef4.closeMenu();
    //         }
    //     })
    // },

    multiFromClick(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;
            if (!v.from) {
              v.multiFromError = true;
            } else {
              v.multiFromError = false;
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;
            if (!v.to) {
              v.multiToError = true;
            } else {
              v.multiToError = false;
            }
          }
        }
      });
    },

    multifocus1(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          console.log(v.from, "Working.....Working");

          if (!v.to) {
            setTimeout(() => {
              const inputElement = this.$refs.multiSecond[index].$el
                .querySelector("input")
                .focus();
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = true;
              if (inputElement) {
                inputElement.focus();
              }
            }, 10);
          } else {
            setTimeout(() => {
              const inputElement =
                this.$refs.multiFirst[index].$el.querySelector("input");

              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }
        }
      });
    },

    multifocus2(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (!v.departDate) {
            setTimeout(() => {
              const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = true;
            }, 10);
          } else {
            setTimeout(() => {
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = false;

              const inputElement =
                this.$refs.secondAutoComplete[index].$el.querySelector("input");
              console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }

          // for (let i = 0; i < 1; i++) {
          //   console.log(this.multiData[0].to, 'bfbfbfbf.....')
          //   this.multiData.itinery[1].from = this.multiData.itinery[0].to;
          // }
        }
      });

      // setTimeout(() => {
      //   if (this.$refs.dpRef4.length > 0) {
      //     const inputElement = this.$refs.dpRef4[index];
      //     if (inputElement) {
      //       inputElement.openMenu();
      //     }
      //   }

      // for (let i = 0; i < 1; i++) {
      //   //console.log(this.multiData[0].to, 'bfbfbfbf.....')
      //   this.multiData.itinery[1].from = this.multiData.itinery[0].to;
      // }
    },

    // Subcribe Section
    subscribeData() {
      let subscribeData = {
        request_type: "subscriber_form",
        email: this.subEmail,
        whatsapp: true,
        phone_no: this.subPhonenumber,
        token: "djfkdjkf",
      };
      console.log(subscribeData, "subscribeDatasubscribeData");
      if (this.validData) {
        this.loader = true;
        axios
          .post(this.subscribeApi, subscribeData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "response");
            this.loader = false;
            this.dialog2 = true;
          })
          .catch((error) => {
            console.log(error, "error");
            setTimeout(() => {
              this.dialog1 = true;
            }, 500);
          });
      }
    },
    clearEmailError() {
      if (this.Errormsg && this.subscribe.email) {
        this.Errormsg = "";
      }
    },
    clearAgreeError() {
      if (this.agreeMsg && this.subscribe.checkAgree) {
        this.agreeMsg = "";
      }
    },
    // Subcribe Section End

    // fromOutsideAuto($event){
    //   console.log($event.from, 'p.....')
    //   if ($event.from) {
    //     this.fromlabel=true
    //   }
    //   else{
    //   this.fromlabel=false
    //   }
    // },

    // toOutsideAuto($event){
    //   console.log($event.to, 'p.....')
    //   if ($event.to) {
    //     this.tolabel=true
    //   }
    //   else{
    //   this.tolabel=false
    //   }
    // },

    // AutoComplete Functions

    // fromClick($event) {
    //   this.formres = true;
    //   this.fromlabel = true;

    //   if ($event.from) {
    //     $event.from = null;
    //     this.fromErrorMessage = true;
    //     this.changeTextFrom = false;
    //   }
    // },

    toClick($event) {
      this.tolabel = true;
      //console.log($event, 'p.....')
      if ($event.to) {
        $event.to = null;
        this.toErrorMessage = true;
        this.changeTextTo = false;
      }
    },
    handleFocus(buttonNumber) {
      if (buttonNumber === 1) {
        this.isFocused1 = true;
      } else if (buttonNumber === 2) {
        this.isFocused2 = true;
      }
    },
    handleBlur(buttonNumber) {
      if (buttonNumber === 1) {
        this.isFocused1 = false;
      } else if (buttonNumber === 2) {
        this.isFocused2 = false;
      }
    },
    onChangeFrom() {
      setTimeout(() => {
        if (this.oneway.from) {
          // this.$refs.secondAutoComplete.$el.querySelector("input").focus();
          this.fromErrorMessage = false;
          this.changeTextFrom = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100);
    },
    // if (this.oneway.from) {
    //   //console.log(this.oneway.from, 'okokok....1...')
    //   this.fromErrorMessage = false;
    // }
    // else {
    //   //console.log(this.oneway.from, 'okokok....2...')
    //   this.fromErrorMessage = true;
    // }

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
          this.changeTextTo = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },

    validateOnewayForm() {
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.depdate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.depdate;
      // this.toDateError = !this.oneway.redate;
    },

    removemulti(index) {
      console.log(index, this.multiData, "pp");
      this.multiData.itinery = this.multiData.itinery.filter(
        (v, i) => i !== index
      );
    },

    // fetchData() {

    //   console.log(userData, 'respomsedrespomsedr.......')
    //   Object.keys(userData).forEach((icao) => {
    //     const airport = userData[icao];

    //     if (airport.iata !== '') {
    //       this.fromAirports.push({
    //         iata: airport.iata,
    //         icao: airport.icao,
    //         places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //       });
    //     }
    //   });
    // },

    async typeCity(event, $data) {
      if (event.length >= 1) {
        this.formres = false;
        if (event.length >= 1) {
          this.formres1 = false;
        }
      }

      let $event = [];
      console.log(event, $data, "searchsearchsearchsearch");
      if ($data == 1 && event.length > 0) {
        this.fromloader = true;
      } else {
        this.fromloader = false;
      }

      if ($data == 2 && event.length > 0) {
        this.toloader = true;
      } else {
        this.toloader = false;
      }

      if (event.length >= 3) {
        await axios
          .get(
           `${this.autocompleteApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, "response");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.toUpperCase();
        // console.log(searchString,'searchStringsearchString')
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
            }
          });

          this.airitems = iataMatch.concat(otherMatches);

          $event = this.airitems.map((item) => {
            const parts = item.places.split(", ");
            if (parts.length >= 2) {
              const name = `${parts[1]} (${parts[0]})`;
              const originalName = item.places;
              return {
                name,
                originalName,
              };
            }
          });

          if ($data == 1) {
            this.fromloader = false;
            $event.forEach((v) => {
              if (
                !this.city.some((item) => item.originalName == v.originalName)
              ) {
                this.city.push(v);
              }
            });
            this.typedrop = true;
          } else if ($data == 2) {
            this.toloader = false;
            $event.forEach((v) => {
              if (
                !this.city1.some((item) => item.originalName == v.originalName)
              ) {
                this.city1.push(v);
              }
            });
            this.typedrop1 = true;
          }

          console.log(this.city, this.city1, "vasanth");
          this.loading = false;
        }, 1000);
      } else {
        // if (this.oneway.from) {
        //   this.maximumletter = true;
        // }
        // if (this.oneway.to) {
        //   this.maximumletter1 = true;
        // }
      }
    },
    datachange(data) {
      this.oneway.from = data.name;
      this.inputCity = data.name;
      this.typedrop = false;
    },
    datachangeTo(data) {
      this.oneway.to = data.name;
      this.inputCityto = data.name;
      this.typedrop1 = false;
    },
    datachangeMuti(data, index) {
      console.log(data, index, "data,index1");
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          v.from = data.name;
          v.autocomfrom = false;
        }
      });
      console.log(this.multiData, "this.multiDatathis.multiData");
    },
    datachangeMutiTo(data, index) {
      console.log(data, index, "data,index1");
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          v.to = data.name;
          v.autocomTo = false;
        }
      });

      for (let i = 1; i < this.multiData.itinery.length; i++) {
        this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      }
      console.log(this.multiData, "this.multiDatathis.multiData");
    },

    async typeMultiCity(event, index, $num) {
      console.log(event, index, $num, "searchsearchsearchsearch");
      let $event = [];
      console.log(event, index, "searchsearchsearchsearch");

      if (event.length >= 3) {
        if ($num == 1 && event.length > 2) {
          this.multiData.itinery.map((v, i) => {
            v.autocomTo = false;
            v.fromloader = true;
            if (i == index && event.length > 2) {
              v.autocomfrom = true;
            } else {
              v.autocomfrom = false;
              v.fromloader = false;
              this.multidrop = [];
            }
          });
        } else if ($num == 2 && event.length > 0) {
          this.multiData.itinery.map((v, i) => {
            v.autocomfrom = false;
            if (i == index && event.length > 2) {
              v.autocomTo = true;
              v.toloader = true;
            } else {
              v.autocomTo = false;
              v.toloader = false;
              this.multidrop1 = [];
            }
          });

          for (let i = 1; i < this.multiData.itinery.length; i++) {
            this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
          }
        }

        await axios
          .get(
           `${this.autocompleteApi}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, "response");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.toUpperCase();
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
            }
          });

          this.airitems = iataMatch.concat(otherMatches);

          $event = this.airitems.map((item) => {
            const parts = item.places.split(", ");
            if (parts.length >= 2) {
              const name = `${parts[1]} (${parts[0]})`;
              const originalName = item.places;
              return {
                name,
                originalName,
              };
            }
          });
          if ($num == 1) {
            this.multiData.itinery.map((v) => {
              v.fromloader = false;
            });

            //this.multidrop = $event;

            $event.forEach((v) => {
              if (
                !this.multidrop.some(
                  (item) => item.originalName == v.originalName
                )
              ) {
                this.multidrop.push(v);
              }
            });
          } else if ($num == 2) {
            this.multiData.itinery.map((v) => {
              v.toloader = false;
            });

            $event.forEach((v) => {
              if (
                !this.multidrop1.some(
                  (item) => item.originalName == v.originalName
                )
              ) {
                this.multidrop1.push(v);
              }
            });
          }

          console.log(this.multidrop, "vasanth");
          this.loading = false;
        }, 1000);
      }
    },

    onewaySwap() {
      this.swapData = !this.swapData;
      let $dataFrom = this.inputCity;
      let $dataTo = this.inputCityto;
      this.inputCity = $dataTo;
      this.inputCityto = $dataFrom;
    },

    focus1() {
      if (!this.oneway.to) {
        console.log(this.oneway.to, "working...working...working....1");
        setTimeout(() => {
          this.$refs.onewaysecondAuto.$el.querySelector("input").focus();
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
          this.focusEvent2 = true;
        }, 100);
      } else {
        console.log(this.oneway.to, "working...working...working....2");

        this.focusEvent2 = false;
        console.log("testueeiekek");
        setTimeout(() => {
          this.$refs.fromAutoComplete.$el.querySelector("input").blur();
        }, 100);
      }
    },

    focus2() {
      if (!this.oneway.depdate || !this.oneway.travelDate) {
        setTimeout(() => {
          this.focusEvent3 = true;
          if (this.$refs.dpRef1 || this.$refs.dpRef2) {
            console.log(
              this.$refs.dpRef1,
              "this.$refs.dpRef1this.$refs.dpRef1"
            );
            this.$refs.dpRef1.openMenu();
            this.$refs.dpRef2.openMenu();
          }
        }, 100);
      } else {
        this.focusEvent3 = false;

        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").blur();
        }, 100);
      }
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          //console.log(this.input[0].to, 'bfbfbfbf.....')
          this.input[1].from = this.input[0].to;
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth <= 480) {
        // console.log(this.multiCalender,'multmiikmhuihuu...1111')
        this.multiCalender = false;
      } else {
        // console.log(this.multiCalender,'multmiikmhuihuu...2222')

        this.multiCalender = true;
      }
    },

    gettraveldeals(){
      console.log(this.traveldealsApi, 'getTopdestinationdatagetTopdestinationdata')
      axios.get(this.traveldealsApi.url, {
       headers: {
           "Content-Type": this.traveldealsApi.type,
       },
      })
     .then((response) => {
       if(!response.data.error){
          this.traveldealsData = response.data;
          console.log(this.traveldealsData, 'traveldealsDatatraveldealsData..0')
       }
      else{
      //  this.traveldealsData = topdestinationsdata;
       console.log(this.traveldealsData, 'traveldealsDatatraveldealsData..1')
      }
     })

     .catch((error) => {
      //  this.getTopdestinationdata = topdestinationsdata;
         console.log(error, 'erroroor.....')
     })
    },

    getflightdeals(){
      console.log(this.flightdealsApi, 'getTopdestinationdatagetTopdestinationdata')
      axios.get(this.flightdealsApi.url, {
       headers: {
           "Content-Type": this.flightdealsApi.type,
       },
      })
     .then((response) => {
       if(!response.data.error){
          this.flightdealsData = response.data;
          console.log(this.flightdealsData, 'flightdealsDataflightdealsData..0')
       }
      // else{
      // //  this.flightdealsData = topdestinationsdata;
      //  console.log(this.flightdealsData, 'flightdealsDataflightdealsData..1')
      // }
     })

     .catch((error) => {
      //  this.getTopdestinationdata = topdestinationsdata;
         console.log(error, 'erroroor.....')
     })
    },

    gettopdestinationcontent(){
      console.log(this.getTopdestinationdata, 'getTopdestinationdatagetTopdestinationdata')
      axios.get(this.topDestinationApi.url, {
       headers: {
           "Content-Type": this.topDestinationApi.type,
       },
      })
     .then((response) => {
       if(!response.data.error){
          this.getTopdestinationdata = response.data;
          console.log(this.getTopdestinationdata, 'getTopdestinationdatagetTopdestinationdata..0')
       }
      else{
      //  this.getTopdestinationdata = topdestinationsdata;
       console.log(this.getTopdestinationdata, 'getTopdestinationdatagetTopdestinationdata..1')
      }
     })

     .catch((error) => {
      //  this.getTopdestinationdata = topdestinationsdata;
         console.log(error, 'erroroor.....')
     })
    },

    gethowitworks(){
      console.log(this.howitworksApi, 'getTopdestinationdatagetTopdestinationdata')
      axios.get(this.howitworksApi.url, {
       headers: {
           "Content-Type": this.howitworksApi.type,
       },
      })
     .then((response) => {
       if(!response.data.error){
          this.gethowitworksData = response.data;
          console.log(this.gethowitworksData, 'gethowitworksDatagethowitworksData..0')
       }
      else{
      //  this.gethowitworksData = topdestinationsdata;
       console.log(this.gethowitworksData, 'gethowitworksDatagethowitworksData..1')
      }
     })

     .catch((error) => {
      //  this.getTopdestinationdata = topdestinationsdata;
         console.log(error, 'erroroor.....')
     })
    },
    
    getrecommendations(){
      console.log(this.recommendationsApi, 'getTopdestinationdatagetTopdestinationdata')
      axios.get(this.recommendationsApi.url, {
       headers: {
           "Content-Type": this.recommendationsApi.type,
       },
      })
     .then((response) => {
       if(!response.data.error){
          this.getrecommendationsData = response.data;
          console.log(this.getrecommendationsData, 'getrecommendationsDatagetrecommendationsData..0')
       }
      else{
      //  this.getrecommendationsData = topdestinationsdata;
       console.log(this.getrecommendationsData, 'getrecommendationsDatagetrecommendationsData..1')
      }
     })

     .catch((error) => {
      //  this.getTopdestinationdata = topdestinationsdata;
         console.log(error, 'erroroor.....')
     })
    },

    getConfig() {
      const getConfigData = getConfigDataFromLocalStorage();

      let locale = null;
      locale = this.getLangname || null;
      console.log(locale,'localelocalelocale')

      if (getConfigData) {
        let pluginWhats = "";
        pluginWhats = getConfigData.payload.portal_configuration.menus.enabled.plugins;
        this.currencyIsocode = getConfigData.payload.portal_configuration.currency.iso_code;
        this.currencydisplay = getConfigData.payload.portal_configuration.currency.display;
        this.airlineApi = getConfigData.payload.portal_configuration.API_endpoints.airline_autocomplete;
        this.airportdataApi = getConfigData.payload.portal_configuration.API_endpoints.airport_data;
        this.susbcribeApi = getConfigData.payload.portal_configuration.API_endpoints.subscriber_form;
        this.autocompleteApi = getConfigData.payload.portal_configuration.API_endpoints.autocomplete;

        let siteContentApi = getConfigData.payload.portal_configuration.site_content_data[locale];
        if(siteContentApi){
          this.traveldealsApi = siteContentApi.traveldeals;
          this.flightdealsApi = siteContentApi.flightdeals;
          this.topDestinationApi = siteContentApi.topdestination;
          this.howitworksApi = siteContentApi.howitworks;
          this.recommendationsApi = siteContentApi.recommendations;

          if(this.traveldealsApi) this.gettraveldeals();
          if(this.flightdealsApi) this.getflightdeals();
          if(this.topDestinationApi) this.gettopdestinationcontent();
          if(this.howitworksApi) this.gethowitworks();
          if(this.recommendationsApi) this.getrecommendations();
        }

        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')
        if (pluginWhats.includes("whatsapp")) {
          this.pluginWhatspp = true;
          this.whatsappApi =
            getConfigData.payload.portal_configuration.API_endpoints.whatsapp;
        } else {
          this.pluginWhatspp = false;
        }
      }
    },

    autoDate() {
      this.oneway.travelDate = [];
      let value1 = new Date();
      let value2 = new Date(new Date().setDate(value1.getDate() + 7));

      this.oneway.travelDate.push(value1, value2);

      if (this.oneway.travelDate == 2) {
        this.oneway.depdate = this.oneway.travelDate[0];
        this.oneway.redate = this.oneway.travelDate[1];
      }

      // console.log(this.oneway.travelDate, 'creaaadddddddd....')
    },

    recentFromData1() {
      let result1 = [];
      let result2 = [];
      // let result3 = []
      // localStorage.setItem("recentFrom")
      result1 = JSON.parse(localStorage.getItem("recentFromData"));
      // console.log(result1, 'result1result1')
      if (result1 == null) {
        // console.log("suhajhbhu");
      } else if (result1.length > 0) {
        //  alert('pppp')
        result2 = result1.splice(0, 4);
        // result3 = result1.splice(0, 15)
        // console.log(result1, "ppp");
        let result = [];
        let resultTo = [];

        result2.forEach((a) => {
          if (!result.some((b) => b.from === a.from)) {
            result.push(a);
          }
        });
        this.resultFrom = result;
        // console.log(result2,'result3result3')
        result2.forEach((a) => {
          if (!resultTo.some((b) => b.to === a.to)) {
            resultTo.push(a);
          }
        });
        // console.log(resultTo, "resultToresultTo");

        this.resultTo = resultTo;
      } else {
        console.log(result1);
      }
    },

    updateContent() {
      this.getLangname = this.$i18n.locale;
      console.log(this.getLangname,'getcontententUrl...1');
      this.getConfig();
    },
  },

  // AutoComplete Functions End
  watch: {
    "$i18n.locale": {
      handler: function () {
        // this.service = this.getService();
        // this.flightDeals = this.getflightDeals();
        this.updateContent();
        // this.classTypeitems = this.getClassType();

        // console.log(this.$i18n.locale, "getegeteegtegete...9");
      },
      deep: true,
    },
    directFlight(newValue) {
      if(newValue) {
        this.stopsSelect = '0';
      }
      else if(!newValue && this.stopsSelect == '0'){
        this.stopsSelect = 'anyStop';
      }
      else if(!newValue && (this.stopsSelect !== 'anyStop' || this.stopsSelect !== '0')){
        return this.stopsSelect;
      }
      else{
        this.stopsSelect = 'anyStop';
      }
    },

    stopsSelect(newValue) {
      this.directFlight = newValue == '0' ? true : false;
          
      if(!this.directFlight && (newValue !== 'anyStop' || newValue !== '0')){
        this.stopsSelect = newValue;
      }
      if(newValue !== 'anyStop' || newValue !== '0') this.handleOptionChange('stopsSelect', newValue);
    },
    adlayoverTime(newValue) {
      this.handleOptionChange('adlayoverTime', newValue);
    },
    avoidCountry(newValue) {
      this.handleOptionChange('avoidCountry', newValue);
    },

    airlineSelect(newValue) {
      this.handleOptionChange('airlineSelect', newValue);
    },
    adviaAirport(newValue) {
      this.handleOptionChange('adviaAirport', newValue);
    },
    selectedAirline(newValue) {
      this.handleOptionChange('selectedAirline', newValue);
    },
    selectedAirport(newValue) {
      this.handleOptionChange('selectedAirport', newValue);
    },
    datepickerDate(valueDate) {
      console.log(valueDate, "valueDatevalueDatevalueDate");
    },

    "oneway.from "(val) {
      console.log(val, "ooooooo");
      // val && val !== this.selectAcc && this.querySelections(val)
    },
    "oneway.class"(newSelectValue) {
      // console.log('select property changed to:', newSelectValue);

      if (newSelectValue) {
        // setTimeout(() => {
        //this.$refs.countrySelect.focus();
        this.classSelect = false;
        // console.log(this.classSelect)
        //  }, 100);
      }
    },

    "oneway.travelDate"(value) {
      console.log(value);
      if (value && value.length <= 2) {
        this.oneway.depdate = value[0];
        this.oneway.redate = value[1];

        this.focusEvent3 = false;
      } else {
        this.oneway.travelDate = null;
      }
    },

    "oneway.depdate"(value) {
      if (value) {
        this.focusEvent3 = false;
      } else {
        this.oneway.depdate = null;
      }
    },
  },

  // AutoComplete Functions
  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },


    // progressColor() {
    //      return this.isNotActive ? '#40f467' : '#ff0000';
    //     },
  },
  // AutoComplete Functions End

  created() {
    localStorage.removeItem("delete_Id");
    this.getConfig();
    // this.autoDate();
    // this.autocompletJson();
    window.addEventListener("resize", this.handleResize);
    console.log(this.$i18n.locale, "getegeteegtegete...8");
  },

  mounted() {
    this.getConfig();
    this.updateContent();
    // this.fetchData();
    // document.getElementById("navbar").style.display = "none";
    // document.getElementById("food").style.display = "none";
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    document.body.addEventListener("click", this.handleOutsideClick);
    this.recentFromData1();
    this.generateRandomAlphabets();

    this.intervalId = setInterval(this.changeWord, 2000);

    // if (!navigator.cookieEnabled) {
    //     this.showCookieInfo = true;
    // console.log(navigator,'navigatornavigator....1')
    //   }
    //   else{
    //     this.showCookieInfo = false;
    // console.log(navigator,'navigatornavigator....2')
    //   }

    //
    // loginService.getAllUser().then(res => {
    //   this.customer = res.data
    //   //console.log(this.customer, '44444')
    // })
    //console.log(this.oneway, 'llllllllllllllll')
  },
};
</script>

<style scoped>

/* @import "@/assets/main.css"; */




/* Toast Styles */
  /* body{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    overflow: hidden;
  } */
  
  .toast{
    width:370px;
    position: fixed;
    /* top: 80px; */
    top: 400px;
    right: 10px;
    border-radius: 6px;
    background: #fff;
    padding: 20px 35px 20px 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-left: 8px solid #ff0000;
    overflow: hidden;
    transform: translateX(calc(100% + 30px));
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
    z-index: 9;
  }
  .toast.active{
    transform: translateX(0);
  }
  .toast-content{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .toast-check{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    background-color: #ff0000;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
  }
  .message{
    display: flex;
    flex-direction: column;
    margin: 0 20px;
  }
  .message-text{
    font-size: 20px;
    font-weight: 600;
  }
  .text-1{
    color: #333;
  }
  .text-2{
    color: #666;
    font-weight: 400;
    font-size: 13px;
  }
  .toast-close{
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 5px;
    cursor: pointer;
    opacity: 0.7;
  }
  .toast-close:hover{
    opacity: 1;
  }
  .progress{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: #ddd;
  }
  
  .progress::before{
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: var(--progress-color, #ff0000);
    background-color: var(--progress-color, #ff0000);
  }
  .progress.active::before{
    animation: progress 2s linear forwards;
  }
  
  .toast:not(.show) {
      display: block;
  }
  
  
  @keyframes progress {
    100%{
      right: 100%;
    }
  }
  .toast-btn{
    padding: 10px 40px;
    font-size: 20px;
    outline: none;
    border: none;
    background-color: #ff0000;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.3s;
  }
  .toast-btn:hover{
    background-color: #ff0000;
  }
  /* toast style end */









.inter-input {
  height: 40px;
  width: auto;
  font-weight: 600;
}

.dropdown-toggle .text-truncate {
  font-weight: 600;
}

.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url(owl.video.play.png) no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
  margin-top: 10px;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  /* margin: 5px;
  padding: 4px 7px; */
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

* {
  outline: none;
}

html,
body,
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: #008cff;
}

a:hover,
a:active,
a:focus {
  transition: all ease-in-out 0.3s;
}

.form-control:focus {
  color: #002d5b;
  background-color: #ffffff;
  border-color: #ffc104;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 4, 0.5);
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 4, 0.5);
}

.btn:hover {
  border-color: inherit;
  background-color: #002d5b;
  overflow: hidden;
}

.ui-state-default {
  text-align: center !important;
}

.ui-state-hover {
  background-color: #ffc104 !important;
  border: inherit !important;
}

.accordion-button {
  background-color: transparent;
  border: 0px solid transparent;
  font-size: 0.875rem;
}

.accordion-button:not(.collapsed) {
  color: #002d5b;
  background-color: transparent;
  box-shadow: none;
}

html {
  font-size: 1rem;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #efeeee;
}

a,
a:hover {
  text-decoration: none;
}

h1 {
  text-align: center;
  text-transform: lowercase;
}

.theme-bg-primary {
  background-color: #ffc104;
}

.theme-bg-secondary {
  background-color: #002d5b;
}

.theme-bg-white {
  background-color: #ffffff;
}

.theme-bg-light {
  background-color: #9f9f9f;
}

.theme-bg-accent-one {
  background-color: #343534;
}

.theme-bg-accent-two {
  background-color: #9f9f9f;
}

.theme-bg-accent-three {
  background-color: #f5faff;
}

.body-color {
  /* background-color: #efeeee; */
}

.theme-text-white {
  color: #ffffff;
}

.theme-text-primary {
  color: #ffc104;
}

.theme-text-secondary {
  color: #002d5b;
}

.theme-text-accent-one {
  color: #343534;
}

.theme-text-accent-two {
  color: #9f9f9f;
}

.theme-text-accent-three {
  color: #f5faff;
}

.theme-box-shadow {
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
}

.theme-text-shadow {
  text-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
}

.theme-border-radius {
  /* border-radius: 0.5rem; */
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.theme-border-radius-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.theme-border-radius-bottom {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.font-extra-small {
  font-size: 0.625rem;
}

.font-small {
  font-size: 0.75rem;
}

.font-medium {
  font-size: 0.875rem;
}

.max-1 {
  max-width: 50rem;
  margin: auto;
}

.btn-select {
  position: relative;
  border: 1px solid #002d5b;
  color: #002d5b;
  z-index: 1;
}

.btn-book {
  position: relative;
  background-color: #002d5b;
  color: #ffffff;
  z-index: 0;
  overflow: hidden;
}

.min-h58 {
  min-height: 58px;
}

.z-in-2 {
  z-index: 2;
}

.dropdown-container {
  position: relative;
  min-width: 120px;
}

/* .dropdown-container .dropdown-toggle::after {
  opacity: 0;
}

.dropdown-container .dropdown-toggle {
  width: 135px;
  height: 28px;
  padding: 0 !important;
  cursor: pointer;
  position: relative;
  transition: all ease-in-out 0.3s;
}  */

/* .dropdown-container .mybookingBtn {
  color: #ffffff !important;
} */

.dropdown-container .mybookingBtn:hover {
  cursor: pointer;
  position: relative;
  color: #ffc104 !important;
  transition: all ease-in-out 0.3s;
}

.dropdown-container .dropdown-toggle label {
  cursor: pointer;
  width: 100%;
}

.dropdown-container .dropdown-toggle:hover,
.dropdown-container .dropdown-toggle:active,
.dropdown-container .dropdown-toggle:focus {
  color: #ffc104 !important;
}

.dropdown-container .click-dropdown:hover,
.dropdown-container .click-dropdown:active,
.dropdown-container .click-dropdown:focus {
  color: #ffc104 !important;
  cursor: pointer !important;
}

.dropdown-container .dropdown-menu {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
  margin-top: 17px;
  position: absolute;
  left: 0;
  top: 100%;
  display: none;
  background-color: #ffffff;
  z-index: 10;
}

.dropdown-container .dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: hidden;
  border-radius: 0.5rem;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .dropdown-container .dropdown-menu ul {
    border-radius: 0rem;
  }
}

.dropdown-container .dropdown-menu a {
  text-decoration: none;
  display: block;
  padding: 5px 20px;
  font-weight: 600;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
  transition: all ease-in-out 0.3s;
}

.dropdown-container .dropdown-menu a:hover,
.dropdown-container .dropdown-menu a:active,
.dropdown-container .dropdown-menu a:focus {
  color: inherit;
}

/* .dropdown-menu,
.dropdown-toggle {
  position: relative;
}

.dropdown-menu::before,
.dropdown-toggle::before {
  content: "";
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  border: 5px solid;
  border-top-color: rgba(0, 0, 0, 0);
  border-left-color: rgba(0, 0, 0, 0);
  margin-top: -2.5px;
  background-color: rgba(0, 0, 0, 0);
  transition: all ease-in-out 0.3s;
  z-index: 9;
} */

.dropdown-menu {
  z-index: 10;
  position: relative;
}

.dropdown-menu::before {
  z-index: -1;
  transform: rotate(-135deg);
  top: -4px;
  border-color: #ffffff;
  box-shadow: 0px 0px 4px -2px rgba(0, 0, 0, 0.5);
  display: none;
}

.dropdown-open .dropdown-menu.dropdown-active {
  display: block;
}

.dropdown-container.dropdown-open .dropdown-toggle {
  color: #ffffff;
}

.dropdown-container.dropdown-open .dropdown-toggle:before {
  transform: rotate(-135deg);
}

@media screen and (max-width: 767px) {
  #navbarSupportedContent {
    background-color: #ffffff;
    position: absolute;
    right: 0;
    top: 3rem;
    min-width: 12rem;
  }
}

@media screen and (max-width: 767px) {
  #navbarSupportedContent .nav-item {
    padding: 0.5rem 0.5rem;
  }
}

@media screen and (max-width: 767px) {
  #navbarSupportedContent .dropdown-active {
    position: relative;
    border-radius: 0;
    border: 0;
  }
}

@media screen and (max-width: 767px) {
  #navbarSupportedContent .dropdown-active .nav-item {
    padding: 0rem 0rem;
  }
}

@media screen and (max-width: 767px) {
  #navbarSupportedContent .dropdown-toggle {
    color: #343534;
  }
}

.dropdown-open .dropdown-menu {
  min-width: 5rem;
  padding: 0 0;
  width: auto;
}

.dropdown-open .dropdown-menu a {
  padding: 0.5rem 0.5rem;
}

.dropdown-open .dropdown-menu a:active,
.dropdown-open .dropdown-menu a:hover {
  background-color: #ffc104;
}

#corporate-page .dropdown-container .dropdown-toggle {
  color: #002d5b;
  display: flex;
  flex-direction: column;
}

#corporate-page .dropdown-container .dropdown-toggle::before {
  top: 70%;
}

#corporate-page .dropdown-container .dropdown-toggle::after {
  opacity: 0;
  display: none;
}

#corporate-page .dropdown-container .dropdown-toggle:hover,
#corporate-page .dropdown-container .dropdown-toggle:active,
#corporate-page .dropdown-container .dropdown-toggle:focus {
  color: #ffc104;
}

#corporate-page .dropdown-container .dropdown-menu {
  margin-top: 0;
}

.flag {
  width: 20px;
  height: 20px;
  display: inline-block;
  /* background: url(../images/icons/flag.png); */
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 50px;
  overflow: hidden;
  vertical-align: middle;
  margin-right: 6px;
}

.flag.in {
  background-position: -4px 0px;
}

.flag.us {
  background-position: 0 -24px;
}

.flag.er {
  background-position: 0 -50px;
}

.flag.ru {
  background-position: 0 -100px;
}

.flag.fr {
  background-position: -4px -75px;
}

.preloader {
  position: fixed;
  z-index: 999999;
  background-color: #efeeee;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0;
  right: 0;
}

.preloader .d-table {
  width: 100%;
  height: 100%;
}

.preloader .d-table .d-table-cell {
  vertical-align: middle;
}

.preloader .load-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.preloader .load-spinner div {
  transform-origin: 40px 40px;
  animation: load-spinner 1.2s linear infinite;
}

.preloader .load-spinner div::after {
  content: " ";
  display: block;
  position: absolute;
  top: 5px;
  left: 35px;
  width: 5px;
  height: 20px;
  border-radius: 20%;
  background: #002d5b;
}

.preloader .load-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.preloader .load-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.preloader .load-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.preloader .load-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.preloader .load-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.preloader .load-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.preloader .load-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.preloader .load-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.preloader .load-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.preloader .load-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.preloader .load-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.preloader .load-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes load-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.header-light {
  background-color: #ffffff;
}

.header-light .dropdown-toggle {
  color: #002d5b;
}

.header-light .dropdown-container.dropdown-open .dropdown-toggle {
  color: #002d5b;
}

.modify-search .head-wrapper {
  min-height: auto;
}

.modify-search .flight-search {
  background-color: #002d5b;
}

.modify-search .flight-search .check-wrap {
  color: #ffffff;
}

.modify-search .flight-search .check-wrap .checkmark {
  border: 1px solid #ffc104;
}

.modify-search .flight-search .nav-tabs .nav-item .nav-link {
  color: #ffffff;
}

.filter-sec {
  background-color: #f5faff;
}

.filter-sec .flt-btn {
  position: relative;
  padding: 0 2rem 0 0;
}

.filter-sec .flt-btn::before {
  content: "\f282";
  font-family: bootstrap-icons !important;
  display: inline-block;
  background-color: transparent;
  width: 20px;
  height: 20px;
  z-index: 1;
  position: absolute;
  right: 0;
  transform: rotate(180deg);
}

.filter-sec .collapsed::before {
  transform: rotate(0deg);
}

.check-wrap {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: white !important;
  font-size: 0.75rem;
}

.check-wrap:hover input ~ .checkmark {
  background-color: #ffc104;
}

.check-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.check-wrap input:checked ~ .checkmark {
  background-color: #ffc104;
  border: 0px solid #ffc104;
}

.check-wrap input:checked ~ .checkmark:after {
  display: block;
}

.check-wrap .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  border: 1px solid #9f9f9f;
}

.check-wrap .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #002d5b;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.price-range-slider {
  width: 100%;
  float: left;
  padding: 10px 20px;
}

.price-range-slider .range-value {
  margin: 0;
}

.price-range-slider .range-value input {
  width: 100%;
  background: none;
  color: #343534;
  font-size: 16px;
  font-weight: initial;
  box-shadow: none;
  border: none;
  margin: 20px 0 20px 0;
}

.price-range-slider .range-bar {
  border: none;
  background: #343534;
  height: 3px;
  width: 96%;
  margin-left: 8px;
  padding: 0 !important;
  border: 0 !important;
}

.price-range-slider .range-bar .ui-slider-range {
  background: #ffc104;
}

.price-range-slider .range-bar .ui-slider-handle {
  border: none;
  border-radius: 25px;
  background: #ffffff;
  border: 2px solid #002d5b;
  height: 17px;
  width: 17px;
  top: -0.52em;
  cursor: pointer;
}

.price-range-slider .range-bar .ui-slider-handle + span {
  background: #002d5b;
}

.srp .stops {
  display: block;
  width: 60px;
  height: 2px;
  background-color: #9f9f9f;
  position: relative;
}

.srp .stops .one {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: flex;
  background: #002d5b;
  position: absolute;
  top: -3px;
  left: 40%;
}

.selected-strip {
  box-shadow: 0px 0 20px rgba(0, 45, 91, 0.5);
}

.reviewStatus {
  width: 100%;
  display: flex;
  height: 2px;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  color: #ffffff;
  font-size: 0.75rem;
}

.reviewStatus li {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.reviewStatus:before {
  height: 2px;
  background: #ffc104;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 8px;
}

.reviewStatus.step1:before {
  width: 0%;
}

.reviewStatus.step2:before {
  width: 25%;
  animation-name: step2;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 500ms;
}

.reviewStatus.step3:before {
  width: 50%;
  animation-name: step3;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 500ms;
}

.reviewStatus.step4:before {
  width: 75%;
  animation-name: step4;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-delay: 500ms;
}

@keyframes step2 {
  from {
    width: 0%;
  }

  to {
    width: 25%;
  }
}

@keyframes step3 {
  from {
    width: 25%;
  }

  to {
    width: 50%;
  }
}

@keyframes step4 {
  from {
    width: 50%;
  }

  to {
    width: 75%;
  }
}

.reviewStatus .numbering {
  width: 13px;
  min-height: 13px;
  font-size: 10px;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  position: relative;
  top: -5px;
  color: #343534;
}

.reviewStatus .numbering.completed {
  background: #ffc104;
  text-indent: -999999px;
  position: relative;
}

.reviewStatus .numbering.completed:before {
  content: "";
  display: block;
  width: 4px;
  height: 7px;
  border: solid #ffffff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  position: absolute;
  left: 4px;
  top: 2.5px;
}

.reviewStatus .numbering.onpage {
  background: #ffc104;
  color: #ffffff;
}

.reviewStatus .reviewText.active {
  font-weight: 700;
  color: #ffffff;
}

.reviewStatus .numbering.completed:before {
  content: "";
  display: block;
  width: 4px;
  height: 7px;
  border: solid #ffffff;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
  position: absolute;
  left: 5px;
  top: 2px;
}

.custom-tab .nav-link {
  text-align: left;
  background: #f5faff;
  color: #343534 !important;
  font-size: 0.875rem;
  border-radius: 0;
  border-bottom: 1px solid #efeeee;
  padding: 1.02rem 1rem;
}

.custom-tab .nav-link:last-child {
  border-bottom: 0px solid transparent;
}

.custom-tab .first-tab {
  border-top-left-radius: 0.5rem !important;
}

.custom-tab .last-tab {
  border-bottom-left-radius: 0.5rem !important;
}

.custom-tab .nav-link.active,
.custom-tab .show > .nav-link {
  text-align: left;
  background: #ffffff;
  color: #343534;
  font-size: 0.875rem;
  border-radius: 0;
}

.custom-tab .payment-icon {
  width: 24px;
  height: 16px;
  display: inline-block;
  /* background: url(../images/icons/icons-sprite-payment.png) no-repeat; */
  vertical-align: middle;
  margin-right: 5px;
}

.custom-tab .credit {
  background-position: 0 0;
}

.custom-tab .debit {
  background-position: -25px 0;
}

.custom-tab .net {
  background-position: -50px 0;
}

.custom-tab .upi {
  background-position: -75px 0;
}

.custom-tab .phone {
  background-position: -100px 0;
}

.custom-tab .wallet {
  background-position: -125px 0;
}

.custom-tab .paypal {
  background-position: -150px 0;
}

.custom-tab .emi {
  background-position: -175px 0;
}

.form-label {
  font-size: 0.875rem;
}

.inner-banner {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 430px;
}

.privacy-page-bg {
  /* background-image: url(../images/section/privacy-page-bg.jpg); */
}

.deals-page-bg {
  /* background-image: url(../images/section/deals-offer-banner.jpg); */
}

.faq-page-bg {
  /* background-image: url(../images/section/faq-banner.jpg); */
}

.privacy-list li {
  font-size: 0.875rem;
  color: #9f9f9f;
  line-height: 2;
  list-style: disc;
  margin-left: 1.125rem;
}

.company-qotes {
  font-size: 2.5rem;
  line-height: 1.5;
  color: #9f9f9f;
  font-weight: 300;
  position: relative;
  padding: 0 0 0 3rem;
  margin-bottom: 2rem;
}

.company-qotes::after,
.company-qotes:before {
  content: "";
  /* background: url(../images/icons/qotes-icon.png) no-repeat; */
  width: 36px;
  height: 30px;
  display: block;
}

.company-qotes::after {
  position: absolute;
  top: 0;
  left: 0;
}

.company-qotes::before {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);
}

.about-pic {
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
  border: 5px solid #ffffff;
}

.text-color {
  color: rgba(0, 45, 91, 0.2);
}

iframe {
  width: 100%;
}

.deals-tab {
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
  border-radius: 0.5rem;
  flex-wrap: nowrap;
}

.deals-tab li .nav-link {
  color: #343534;
  padding: 1rem;
}

.deals-tab li .nav-link.active {
  background-color: transparent;
  border-bottom: 2px solid #002d5b;
  border-radius: 0;
  color: #002d5b;
}

.offers-content .wrap-img {
  position: relative;
  min-height: 150px;
  height: auto;
  max-height: 200px;
  overflow: hidden;
}

.offers-content .wrap-content {
  display: flex;
  flex-direction: column;
}

.addons-tab li.nav-item {
  background-color: #ffffff;
}

.addons-tab li.nav-item div {
  cursor: pointer;
}

.addons-tab li.nav-item div:hover {
  background-color: #f5faff;
}

.addons-tab li.nav-item .active {
  background-color: #f5faff;
}

.add-btn-wrap {
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
  border-radius: 0.5rem;
  width: 50%;
}

.add-btn-wrap .input-group .input-group-btn .btn:disabled {
  border-color: transparent;
}

.active-addon {
  border-bottom: 2px solid #002d5b;
  background-color: #f5faff;
}

.profile-pic {
  background-color: #efeeee;
  width: 100%;
  height: 100%;
  min-height: 130px;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.edit-btn {
  position: absolute;
  bottom: -5px;
  border: 2px solid #ffffff;
  background-color: #002d5b;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-btn i {
  color: #ffffff;
  font-size: 0.75rem;
}

.edit-btn:hover {
  background-color: #ffffff;
}

.edit-btn:hover i {
  color: #002d5b;
}

.profile {
  width: 100%;
  height: 5px;
  background-color: #9f9f9f;
}

.complete-100 {
  width: 100%;
  background-color: green;
}

.traveller-pic {
  background-color: #f5faff;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
  position: relative;
}

.img-icon {
  width: 25px;
  height: 23px;
  display: inline-block;
  /* background: url(../images/icons/my-booking-sprite.svg) no-repeat; */
}

.icon-upcoming {
  background-size: 540%;
  background-position: 0px 0px;
}

.icon-cancel {
  background-size: 540%;
  background-position: -85px 0px;
}

.icon-complete {
  background-size: 540%;
  background-position: -53px 0px;
}

.icon-fail {
  background-size: 540%;
  background-position: -27px 0px;
}

.refund-step {
  margin-top: 1rem;
}

.refund-step li {
  font-size: 0.75rem;
  display: inline-flex;
  flex-direction: column;
  padding-right: 5rem;
  position: relative;
}

.refund-step li::after {
  content: "";
  background-color: #ffc104;
  width: 90%;
  height: 4px;
  display: block;
  position: absolute;
  top: 15px;
  left: 24px;
  z-index: 1;
}

.refund-step li:last-child::after {
  display: none;
}

.wallet-head {
  background-color: #f5faff;
  border-radius: 0.5rem 0.5rem 0 0;
}

.cash-point {
  width: 2rem;
  height: 2rem;
  background-color: #ffc104;
  border: 2px solid #002d5b;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  line-height: 2rem;
  font-size: 0.75rem;
  margin-right: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cash-info {
  background-color: rgba(255, 193, 4, 0.2);
  border-radius: 0.5rem;
}

.hex {
  width: 30px;
  height: 18px;
  background-color: #002d5b;
  border-color: #002d5b;
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: 0;
}

.hex i {
  color: #ffffff;
}

.hex:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 8px solid;
  border-color: inherit;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  top: -8px;
  left: 0;
}

.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -8px;
  border-top: 8px solid;
  border-color: inherit;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  left: 0;
}

.pagination li a.page-link {
  border: 2px solid #9f9f9f;
  border-radius: 0.5rem;
  color: #ffc104;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  width: 3rem;
  height: 3rem;
  display: inline-block;
  margin-right: 10px;
}

.pagination li a.page-link:hover {
  color: #ffffff;
  background: #ffc104;
  border-color: #9f9f9f;
}

.pagination li a.page-link:first-child {
  border-radius: 0;
  border-radius: 0.5rem;
}

.pagination li a.page-link:last-child {
  border-radius: 0;
  border-radius: 0.5rem;
}

.post-wrap {
  padding-bottom: 3rem;
  margin-bottom: 3rem;
}

.post-wrap p i {
  line-height: 2rem;
  font-size: 0.875rem;
  border-left: 2px solid #ffc104;
  height: 100%;
  display: block;
  padding: 1rem;
  background: rgba(255, 193, 4, 0.04);
  text-align: justify;
}

.post-wrap .post-head {
  font-size: 1.5rem;
  margin: 2rem 0;
  font-weight: bold;
}

.post-wrap .post-desc {
  font-size: 0.875rem;
  line-height: 2rem;
  text-align: justify;
}

.blog-post-social {
  border-top: 2px solid #efeeee;
  border-bottom: 2px solid #efeeee;
  padding: 25px 0px;
  margin-bottom: 55px;
}

.post-tag-list {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.post-tag-list li {
  font-weight: bold;
  color: #002d5b;
  margin-right: 8px;
}

.post-tag-list li:first-child {
  margin-right: 15px;
}

.post-tag-list li a {
  font-size: 0.875rem;
  color: #9f9f9f;
  font-weight: normal;
}

.post-tag-list li a:hover {
  color: #002d5b;
}

.blog-icon-list {
  display: flex;
  justify-content: start;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.blog-icon-list li {
  margin-left: 1rem;
}

.blog-icon-list li:first-child {
  font-weight: bold;
  color: #002d5b;
}

.blog-icon-list li a {
  display: block;
  transition: 0.4s ease;
}

.blog-icon-list li a i {
  color: #002d5b;
}

.blog-icon-list li a:hover {
  transform: scale(1.2);
}

.comment-box {
  border-radius: 0.5rem;
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
}

.comment-box .comment-head {
  padding: 2rem;
  font-weight: bold;
  font-size: 1.5rem;
}

.comment-box .user-comment-card {
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.comment-box .user-comment-card li {
  position: inherit;
}

.comment-box .user-comment-card .userPic {
  position: relative;
}

.comment-box .user-comment-card .userName {
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 1rem;
  font-size: 0.875rem;
}

.comment-box .comment-title {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
}

.comment-box .comment-title .comment-meta span {
  color: #343534;
  font-size: 0.875rem;
}

.comment-box .comment-body p {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #9f9f9f;
}

.comment-box h4 {
  margin-bottom: 2rem;
}

.blog-list-head {
  position: relative;
  font-size: 20px;
  text-transform: uppercase;
  margin: 0 0 30px 0;
  color: #002d5b;
  font-weight: bold;
}

.blog-list-head :after {
  content: "";
  width: 20px;
  height: 4px;
  position: absolute;
  bottom: -10px;
  left: 0;
  background: #ffc104;
}

.widget-categories li :first-child {
  margin-top: 0;
}

.widget-categories li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffc104;
  padding: 10px 15px;
  padding-left: 15px;
  position: relative;
  transition: all 0.2s;
}

.widget-categories li a:hover {
  background: rgba(255, 193, 4, 0.04);
  color: #002d5b;
}

.widget-categories li a:hover::before {
  opacity: 1;
  transform: translateX(0);
}

.widget-categories li a::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  background: #002d5b;
  border-radius: 2px 0px 0px 2px;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.2s;
  transform: translateX(-5px);
}

.tags {
  list-style-type: none;
  border-radius: 0.5rem;
  box-shadow: 0 0rem 0.8rem rgba(52, 53, 52, 0.15);
  padding: 1rem;
}

.tags li {
  padding: 1rem 0.5rem;
  margin: 0 0;
  display: inline-block;
}

.tags li a {
  color: #002d5b;
  font-weight: 600;
  border-radius: 0.5rem;
  background: #f5faff;
  padding: 0.5rem;
}

.tags li a:hover {
  background: #002d5b;
  color: #ffffff;
}

#default-page .head-wrapper {
  min-height: 110vh;
}

.promo-ban .container-fluid .owl-carousel .owl-nav button.owl-prev {
  left: 0;
}

.promo-ban .container-fluid .owl-carousel .owl-nav button.owl-next {
  right: 0%;
}

#corporate-page {
  position: relative;
  /*-- coupon deals css --*/
}

#corporate-page .head-wrapper {
  background: #002d5b;
  background: linear-gradient(0deg, #002d5b 0%, #002d5b 100%);
}

#corporate-page .head-wrapper::after {
  background: rgba(52, 53, 52, 0.2);
  opacity: 0.1;
}

#corporate-page .head-wrapper .flight-search {
  background-color: transparent;
}

#corporate-page .head-wrapper .flight-search .search-pan {
  padding: 0rem;
}

#corporate-page .head-wrapper .flight-search .search-pan .form-control {
  background-color: #efeeee;
}

#corporate-page .head-wrapper .flight-search .icon-20 {
  border: 1px solid #002d5b;
}

#corporate-page .coupon-deal {
  position: relative;
}

#corporate-page .coupon-deal #carouseldeals img {
  width: auto;
}

#corporate-page .coupon-deal .owl-carousel .owl-nav button.owl-next {
  top: 0;
  right: 0;
}

#corporate-page .coupon-deal .owl-carousel .owl-nav button.owl-prev {
  top: 0;
  left: 0;
}

#corporate-page .coupon-deal .owl-carousel .owl-nav button.owl-next,
#corporate-page .coupon-deal .owl-carousel .owl-nav button.owl-prev {
  position: absolute;
}

#corporate-page .owl-dots {
  position: absolute;
  bottom: -20px;
  left: 39%;
}

@media screen and (max-width: 575px) {
  #corporate-page .owl-dots {
    left: 31%;
  }
}

#corporate-page .footer {
  background-color: #efeeee;
}

#corporate-page .footer h1,
#corporate-page .footer h2,
#corporate-page .footer h3,
#corporate-page .footer p {
  color: #343534;
}

#corporate-page .footer .social a.smo {
  border-radius: 50%;
  padding: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5;
  border: 1px solid #343534;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-left: 0.5rem;
}

#corporate-page .footer .social a,
#corporate-page .footer .social .botom-link {
  color: #343534;
}

#corporate-page .footer .social a:hover,
#corporate-page .footer .social .botom-link:hover {
  color: #ffc104;
}

#video-page .head-wrapper {
  min-height: 95vh;
}

#video-page .flight-search {
  background: rgba(255, 255, 255, 0);
  z-index: 2;
}

#video-page .content {
  margin-top: 8rem;
}

@media screen and (max-width: 992px) {
  #video-page .content {
    margin-top: 4rem;
  }
}

#video-page .video-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%),url("https://dl.traveloplex.com/img/portalhomebg-min.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  /* width: 100%;
  max-height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
}

#video-page .video-image .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

#video-page .video-image:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(187 185 185 / 60%);
  z-index: 2;
}

/*-- header wraper css --*/
.head-wrapper {
  background-image: linear-gradient(rgb(0 0 0) 0%, rgb(0 0 0 / 14%) 100%), url("https://dl.traveloplex.com/img/portalhomebg-min.jpg");
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%),url("https://dl.traveloplex.com/img/portalhomebg-min.jpg"); */
  /* background: url('../images/hero/banner-bg.jpg') no-repeat; */
  background-position: center center;
  display: block;
  min-height: 80vh;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.head-wrapper::after {
  content: "";
  position: absolute;
  display: block;
  /* background: #343534; */
  opacity: 0.6;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

/*-- header css --*/
.header .sideMenu {
  position: relative;
}

.header .sideMenu .custom-btn-close {
  background-image: none;
  background: #002d5b;
  position: absolute;
  right: -48px;
  top: 0;
  border-radius: 0;
  padding: 1rem;
  opacity: 1;
  line-height: 0;
}

.header .sideMenu .custom-btn-close:hover {
  background-color: #ffc104;
  color: #343534;
}

.header .sideMenu .menuList li {
  line-height: 3rem;
}

.header .sideMenu .menuList li a {
  color: #ffffff;
  display: flex;
}

.header .sideMenu .menuList li a:hover {
  color: #343534;
}

.header .sideMenu .social a {
  color: #ffffff;
}

.header .sideMenu .social a:hover {
  color: #ffc104;
}

@media screen and (max-width: 575px) {
  .header .offcanvas-start {
    width: 260px;
  }
}

.header .btn-left {
  width: auto;
  height: auto;
  min-height: 50px;
  background-color: #002d5b;
  color: #ffffff;
  background-position: 0 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.header .btn-left:hover {
  background-color: #ffc104;
  color: #343534;
}

/*-- custom theme css --*/
.custom-btn-head {
  color: #ffffff;
  padding: 0.6rem 1.6rem;
  background-color: #002d5b;
}

.custom-shadow:hover {
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.5);
}

/*-- Search engine css*/
.flight-search {
  position: relative;
  z-index: 0;
  background: rgba(255, 255, 255, 0.5);
  padding: 1.5rem 0;
  /*checkbox css*/
}

.flight-search .icon-20 {
  width: 20px;
  height: 20px;
}

.flight-search .search-pan {
  background: #ffffff;
  padding: 0.8rem;
  min-height: auto;
  justify-content: space-evenly;
  /*Date calender*/
}

.flight-search .search-pan .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 50%;
}

.flight-search .search-pan .dropdown .dropdown-menu {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  /*-- Droup down Traveller --*/
}

.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li {
  border-bottom: 1px solid #f5faff;
  display: flex;
  padding: 5px 0 5px 0;
}

.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li span {
  font-size: 14px;
  font-weight: 500;
}

.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li .br {
  display: block;
  padding-top: 0px;
}

.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li .btn {
  background-color: #002d5b;
  color: #ffffff;
  border: 0;
}

.flight-search .search-pan .dropdown .dropdown-menu .drop-rest li .btn:hover {
  background-color: #ffc104;
  color: #343534;
}

/* .flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input {
  align-items: center;
  width: 45%;
  width: auto;
} */

/* @media screen and (max-width: 992px) {
  .flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input {
    width: 30%;
  }
} */

/* @media screen and (max-width: 575px) {
  .flight-search .search-pan .dropdown .dropdown-menu .plus-minus-input {
    width: 50%;
  }
} */

.flight-search
  .search-pan
  .dropdown
  .dropdown-menu
  .plus-minus-input
  .input-group-button
  button {
  background-color: #002d5b;
  border: none;
  color: #ffffff;
}

.flight-search
  .search-pan
  .dropdown
  .dropdown-menu
  .plus-minus-input
  .input-group-button
  button:hover {
  background-color: #ffc104;
  color: #343534;
}

.flight-search
  .search-pan
  .dropdown
  .dropdown-menu
  .plus-minus-input
  .input-group-field {
  text-align: center;
  width: 50%;
  background: #f5faff;
  border: 0;
}

.flight-search
  .search-pan
  .dropdown
  .dropdown-menu
  .plus-minus-input
  .input-group-field::-webkit-inner-spin-button,
.flight-search
  .search-pan
  .dropdown
  .dropdown-menu
  .plus-minus-input
  .input-group-field::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

.flight-search .search-pan .form-group {
  position: relative;
}

.flight-search .search-pan .icon-pos {
  top: 0.6rem;
  left: 0.5rem;
  color: #9f9f9f;
}

@media screen and (max-width: 992px) {
  .flight-search .search-pan .icon-pos {
    top: 1rem;
  }
}

.flight-search .search-pan .input-group {
  color: inherit;
}

.flight-search .search-pan .form-control {
  font-size: 14px;
  background-color: #f5faff;
  min-height: 56px;
  border: 0px !important;
  text-decoration: none;
  border-radius: 0.5rem;
  text-align: left;
  width: 100%;
}

.flight-search .search-pan .cal-input {
  background: none;
  border: 0px;
  width: 6rem;
  padding: 0.4rem 0 0 0.5rem;
}

.flight-search .search-pan .dep-date-input {
  position: relative;
  width: auto;
  height: 2rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  background: #ffffff;
  border-style: none;
  padding: 0 0;
  margin: 0.3rem 0 0 2rem;
}

.flight-search .search-pan .dep-date-input:after {
  content: "";
  position: absolute;
  right: -12px;
  bottom: 0px;
  width: 0;
  height: 0;
  border-left: 0.8rem solid #ffffff;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
}

.flight-search .search-pan .arv-date-input {
  position: relative;
  width: 45%;
  height: 2rem;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: #ffffff;
  border-style: none;
  padding: 0 0;
  margin: 0.3rem 0 0 2rem;
}

.flight-search .search-pan .arv-date-input:before {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 0.5rem solid #f5faff;
  border-top: 1rem solid transparent;
  border-bottom: 1rem solid transparent;
  /* border-bottom: 1rem solid transparent; */
}

.flight-search .search-pan .dropdown-toggle {
  padding: 0 1rem 0 1rem;
}

.flight-search .search-pan .dropdown-toggle::before {
  color: #9f9f9f;
  right: 0.8rem;
}

.flight-search .nav-tabs .nav-item .nav-link {
  font-weight: 700;
  color: white !important;
  /* color: #002d5b; */
  background-color: transparent;
  border: none;
  padding: 0.5rem 1rem 1.5rem 0rem;
}

.flight-search .nav-tabs .nav-item .nav-link:hover {
  border-color: transparent;
  color: #ffc104;
}

.flight-search .nav-tabs .nav-item .active span {
  background: #ffffff !important;
  border: 1px solid #ffc104;
  position: relative;
}

.flight-search .nav-tabs .nav-item .active span:after {
  content: "";
  display: block;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid #ffc104;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
}

.flight-search .btn-search {
  background-color: #002d5b;
  min-height: 56px;
  color: #ffffff;
  min-width: 75px;
  width: 100%;
  border-radius: 0.5rem;
  border: none;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.flight-search .btn-search:before {
  width: 100%;
  height: 100%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: -100%;
  background: #ffc104;
  transition: all 0.2s;
}

.flight-search .btn-search:hover {
  color: #343534;
}

.flight-search .btn-search:hover:before {
  top: 0;
  left: 0;
}

.flight-search .pos-swap {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #002d5b;
  position: absolute;
  top: 0.9rem;
  right: -1.2rem;
  z-index: 1;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.flight-search .pos-swap:hover {
  background-color: #ffc104;
  color: #343534;
}

@media screen and (max-width: 992px) {
  .flight-search .pos-swap {
    right: 0;
    top: 2.5rem;
  }
}

.flight-search .check-wrap {
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #343534;
}

.flight-search .check-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.flight-search .check-wrap .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  border-radius: 0.2rem;
  /* border: 1px solid #343534; */
}

.flight-search .check-wrap .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.flight-search .check-wrap .checkmark:after {
  left: 0.2rem;
  top: 0rem;
  width: 0.5rem;
  height: 0.8rem;
  border: solid #343534;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.flight-search .check-wrap:hover input ~ .checkmark {
  background-color: #f5faff;
  background: #ffc104;
}

.flight-search .check-wrap input:checked ~ .checkmark {
  border: 1px solid #343534;
}

.flight-search .check-wrap input:checked ~ .checkmark:after {
  display: block;
}

/*-- calender header modify css --*/
.ui-widget-header {
  background-color: #ffc104 !important;
  color: #343534 !important;
}

/*-- Promotional banner css --*/
.promo-ban {
  position: relative;
}

.promo-ban .owl-dots {
  display: none;
}

.promo-ban .owl-nav {
  margin: 0;
}

.promo-ban .owl-carousel .owl-item .promo {
  height: 100%;
  min-height: auto;
}

.promo-ban .owl-carousel .owl-item .promo img {
  height: auto;
}

.promo-ban .owl-carousel .owl-nav {
  margin: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.promo-ban .owl-carousel .owl-nav button.owl-prev,
.promo-ban .owl-carousel .owl-nav button.owl-next {
  color: #343534;
  font-size: 3rem;
  position: absolute;
  top: 40%;
  background: #ffffff;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  transition: all 0.3s ease-in-out;
}

.promo-ban .owl-carousel .owl-nav button.owl-prev span,
.promo-ban .owl-carousel .owl-nav button.owl-next span {
  line-height: 3rem;
  position: absolute;
  top: -10px;
  left: 8px;
  color: #002d5b;
}

.promo-ban .owl-carousel .owl-nav button.owl-prev:hover,
.promo-ban .owl-carousel .owl-nav button.owl-next:hover {
  background-color: #ffc104;
}

.promo-ban .owl-carousel .owl-nav button.owl-prev {
  left: -2%;
}

@media screen and (max-width: 575px) {
  .promo-ban .owl-carousel .owl-nav button.owl-prev {
    left: 0px;
  }
}

.promo-ban .owl-carousel .owl-nav button.owl-next {
  right: -2%;
}

@media screen and (max-width: 575px) {
  .promo-ban .owl-carousel .owl-nav button.owl-next {
    right: 0px;
  }
}

.promo-ban .owl-carousel:hover .owl-nav {
  opacity: 1;
}

.promo-ban .promo-hover {
  bottom: -130px;
  left: 0;
  height: auto;
  color: #ffffff;
  padding: 10px 15px;
  margin: 0 0;
  width: 100%;
  position: absolute;
  transition: all 0.3s ease 0s;
  min-height: 175px;
  background: #002d5b;
  opacity: 0.8;
}

.promo-ban .item {
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: 0.5rem;
}

.promo-ban .item:hover .promo-hover {
  background: #002d5b;
  opacity: 0.9;
  bottom: 0px;
  color: #ffc104;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.promo-ban .item span {
  z-index: 10;
}

/*-- Travel deals css --*/
.travel-deal {
  position: relative;
}

/*-- card effect --*/
.cardeffect {
  transition: all 500ms ease-in-out;
}

.cardeffect:hover {
  transform: translateY(-10px);
  cursor: pointer;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

/*-- Flight Featured deals css --*/
.featured-deal {
  position: relative;
}

.featured-deal .picHeight {
  position: relative;
  max-height: 240px;
  height: auto;
}

@media screen and (max-width: 767px) {
  .featured-deal .picHeight {
    max-height: 100%;
  }
}

.featured-deal .airline-tags {
  top: 1rem;
  left: 1rem;
  z-index: 1;
}

.featured-deal .airline-tags .airlineName {
  opacity: 0;
}

.featured-deal .airline-icon {
  display: block;
  background: #ffffff;
  border-radius: 40px;
  cursor: pointer;
}

.featured-deal .airline-icon a {
  text-decoration: none;
  color: #002d5b;
}

.featured-deal .airline-icon a:hover {
  border-radius: 50px;
}

.featured-deal .airline-icon a:hover a {
  color: #ffffff;
}

.featured-deal .airline-icon a:hover .airlineName {
  opacity: 100;
}

/*-- flood effect --*/
.flood-effect {
  transition: 0.25s ease-in-out;
  overflow: hidden;
  display: inline-block;
  border-radius: 40px;
  vertical-align: middle;
}

.flood-effect:hover {
  box-shadow: inset 10rem 0 0 0 #002d5b;
}

.flood-effect:hover:hover a {
  color: #ffffff;
}

/*-- Shine effect --*/
.hoverShine figure {
  position: relative;
}

.hoverShine figure::before {
  position: absolute;
  top: 0;
  left: -90%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  transform: skewX(-25deg);
}

.hoverShine figure:hover::before {
  animation: shine 0.75s;
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

/*-- card hover effect --*/
.card-effect {
  overflow: hidden;
  z-index: 2;
}

.card-effect:hover .card-box {
  left: 0;
}

.card-effect .card-box {
  width: 100%;
  height: 100%;
  left: -50rem;
  top: 0;
  background: #ffc104;
  position: absolute;
  transition: all 0.5s ease;
  z-index: -1;
}

/*-- image hover effect --*/
.img-effect img {
  transition: all 0.4s ease-in-out;
}

.img-effect:hover {
  transform: scale(1.5);
  transition: all 0.4s ease-in-out;
}

/*-- process card hover effect --*/
.process-card {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.process-card:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -0.2rem;
  left: -0.2rem;
  background: #ffc104;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.process-card:hover:before {
  transform: scale(50);
}

.process-card:hover p {
  transition: all 0.3s ease-out;
  color: #343534;
}

.step-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  top: 0;
  left: 0;
  border-radius: 0 4px 0 32px;
}

.step-corner .step-arrow {
  color: #343534;
}

.process-card {
  cursor: pointer;
}

.process-card svg {
  width: 70px;
  height: 70px;
  transition: 0.5s ease-in-out;
}

.process-card:hover svg {
  transform: translateY(-20px);
}

.process-icon {
  margin-top: 3rem;
}

#work-process-01 path,
#work-process-02 path,
#work-process-03 path {
  fill: #002d5b;
}

/*-- flight top destination css --*/
.flight-destination {
  position: relative;
}

.flight-destination img {
  width: 100%;
  max-height: 185px;
}

@media screen and (max-width: 767px) {
  .flight-destination img {
    max-height: 100%;
  }
}

.text-link,
.text-link > span {
  position: relative;
}

.text-link:before,
.text-link:after,
.text-link > span:before,
.text-link > span:after {
  content: "";
  position: absolute;
  transition: transform 0.5s ease;
}

.text-link-effect {
  display: inline-block;
  overflow: hidden;
}

.text-link-effect:before,
.text-link-effect:after {
  right: 0;
  bottom: 0;
  background: #ffc104;
}

.text-link-effect:before {
  width: 100%;
  height: 2px;
  transform: translateX(-100%);
}

.text-link-effect:after {
  width: 2px;
  height: 100%;
  transform: translateY(100%);
}

.text-link-effect > span {
  display: block;
  color: #002d5b;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.text-link-effect > span:before,
.text-link-effect > span:after {
  left: 0;
  top: 0;
  background: #ffc104;
}

.text-link-effect > span:before {
  width: 100%;
  height: 2px;
  transform: translateX(100%);
}

.text-link-effect > span:after {
  width: 2px;
  height: 100%;
  transform: translateY(-100%);
}

.text-link-effect:hover:before,
.text-link-effect:hover:after,
.text-link-effect:hover > span:before,
.text-link-effect:hover > span:after {
  transform: translate(0, 0);
}

/*-- unique experience css --*/
.recom-sec {
  position: relative;
}

.recom-sec .con {
  position: absolute;
  left: 5%;
  top: 5%;
}

.item-effect {
  background: #343534;
  overflow: hidden;
  max-height: 250px;
}

@media screen and (max-width: 575px) {
  .item-effect {
    max-height: 100%;
  }
}

.item-effect img {
  min-height: 220px;
}

.item-effect a {
  position: absolute;
  bottom: 0;
  left: 10%;
  border-radius: 50%;
  font-size: 2rem;
  color: #ffffff;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  background: #002d5b;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 300ms 0ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.item-effect:hover a {
  transform: translate(-50%, -50%) scale(1);
  transition: transform 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: 0.3s ease-in-out;
}

.item-effect:hover a:hover {
  background-color: #ffc104;
  color: #343534;
}

.item-effect:hover img {
  opacity: 0.3;
  filter: grayscale(10%);
}

/*-- ribbon css --*/
.ribbon {
  --ribbon-size: 20px;
  --ribbon-padding: 10px;
  padding: var(--ribbon-padding);
  padding-inline-start: calc(var(--ribbon-size) + var(--ribbon-padding));
  border-radius: 4px;
  background-color: #002d5b;
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-clip-path: polygon(
    0 0,
    var(--ribbon-size) 50%,
    0 100%,
    100% 100%,
    100% 0
  );
  clip-path: polygon(0 0, var(--ribbon-size) 50%, 0 100%, 100% 100%, 100% 0);
}

/*-- button effect --*/
.btn::before,
.btn::after {
  background: #ffc104;
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.btn-effect::after {
  height: 100%;
  left: 0;
  top: 0;
  width: 0;
}

.btn-effect:hover:after {
  width: 100%;
}

/*-- footer css --*/
.footer {
  background-color: #343534;
}

.footer h1,
.footer h2,
.footer h3,
.footer p {
  color: #ffffff;
}

.footer .custom-btn-subscribe {
  color: #ffffff;
  background-color: #002d5b;
}

.footer .custom-btn-subscribe:hover {
  color: #343534;
  background-color: #002d5b;
}

.footer .fl-menu li {
  list-style: none;
  line-height: 34px;
}

.footer .fl-menu li a {
  color: #9f9f9f;
  position: relative;
}

.footer .fl-menu li a::after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  transition: opacity 0.35s, transform 0.35s;
  transform: scale(0, 1);
}

.footer .fl-menu li a:hover {
  color: #ffc104;
}

.footer .fl-menu li a:hover:after {
  opacity: 1;
  transform: scale(1);
}

.footer .social a,
.footer .social .botom-link {
  color: #ffffff;
}

.footer .social a:hover,
.footer .social .botom-link:hover {
  color: #ffc104;
}

/* Back to top button css */
.back-to-top {
  position: fixed;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  z-index: 50;
  border-radius: 50%;
  right: 2rem;
  bottom: 2rem;
  text-align: center;
  padding: 0px;
  margin: 0px;
}

.back-to-top .back-to-top a {
  text-decoration: none;
}

.back-to-top i {
  color: #ffffff;
  font-size: 1.8rem;
  transition: all 0.3s ease-in-out;
  top: 8px;
  position: relative;
}

.back-to-top i:hover {
  color: #ffc104;
}

/*# sourceMappingURL=main.css.map */
.float-start {
  display: flex;
}

/* Old Css */

.autoComInput {
  width: 100%;
  height: auto;
  /* font-size: 14px; */
  /* color: #242424; */
  font-weight: 500;
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-autocomplete-input::placeholder {
  color: #9e9e9e !important;
  font-weight: 500;
  font-size: 16px;
  /* color: #70717270 !important; */
}

>>> .datePickInput .dp__input::placeholder {
  color: #9e9e9e !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  /* color: #70717270 !important; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}

>>> .p-autocomplete-dd .p-autocomplete-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0px;
  display: none;
}

.v-select .v-field .v-text-field__prefix,
.v-select .v-field .v-text-field__suffix,
.v-select .v-field .v-field__input,
.v-select .v-field.v-field {
  cursor: pointer;
  height: 41px;
  box-shadow: none !important;
}

body {
  zoom: 0.9;
  -ms-zoom: 0.9;
  -webkit-zoom: 0.9;
  /* -moz-transform: scale(0.9, 0.9); */
  -moz-transform-origin: top;
}

>>> .captcha_section .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  position: relative !important;
  top: -6px !important;
  padding: 0px 6px !important;
}

>>> .captcha_section
  .v-input--density-default
  .v-field--variant-solo-filled
  .v-label.v-field-label--floating {
  top: 8px;
  margin: 0px 7px;
  font-size: 10px;
}

>>> .captcha_section .v-field--center-affix .v-label.v-field-label {
  font-size: 11px;
  top: 50%;
  transform: translateY(-50%);
}

>>> .captcha_section .v-field__input {
  padding: 0px 6px;
}

#visible {
  visibility: hidden;
}

.container-styles {
  border-radius: 20px;
}

>>> .v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

>>> .v-overlay__content {
  outline: none;
  position: absolute;
  pointer-events: auto;
  contain: layout;
}

.recent_style {
  position: absolute;
  background: white;
  width: 310px;
  margin: 4px;
  padding: 5px 0px;
  border-radius: 10px;
  z-index: 5;
  border: 2px solid gray;
  cursor: pointer;
}

.resu-form:hover {
  background-color: #ffc104 !important;
  border-radius: 50px !important;
}

/* >>>.v-selection-control__input {
  bottom: 6px;
} */

>>> .subscribe-inputFields .v-input__details {
  display: block !important;
  position: relative;
  top: 5px;
}

>>> .subscribe-inputFields .v-input__details .v-messages__message {
  font-size: 10px !important;
}

.v-select .v-select__selection-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}

.v-selection-control__wrapper {
  width: 30px;
  max-height: 23px;
}

.login-card {
  position: absolute;
  z-index: 2;
}

/* Datepicker Style Section */

.dp__icon {
  display: block;
}

/* .v-icon {
   width: 100%;
 } */

>>> .dp__input {
  border: none;
  border-radius: 0px !important;
  padding: 0px;
  height: auto;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  height: 43px;
}

>>> .dp__input::placeholder {
  color: #9e9e9e !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  /* color: grey; */
}

.calendar-container {
  display: flex;
  align-items: center;
  position: relative;
}

.label-top {
  position: absolute;
  top: -6px;
  left: -46px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
}

.datepicker-label {
  position: absolute;
  bottom: -38px;
  transform: translateY(-100%);
  background-color: transparent;
  pointer-events: none;
  z-index: 1;
  line-height: 1;
  color: #9ca3af;
  font-size: 14px;
  font-weight: 300 !important;
}

.label-top2 {
  position: absolute;
  top: -6px;
  left: -46px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 28px;
  left: -8px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.multiLabel-top {
  position: absolute;
  right: 87px;
  transform: translateY(-100%);
  background-color: white;
  padding: 0 8px;
  font-size: 15px;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute;
  right: 69px;
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
  font-weight: 600;
}

.delete-icon {
  display: flex;
  justify-content: end;
  position: absolute;
  left: 12px;
  z-index: -1;
}

>>> .dp__clear_icon {
  top: 52% !important;
}

/* Datepicker Style Section End */

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100% !important;
  border-radius: 50% !important;
  margin: 8px !important;
}

/* >>> .dp__input_wrap {
  position: relative;
  width: 100% !important;
  box-sizing: unset;
  top: 0px;
  left: 0px !important;
} */

>>> .dp__input {
  background-color: #fff0;
}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

>>> .v-field__input {
  color: grey !important;
}

.form-btn {
  padding-left: 40px;
  color: white;
}

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
  font-size: 15px !important;
}

.btn-active {
  background-color: #ff5400;
  color: white;
}

.v-checkbox .v-input__details {
  display: none;
}

/* Login Page End */

/* Login Remember Btn */

/* .v-selection-control {
  align-items: start;
} */

.v-label {
  align-items: start;
}

.labeling1 {
  position: relative;
  /* bottom: -6px; */
  top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: black;
  left: 30px;
  z-index: 2;
}

.labeling {
  position: relative;
  /* bottom: -6px; */
  top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(51, 6, 148);
  left: 30px;
  z-index: 2;
}

.labeling:focus {
  color: red !important;
}

/* .back {
   background-image: url('../assets/bg.png');
   background-repeat: no-repeat;
   background-size: 800px;
   background-position: top right 24%;
   z-index: 1;
   border-radius: 20px;
 } */

body {
  font-family: var(--normal-font);
}

/* navbar */
.logo {
  width: 180px;
}

.nav-link {
  font-weight: var(--font-600);
  font-size: var(--font-14);
}

.nav-link.active {
  color: var(--primary-color) !important;
}

.btn-white {
  background-color: white;
  padding: 10px 25px;
  border-radius: 15px;
}

.btn-text {
  color: var(--primary-color);
  font-weight: var(--font-700);
}

.v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

/* .v-btn__content {
  color: #FF5400;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
} */

.book-ticket {
  /* color: #FF5400; */
  background-color: #f0f0f0;
}

.whatts-logo {
  display: block;
  position: fixed;
  cursor: pointer;
  right: 20px;
  bottom: 112px;
  z-index: 9999;
  width: 65px;
  height: 65px;
}

/* .whatts-logo {
   display: block;
   position: fixed;
   cursor: pointer;
   right: 30px;
   bottom: 40px;
   z-index: 9999;
 } */

/* navbar end */

/*Flight banner */
.banner-left-small-title {
  color: var(--primary-color) !important;
}

/* .bannar-bg {
   background-image: url('../assets/bg-map.png');
   background-size: 55% 83%;
   background-repeat: no-repeat;
   background-position: center;
 } */

.flight-right {
  width: 500px !important;
  height: 500px;
}

.banner-left-small-title h6 {
  font-weight: var(--font-600);
}

.banner-small-text {
  color: var(--primary-color) !important;
}

.banner-left-title h4 {
  font-family: var(--title-font);
  font-weight: var(--font-600);
  font-size: 60px;
}

.location {
  color: #ff5400;
  width: 22px;
}

.form-control {
  border: none;
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
  /* background-color: unset !important; */
  /* width: 150px; */
}

.form-control:focus {
  color: unset !important;
  border-color: unset !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-select {
  color: unset !important;
  /* border-color:unset !important; */
  outline: none !important;
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
  /* width: 150px; */
}

.form-select:focus {
  border-color: unset !important;
}

.form-control::placeholder {
  font-size: 14px;
  font-weight: var(--font-500);
}

.input-group-text {
  text-align: center;
  white-space: nowrap;
  border: none;
}

.form-location {
  width: 18px;
  margin-top: -15px;
}

.btn {
  background-color: #ff5400;
  color: #fff;
}

.btn:hover {
  border: 1px solid #ff5400;
  color: #ff5400;
}

/* banner end */

/* Explore Top Destination */

.top-destination {
  color: #ff5400;
  font-size: 14px;
}

.heading {
  font-family: var(--title-font);
  font-weight: var(--font-600);
}

.card.top-card {
  height: 330px;
  border-radius: 25px;
}

.card.top-card .card-tour {
  height: 220px;
  width: auto;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

/* .icon-container {
    margin-right: 10px;
  } */

.calendar-wrapper {
  display: inline-block;
  border-bottom: 1px solid lightgray;
}

.calendar-label {
  display: block;
  margin-bottom: 5px;
}

.card-body .star {
  color: #ff5400;
  margin-top: 20px;
}

.card-body .rate {
  color: #ff5400;
}

.how-we-work {
  color: #ff5400;
  font-weight: 600;
  font-size: 14px;
}

.how-we-section {
  margin-top: 100px;
}

.choose {
  width: 40px;
}

.choose-item {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  width: 75px;
  height: 75px;
  border-radius: 10px;
}

.choose-para {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.car-right-wrapper {
  width: 700px;
  height: 700px;
}

.lorem {
  font-size: 11px;
}

.session-bg {
  background-image: url("../assets/bg1.png");
  background-repeat: no-repeat;
  background-position: top 100px right -290%;
}

.travel-type {
  background-color: #fff7ed;
  /* background-color: rgb(243, 216, 207); */
}

.trvel-para {
  color: #ff5400;
  font-weight: 600;
}

.owl-theme .owl-nav [class*="owl-"] {
  border: 2px solid darkturquoise;
  padding: 15px !important;
  border-radius: 50% !important;
  background-color: rgb(243, 216, 207) !important;
  width: 50px;
  margin-right: 20px;
  color: darkturquoise;
}

.owl-images img {
  height: 350px;
  border-radius: 30px;
}

.owl-theme .item {
  width: 330px;
}

.owl-images {
  position: relative;
}

.badge {
  position: absolute;
  top: 40px;
  left: 15px;
  background-color: #ff5400;
  color: #fff;
  border-radius: 30px;
}

.testi-para {
  color: #ff5400;
  font-weight: 600;
}

.testi-div {
  margin-top: 100px;
}

.card img {
  width: 100%;
  height: 100%;
}

.test-name {
  font-size: 18px;
  font-weight: 400;
}

.card-text {
  font-size: 14px;
}

.card {
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border: none;
  border-radius: 25px;
}

.card-relative {
  position: relative;
}

.quotes {
  position: absolute;
  top: -35px;
}

/* Subscribe Section */

.subscribe {
  /* background-color: navy !important; */
  background-color: #019df2 !important;
  color: white;
  border-radius: 14px;
  line-height: 28px;
}

input {
  outline: none;
}

>>> .v-switch__track {
  height: 12px;
  width: 24px;
}

>>> .v-switch__thumb {
  width: 16px;
  height: 16px;
}

.subscribe-input {
  border-radius: 17px;
  border: none;
  padding: 15px 20px;
  background-color: #fff;
}

>>> .dp__input {
  font-family: "Poppins", sans-serif !important;
}

.rounded-shadow {
  box-shadow: 0px 0px 4px 0px #ff6b0a;
}

>>> .subscribe-inputFields .v-input__control {
  display: flex;
  grid-area: control;
  height: 20px !important;
}

>>> .subscribe-inputFields .v-field__input {
  padding-top: 0;
}

>>> .subscribe-inputFields .v-field__field {
  top: -3px;
}

/* >>>.v-field__field {
  height: 40px;
} */

>>> .input-styles-2 .v-input .v-input__control {
  height: 25px !important;
}

>>> .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
  position: relative;
  /* top: -5px; */
}

>>> .subscribe-inputFields .v-input--density-default .v-field--variant-solo,
.v-input--density-default .v-field--variant-solo-inverted,
.v-input--density-default .v-field--variant-solo-filled,
.v-input--density-default .v-field--variant-filled {
  --v-input-control-height: 50px;
  --v-field-padding-bottom: -7px;
}

>>> .subscribe-inputFields .v-field--variant-solo {
  box-shadow: none;
}

>>> .subscribe-inputFields
  .v-input--density-default
  .v-field--variant-solo
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-solo-inverted
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-filled
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-solo-filled
  .v-label.v-field-label--floating {
  top: -1px;
}

/* .subscribe-btn {
   border-radius: 5px;
   border: none;
   width: 200px;
   height: 35px;
   background-color: #ff6b0a !important;
   color: white !important;
 } */

.subscribe-button {
  min-width: 0px !important;
  width: 40px;
  height: 40px;
  font-size: 16px;
  display: inline-block;
  border-radius: 50%;
  padding: 0px;
  background-color: #ff6b0a !important;
  color: white !important;
}

.subscribe-inputFields {
  border: 1px solid lightgray;
  border-radius: 32px;
}

.subscribe-inputFields .v-field--rounded {
  border-radius: 9999px 0px 0px 9999px;
  box-shadow: none;
}

.subscribe-inputFields .v-field--variant-solo {
  box-shadow: none;
}

>>> .v-input__details {
  display: none;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.email-error {
  position: absolute;
  margin-top: 20px !important;
  margin-bottom: -25px;
  margin-right: 65px;
}

.agree-error {
  position: absolute;
  margin-top: 40px;
  margin-left: 40px;
  font-size: 14px;
}

/* Subscribe Section End */

.form-input {
  border: none;
}

.btn-badge {
  background-color: aqua;
  border: none;
  border-radius: 40px;
}

.btn-badge:hover {
  background-color: aqua;
  border: none;
  color: black;
}

.py-8 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.social-icons {
  color: var(--primary-color) !important;
}

.fs-14 {
  font-size: 14px;
  list-style-type: none;
}

.fs-14 li {
  padding: 6px;
}

section {
  margin: auto !important;
}

.slide-card {
  border-radius: 4%;
  width: 258px;
  height: 300px;
}

.card-test {
  position: relative;
  padding: 10px 15px;
  border-radius: 8%;
  max-height: 500px;
}

.card-img {
  position: absolute;
  width: 100px;
  height: 100px;
  top: -50%;
  right: 0;
  transform: translateY(-50%);
}

.radio {
  border-radius: 5px;
  background-color: black;
  color: white;
  font-size: 12px !important;
  font-weight: 500;
  box-shadow: 0 4px 16px rgba(17, 17, 26, 0.05),
    0 8px 32px rgba(17, 17, 26, 0.05) !important;
}

.v-input--density-default {
  --v-input-control-height: 38px;
  /* position: relative;
  top: 0px; */
}

.normal-radio {
  font-size: 14px !important;
  font-weight: 400px;
  height: 32px;
  width: 98px;
  padding-right: 10px;
  padding-right: 16px;
  border: 1px solid #0000003d;
  box-shadow: none;
}

.normal-radio span {
  font-size: 12px !important;
  font-weight: 500;
  /* color: black; */
}

.cart-adult {
  padding: 2px 15px 15px 15px;
  width: 214px;
  cursor: pointer;
  position: absolute;
  top: 131px;
  left: 252px;
}

.adult {
  height: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  /* background-color: #F7F7F7; */
}

.booknow {
  padding-right: 20px;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

>>> .p-datepicker-group-container {
  background-color: #ffffff !important;
  width: 200px;
  padding: 5px 3px;
  border: 1px solid lightgray;
  position: relative;
  right: 14px;
}

.calendar-wrapper {
  border-bottom: 1px solid #a9a1a1;
  max-width: 500px;
}

.calendar-wrapper {
  border-bottom: 1px solid #a9a1a1;
  max-width: 100% !important;
}

>>> .p-datepicker {
  width: auto;
  background: white !important;
}

/* Flight Search Box */

.search-btn {
  min-width: 0px !important;
  width: 60px;
  height: 60px;
  font-size: 16px;
  display: inline-block;
  border-radius: 50%;
  padding: 0px;
}

.search-btn span {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 10px;
}

.formbottom_ClearBtn {
  border: 1px solid lightgray;
  border-radius: 30px;
  padding: 5px 8px;
  background-color: red;
}

.formbottom_addBtn {
  border: 1px solid lightgray;
  border-radius: 30px;
  padding: 5px 5px;
  background-color: green;
}

/* .search-btn {
   background-color: #ff6b0a;
   color: white !important;
   text-transform: capitalize;
 } */

.mdi-airplane-takeoff::before {
  content: "\F05D5";
  margin-top: 0px;
  margin-right: 10px;
  /* color: gray; */
}

.mdi-airplane-landing::before {
  content: "\F05D4";
  margin-top: 0px;
  margin-right: 10px;
  /* color: gray; */
}

>>> .dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

.p-component * {
  box-sizing: border-box;
  /* padding: 2px;
  padding-top: 8px;
  padding-bottom: 8px; */
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-icon {
  display: none;
}

/* >>>.p-autocomplete-panel {
   color: white;
   border-radius: 10px;
 } */

.p-error-from {
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: -16px;
}

.p-error-to {
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: 1px;
}

.dropdown-box {
  padding: 8px 10px;
  /* border-radius: 10px; */
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

.p-float-label {
  width: 100%;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  text-align: left;
}

.p-float-label label {
  position: absolute;
  top: 5px;
  left: -1px;
  font-weight: 600;
  font-size: 1.125rem;
  margin-top: -0.5rem;
  line-height: 1.75rem;
}

/* .p-float-label label {
   position: absolute;
   pointer-events: none;
   top: 17px;
   left: 0px;
   margin-top: -0.5rem;
   transition-property: all;
   transition-timing-function: ease;
   line-height: 1;
   color: #242424;
   font-weight: 600;
   font-size: 16px;
 } */

>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

/* End Flight Search Box */

/* .flight-back {
   background-image: url('../assets/travel-pattern-bg4.svg');
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-size: cover;
     background-position-y: 70px;
   background-color:#e0e9f191;
   background: linear-gradient(90deg, rgba(148, 187, 233, 0.7512254901960784) 0%, rgba(238, 174, 202, 1) 50%, rgba(148, 187, 233, 0.7456232492997199) 100%);

 } */

.form-card {
  width: 100%;
  padding: 20px;
  overflow: unset;
  z-index: 1;
  cursor: pointer;
  /* background-color: #ffffffe8; */
  box-shadow: none !important;
  border-radius: 0px 20px 20px 20px !important;
}

/* .form-card {
   width: 100%;
   padding: 20px 0px 20px 20px;
   overflow: unset;
   z-index: 1;
   cursor: pointer;
   background-color: #ffffffe8;
   box-shadow: none !important;
   border-radius: 0px 0px 0px 20px !important;
 } */

.form-container {
  position: relative;
  top: 20px;
}

.banner-lg-text {
  font-size: 72px;
  font-weight: 500;
}

.banner-sm-text {
  font-size: 18px;
}

.DatecolumnSizing {
  width: 210px !important;
}

.paxcolumnSizing {
  width: 240px !important;
}

@media only screen and (max-width: 480px) {
  .header {
    background: #ffffffcc !important;
  }

  .form-container {
    position: relative;
    top: 0px;
  }

  .banner-lg-text {
    font-size: 45px;
    font-weight: 500;
  }

  .banner-sm-text {
    font-size: 14px;
  }

  .mobile {
    display: none;
  }

  .flight1 {
    display: none !important;
  }

  .form-card {
    padding: 20px;
    border-radius: 0px 20px 20px 20px !important;
  }

  .pax-fonts span {
    font-size: 12px !important;
    color: #242424;
    font-weight: 500;
  }

  /* .flight-back {
     background-image: url('../assets/flight.png');
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-size: cover;
     background-position-y: 70px;

   } */

  /* .subcribe-card {
     margin-top: 10% !important
   } */

  .DatecolumnSizing {
    width: 100% !important;
  }

  .paxcolumnSizing {
    width: 100% !important;
  }
}

@media only screen and (max-width: 992px) {
  .car-right {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .session-bg {
    background-image: none;
  }

  .back {
    background-image: none;
  }

  .flight-right {
    width: 80% !important;
  }

  .form-btn {
    padding: 10px 0px 0px 0px !important;
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }

  .sub-text {
    font-size: 26px;
  }

  /* .subscribe-btn {
    width: 40%;
    height: 100%;
    font-size: 18px;
  } */

  .cart-adult {
    top: 113px !important;
    left: 275px !important;
  }

  .email-error {
    width: 100%;
  }

  .agree-error {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
    padding-right: 15px;
  }

  .cart-adult {
    top: 134px;
    left: 188px;
  }

  .email-error {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
  }

  .dropdown_section {
    width: auto !important;
  }

  /* .email-error {
    margin-right: 465px;
  } */
}

@media only screen and (max-width: 576px) {
  .cart-adult {
    top: 280px !important;
    left: 181px !important;
  }

  /* .email-error {
    margin-right: 240px;
  } */
}

.p-float-label::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

.p-float-label::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

.p-float-label::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}

/* Scroll Bar Work */

>>> .pv_id_2_list {
  overflow: auto !important;
  max-height: 100px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}

>>> .v-field__outline {
  display: none;
}

.input-styles {
  padding: 5px;
  height: 92px;
  /* box-shadow: 15px 10px 7px -18px gray;
   clip-path: inset(0px -16px 12px 0px); */
  padding: 1rem 1.5rem;
}

.styles_input1 {
  border-radius: 0px 0px 0px 60px !important;
}

.styles_input2 {
  border-radius: 0px 60px 0px 0px !important;
}

.input-styles-1 {
  height: 32px;
  width: 180px;
  padding: 4px 7px;
  border-radius: 15px;
  border: 1px solid #0000003d;
}

.input-styles-focus {
  box-shadow: 0 4px 16px rgba(17, 17, 26, 0.05),
    0 8px 32px rgba(17, 17, 26, 0.05);
  padding: 1rem 1.5rem;
  border-radius: 60px !important;
  height: 92px;
  -webkit-clip-path: none !important;
  clip-path: none !important;
}

/* .input-styles {
   box-shadow: 0px 1px 2px 0px gray;
   padding: 5px;
   background: #ffffff;
   border-radius: 7px;
   height: 44px;
 } */

.input-styles-2 {
  box-shadow: none;
  padding: 8px 5px 8px 12px;
  border-radius: 20px;
  border: 1px solid #0000003d;
  height: 32px;
  color: black;
  width: 115px;
}

>>> .select-option .v-select__selection-text {
  color: black !important;
  font-size: 12px !important;
  font-weight: 500;
  position: relative;
  top: -4px;
}

>>> .v-menu > .v-overlay__content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  left: 505px !important;
  width: 290px !important;
  border-radius: 20px !important;
  position: relative !important;
}

>>> .v-overlay-container .v-overlay .v-select__content {
  left: 2.8px !important;
  width: 300px !important;
  border-radius: 20px !important;
  position: relative !important;
}

/* .form-card {
   width: 100%;
   padding: 20px 0px 20px 20px;
   overflow: unset;
   z-index: 1;
   cursor: pointer;
   background-color: #ffffffe8;
   box-shadow: none !important;
   border-radius: 0px 0px 0px 20px;
 } */

>>> .select-1 .v-field__append-inner {
  padding-top: 15px !important;
}

>>> .select-1 .v-field__input {
  padding-top: 5px;
}

>>> .select-1 .v-input--density-default .v-field--variant-plain,
.v-input--density-default .v-field--variant-underlined {
  --input-control-height: 52px !important;
  --v-field-padding-bottom: 0px;
}

>>> .select-2 .v-field__append-inner {
  padding-top: 0px !important;
  top: -3px;
  position: relative;
}

>>> .select-2 .v-field__input {
  padding: 2px 0px 2px 2px !important;
}

>>> .select-2 .v-input--density-default .v-field--variant-plain,
.v-input--density-default .v-field--variant-underlined {
  --v-input-control-height: 48px;
  --v-field-padding-bottom: 10px !important;
}

>>> .select-option .v-input {
  --v-input-chips-margin-bottom: 0 !important;
  --v-input-chips-margin-top: -3px !important;
}

>>> .select-1 .v-list.v-theme--light.v-list--density-default.v-list--one-line {
  background: #f9f8f8 !important;
}

.back-bg {
  width: 100%;
  /* height: 500px; */
  border-radius: 20px;
  padding: 20px;
}

.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.toTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 52%;
  top: 60px;
  color: gray;
}

.p-float-label .p-inputwrapper-focus ~ label {
  display: none !important;
}

.p-float-label .p-inputwrapper-filled ~ label {
  display: none !important;
}

>>> .normal-radio .v-label {
  font-weight: 600 !important;
  opacity: 1 !important;
}

.pax-fonts span {
  font-size: 12px;
  color: black;
  font-weight: 500;
}

.contact-box {
  box-shadow: none;
  background: none;
  border: 1px solid lightgray;
}

>>> .contact-box .v-icon {
  transform: rotate(45deg);
}

.resu-form:hover {
  background-color: lightgray;
  /* padding:5px; */
}

.resu-form {
  padding: 10px;
}

.resu-card {
  border-radius: 10px;
  width: 350px;
  height: 200px;
  text-align: start;
}

/* Cookies Styles */

.cookie-info {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #333;
  color: white;
  text-align: center;
}

.cookie-info p {
  margin: 0;
}

.cookie-info button {
  margin-top: 5px;
  padding: 5px 10px;
  background-color: #ccc;
  border: none;
  cursor: pointer;
}

/* ----------ne adddd--- */

.form-group1 {
  display: flex;
  background: #f5faff;
  height: 100%;
  border-radius: 10px;
  align-items: center;
  position: relative;
}

.form-group1.focused {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 4, 0.5);
}

.form-control1 {
  background: #f5faff !important;
  height: 100%;
  /* position:absolute;
  border-radius:5px; */
}

.form-groupicon {
  align-items: center;
  display: flex;
  margin-left: 8px;
}

.addbtn {
  width: 30px;
  height: 25px;
  background: #002d5b;
  text-align: center;
  color: white;
  cursor: pointer;
}

.addbtn:hover {
  background: #ffc104;
  color: #002d5b;
}

.adddata {
  width: 60px;
  height: 25px;
  color: block;
  background: #f5faff;
  text-align: center;
}

.normalSwap {
  transition: transform 0.2s ease-out;
}

.rotateSwap {
  transform: rotate(180deg);
  transition: transform 0.2s ease-out;
}

.autocompete-menual-dropdown {
  border: 1px solid lightgray;
  background-color: white;
  cursor: pointer;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 350px;
  max-width: 100%;
  padding: 4px;
  border-radius: 2px;
  margin-top: 18px;
  margin-left: -44px;
  z-index: 99999;
  font-size: 15px;
  font-weight: 500;
}

/* .swap {
  position: relative;
  left:48px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #002d5b;
  cursor: pointer;
}
.swap:hover {
  background: #ffc104;
  color: #002d5b;
  color: #002d5b;
} */

 .dropdownSection .input-styles{
  height:auto;
  padding: 5px 7px;
  background-color:#f5faff;
  border-radius:0.5rem;
 }
 :deep(.advanceOpt .v-input__details){
  display:none;
}
:deep(.advanceOpt .v-switch__thumb){
  width :16px;
  height:16px;
}
:deep(.advanceOpt .v-switch__track){
  height:13px;
  min-width:30px;
}
/* :deep(.advanceOpt .v-label){
  font-size:14px;
  padding-left: 5px;
} */
:deep(.v-input__details){
  display:none;
}
:deep(.advanceOpt .dropdownSection .v-select .v-field__input) {
  padding: 0 !important;
  min-height: 30px;
}

:deep(.dropdownSection .v-field__append-inner) {
  padding: 0px !important;
}

:deep(.dropdownSection .v-selection-control__wrapper) {
  height: 20px;
}

:deep(.advanceOpt .dropdownSection .v-select input::placeholder) {
  font-size: 14px;
  font-weight: 500;
  color: #767676;
  opacity: 1;
  height: 20px;
}

/* InlucudeEclude Radio button */

:deep(.advanceRadio.v-radio-group .v-selection-control--density-default) {
  --v-selection-control-size: 24px !important;
}

:deep(.advanceRadio.v-radio-group .v-label) {
  font-size: 14px;
  font-weight: 500;
}
:deep(.advanceRadio1.v-radio-group .v-selection-control--density-default) {
  --v-selection-control-size: 24px !important;
}

:deep(.advanceRadio1.v-radio-group .v-label) {
  font-size: 14px;
  font-weight: 500;
}

.dropdownSection .input-styles .v-btn{
  height:30px;
}

.v-text-field.airlineCode{
  width:auto;
  /* width:180px; */
}

:deep(.airlineCode .v-field) {
  padding: 0 6px;
}
:deep(.airlineCode .v-field__input) {
  min-height: 30px;
  padding: 0 6px;
  font-size: 14px;
  font-weight: 500;
}

/* :deep(.dropdownSection .airlineCode input.v-text-field::placeholder) {
  font-size: 14px;
  font-weight: 500;
} */

/* Advanced Stops */
:deep(.advanceRadio .v-selection-control-group){
  flex-direction:column !important;
} 
:deep(.adcheckBox .v-selection-control--density-default){
  --v-selection-control-size: 30px !important;
}

:deep(.adcheckBox .v-input__control){
  height: 30px;
}

:deep(.adcheckBox .v-selection-control__input > .v-icon){
  font-size: 18px !important;
}

:deep(.adcheckBox .v-input__control .v-label){
  font-size: 14px;
  font-weight: 500;
}
:deep(.airlineCode .v-text-field .v-field--no-label input){
  top:0px !important;
}
:deep(.advanceRadio .v-icon--size-default){
  font-size:17px !important;
}
</style>

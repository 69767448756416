<template>
  <v-container style="padding: 10px 30px;" id="printMe">
    <!-- <v-container> -->

    <div>
      <div class="mt-4">
        <v-card class="mt-3 p-2">
          <div>
            <div class="row d-flex justify-content-between">

              <div class="col-lg-8 col-sm-6 col-7">
                <span class="Booking-id-head">{{ portal_sitetitle }} Booking ID:</span>
                <div class="d-flex flex-md-row flex-column align-items-md-center align-start mb-2">
                  <span class="Booking-id" style="text-transform: uppercase">{{ tripId }}</span>
                  <span class="d-flex align-items-center">
                    <v-icon class="ms-md-4 ms-1 me-sm-2 me-1" size="12px" :color="getColor()">mdi-circle</v-icon>
                    <span class="Booking-status">{{ bookingStatus }}</span>
                  </span>
                </div>
              </div>

              <div class="col-lg-4 col-sm-6 col-5">
                <div class="d-flex justify-end hide-btn-desk">
                  <div class="d-flex justify-end">
                    <a href="/" color="#002d5b" class="mx-2" style="font-size:14px !important">
                      Book Another Flight
                    </a>
                  </div>

                  <div class="d-flex justify-end">
                    <v-btn class="print-btn-1 ms-3 me-2" prepend-icon="mdi-printer" @click="seleceprint()">Print</v-btn>
                    <v-btn class="print-btn-mbl print-btn-2 mx-2" @click="seleceprint()">
                      <v-icon>mdi-printer</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>

            </div>

            <div class="row mt-1 justify-content-between">
              <div class="col-md-8">
                <h5>Your Booking Has Been Received!</h5>

                <p style="font-size: 14px">
                  Thank you for choosing our service! Your booking has been
                  received and the reservation is processing.<br />
                  Soon your tickets will be issued and sent to your email.
                </p>
              </div>


              <div class="col-md-4 d-flex flex-md-column flex-row justify-md-start justify-end" style="display:none !important;">

                <div class="mt-sm-2 mt-0 d-flex justify-end">
                  <v-btn @click="modify('dateChange')" width="100px" color="#002d5b" class="mx-2">
                    <span style="font-size:14px !important; font-weight: 500;">
                      Modify
                    </span>
                  </v-btn>
                </div>
                <div class="mt-sm-2 mt-0 d-flex justify-end">
                  <v-btn @click="modify('cancel')" width="100px" color="#002d5b" class="mx-2">
                    <span style="font-size:14px !important;font-weight: 500;">
                      Cancel
                    </span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>


          <!-- <div class="mt-2">
            <h5>Your Booking Has Been Received!</h5>

            <p style="font-size: 14px">
              Thank you for choosing our service! Your booking has been
              received and the reservation is processing.<br />
              Soon your tickets will be issued and sent to your email.
            </p>
          </div> -->

          <!-- <div class="mbl-Btn" v-if="mobilebtn">
            <div class="row d-flex justify-end show-btn-mbl">
              <div class="col-md-12 d-flex justify-end">
                <a href="/" color="#002d5b" class="me-3" style="font-size:14px !important">
                  Book Another Flight
                </a>

                <v-btn @click="modify()" color="#002d5b">
                  <span style="font-size:14px !important;font-weight: 500;">
                    Cancel
                  </span>
                </v-btn>

                <v-btn @click="modify()" color="#002d5b" class="mx-2">
                  <span style="font-size:14px !important; font-weight: 500;">
                    Modify
                  </span>
                </v-btn>
              </div>
            </div>
          </div> -->


        </v-card>
      </div>

      <div class="mt-3">
        <v-card class="p-2">
          <div v-if="rount" style="border: 1px solid grey; border-radius: 5px; padding: 5px">
            <div class="row align-center">
              <div class="col-lg-2 col-md-3 col-sm-6 col-6 my-sm-0 my-2 order-sm-1 order-2">
                <p class="m-0" style="font-size: 18px; font-weight: 500">
                  Trip ID #
                </p>
                <span>{{ tripId }}</span>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-6 col-6 my-sm-0 my-2 order-sm-2 order-3">
                <p class="m-0" style="font-size: 18px; font-weight: 500">
                  Booking Date
                </p>
                <span>{{ bookingTime }}</span>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-12 col-12 my-sm-0 my-2 order-sm-3 order-4" align="center">
                <div class="row">
                  <div class="col-6">
                    {{ getdepcode(depatureData) }}
                    <br />
                    {{ getstarttime(depatureData) }}
                  </div>
                  <div class="col-6">
                    {{ getarrcode(depatureData) }}
                    <br />
                    <span>{{
                      getstarttime1(arrivalData)
                    }}</span>
                  </div>
                </div>

                <!-- <p class="m-0 text-start" style="font-size: 18px; font-weight: 500">
                   {{ getdepcode(depatureData) }} -
                   {{ getarrcode(depatureData) }}
                 </p>
                 <p class="m-0 text-start">
                     {{ getstarttime(depatureData)}} 
                    <span v-if="datareurn">-{{ getstarttime1(arrivalData)}}</span>
                 </p> -->
              </div>

              <div class="col-lg-2 col-md-6 col-sm-6 col-12 my-sm-0 my-2 d-flex flex-md-column flex-row order-sm-4 order-4">
                <div class="d-flex">
                  <span style="margin-right: 10px">&#9679;</span>
                  <p class="m-0">{{ triptype }}</p>
                </div>
                <div class="d-flex ps-2 ps-sm-0">
                  <span style="margin-right: 10px">&#9679;</span>
                  <p class="m-0" style="text-transform: uppercase">
                    {{ searchcabintype }}
                  </p>
                </div>
              </div>

              <div class="col-lg-2 col-md-6 col-sm-6 order-sm-5 order-1">
                <v-img width="130px" :src="require('@/assets/buymytrip.png')" style="cursor: pointer" />
              </div>
            </div>
          </div>





          <div v-if="oneway" style="border: 1px solid grey; border-radius: 5px; padding: 5px">
            <div class="row align-center">
              <div class="col-md-2 col-6 my-sm-0 my-2 order-sm-1 order-2">
                <p class="m-0" style="font-size: 18px; font-weight: 500">
                  Trip ID #
                </p>
                <span>{{ tripId }}</span>
              </div>
              <div class="col-md-2 col-6 my-sm-0 my-2 order-sm-2 order-3">
                <p class="m-0" style="font-size: 18px; font-weight: 500">
                  Booking Date
                </p>
                <span>{{ bookingTime }}</span>
              </div>
              <div class="col-md-4 col-12 my-sm-0 my-2 order-sm-3 order-4" align="center">
                <div class="row">
                  <div class="col-6">
                    {{ getdepcode(depatureData) }}
                    <br />
                    {{ getstarttime(depatureData) }}
                  </div>
                  <div class="col-6">
                    {{ getarrcode(depatureData) }}
                    <br />
                    <span v-if="datareurn">{{
                      getstarttime1(arrivalData)
                    }}</span>
                  </div>
                </div>

                <!-- <p class="m-0 text-start" style="font-size: 18px; font-weight: 500">
                   {{ getdepcode(depatureData) }} -
                   {{ getarrcode(depatureData) }}
                 </p>
                 <p class="m-0 text-start">
                     {{ getstarttime(depatureData)}} 
                    <span v-if="datareurn">-{{ getstarttime1(arrivalData)}}</span>
                 </p> -->
              </div>

              <div class="col-md-2 col-12 my-sm-0 my-2 d-flex flex-sm-column flex-row order-sm-4 order-4">
                <div class="d-flex">
                  <span style="margin-right: 10px">&#9679;</span>
                  <p class="m-0">{{ triptype }}</p>
                </div>
                <div class="d-flex ps-2 ps-sm-0">
                  <span style="margin-right: 10px">&#9679;</span>
                  <p class="m-0" style="text-transform: uppercase">
                    {{ searchcabintype }}
                  </p>
                </div>
              </div>

              <div class="col-md-2 order-sm-5 order-1">
                <v-img width="130px" :src="require('@/assets/buymytrip.png')" style="cursor: pointer" />
              </div>
            </div>









          </div>


          <div v-if="multi" style="border: 1px solid grey; border-radius: 5px; padding: 5px">
            <div class="row align-center">
              <div class="col-md-2 col-6 my-sm-0 my-2 order-sm-1 order-2">
                <p class="m-0" style="font-size: 18px; font-weight: 500">
                  Trip ID #
                </p>
                <span>{{ tripId }}</span>
              </div>
              <div class="col-md-2 col-6 my-sm-0 my-2 order-sm-2 order-3">
                <p class="m-0" style="font-size: 18px; font-weight: 500">
                  Booking Date
                </p>
                <span>{{ bookingTime }}</span>
              </div>
              <div class="col-md-4 col-12 my-sm-0 my-2 order-sm-3 order-4" align="center">
                <div class="row" v-if="Flight1.length > 0">

                  <div class="col-12">

                    <span style="font-size: 18px; font-weight: 500">
                      {{ multigetdepcode(Flight1) }}
                    </span>
                    <span style="font-size: 18px; font-weight: 500">
                      - {{ multigetarrcode(Flight1) }}
                    </span>
                    <span>
                      - {{ multigetdeptime(Flight1) }}
                    </span>
                  </div>

                </div>

                <div class="row" v-if="Flight2.length > 0">

                  <div class="col-12">

                    <span style="font-size: 18px; font-weight: 500">
                      {{ multigetdepcode(Flight2) }}
                    </span>
                    <span style="font-size: 18px; font-weight: 500">
                      - {{ multigetarrcode(Flight2) }}
                    </span>
                    <span>
                      - {{ multigetdeptime(Flight2) }}
                    </span>
                  </div>

                </div>


                <div class="row" v-if="Flight3.length > 0">

                  <div class="col-12">

                    <span style="font-size: 18px; font-weight: 500">
                      {{ multigetdepcode(Flight3) }}
                    </span>
                    <span style="font-size: 18px; font-weight: 500">
                      - {{ multigetarrcode(Flight3) }}
                    </span>
                    <span>
                      - {{ multigetdeptime(Flight3) }}
                    </span>
                  </div>

                </div>



                <div class="row" v-if="Flight4.length > 0">

                  <div class="col-12">

                    <span style="font-size: 18px; font-weight: 500">
                      {{ multigetdepcode(Flight4) }}
                    </span>
                    <span style="font-size: 18px; font-weight: 500">
                      - {{ multigetarrcode(Flight4) }}
                    </span>
                    <span>
                      - {{ multigetdeptime(Flight4) }}
                    </span>
                  </div>

                </div>









              </div>

              <div class="col-md-2 col-12 my-sm-0 my-2 d-flex flex-sm-column flex-row order-sm-4 order-4">
                <div class="d-flex">
                  <span style="margin-right: 10px">&#9679;</span>
                  <p class="m-0">{{ triptype }}</p>
                </div>
                <div class="d-flex ps-2 ps-sm-0">
                  <span style="margin-right: 10px">&#9679;</span>
                  <p class="m-0" style="text-transform: uppercase">
                    {{ searchcabintype }}
                  </p>
                </div>
              </div>

              <div class="col-md-2 order-sm-5 order-1">
                <v-img width="130px" :src="require('@/assets/buymytrip.png')" style="cursor: pointer" />
              </div>
            </div>


          </div>

        </v-card>

        <v-card class="p-2 mt-3">
          <div>
            <div>
              <div class="row mt-2">
                <h5 class="col-12 col-md-6">
                  <v-icon color="grey">mdi-ticket</v-icon>
                  ITINERARY
                </h5>
              </div>

              <v-table class="text-center rounded desktop-table" style="border: 1px solid lightgray">
                <thead>
                  <tr>
                    <th class="text-center text-dark">PASSENGER NAME</th>
                    <th class="text-center text-dark">GENDER</th>
                    <th class="text-center text-dark">DOB</th>
                    <th class="text-center text-dark">PAX TYPE</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(datas1, index) of passengerDetail" :key="index">
                    <td class="text-center text-dark f-family" style="text-transform: uppercase">
                      <span style="text-transform:capitalize"> {{ datas1.salutation }}</span>
                      {{ datas1.firstname }}
                      {{ datas1.middlename }} {{ datas1.lastname }}

                    </td>
                    <td class="text-center text-dark f-family" style="text-transform: uppercase">
                      {{ datas1.gender }}
                    </td>
                    <td class="text-center text-dark f-family">
                      {{ getdob(datas1.dob) }}
                    </td>
                    <td class="text-center text-dark f-family" style="text-transform: uppercase">
                      {{ datas1.pax_type }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </div>

          <div class="mt-3" style="margin-left: 35px">
            <h5>Contact Info</h5>
            <div class="row mt-3">
              <div class="col-md-4">
                <span class="fw-600">Phone : </span>
                <span class="f-family">{{ countrycode }} {{ contactnum }}</span>
              </div>
              <div class="col-md-4">
                <span class="fw-600">Email : </span>
                <span class="f-family"> {{ passEmail }}</span>
              </div>
            </div>
          </div>

          <div>
            <div>
              <v-card class="mt-2 px-2" style="box-shadow: none" v-if="depatureData.length > 0 ? true : false">
                <hr class="mb-sm-4 mb-0 mt-0" style="margin-bottom: 0px !important" />
                <div class="row d-flex justify-content-between align-center pt-2 pb-2">
                  <div class="col-md-4 col-6">
                    <h6 class="m-0" style="font-size: 18px">
                      <v-icon color="grey" style="font-size: 24px;transform: rotate(90deg) !important;">
                        mdi-airplane
                      </v-icon>
                      <span class="ms-2"> {{ getdepcode(depatureData) }} </span>-
                      {{ getarrcode(depatureData) }}
                      <span style="font-size: 15px">

                      </span>
                    </h6>
                  </div>
                  <div class="col-md-6 col-6 d-flex align-center px-sm-3 px-0">

                    <v-icon class="me-1" color="grey">mdi-calendar</v-icon>
                    <h6 class="m-0">{{ getstarttime(depatureData) }}</h6>
                  </div>
                </div>
                <hr class="mb-0 mt-sm-4 mt-0" style="margin-top: 0px !important" />
                <v-container style="max-width: 100%">
                  <div v-for="(data, index) of depatureData" :key="index">
                    <div class="row mt-1 g-0 mb-1">
                      <div class="col-md-4 col-3 mb-1 mb-md-0">
                        <div class="row align-end w-100">
                          <div class="col-md-4 col-12 airline-logo">
                            <v-img :src="data.logo" class="airlogo-size" width="40px"></v-img>
                          </div>
                          <div class="col-md-8 col-12 airline-profile">
                            <p class="f-family airline-name m-0">
                              <span class="f-family airline-name">{{
                                data.flightname
                              }}</span>
                              <br />
                              <span class="f-family airline-name airline-id">
                                {{ data.carrier }} -
                                {{ data.flight_number }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-9">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-family airline-date">
                              {{ getdepdate(data.depart_datetime) }}
                            </span>
                            <span class="f-family airline-time" style="margin-left: 5px">
                              {{ getdeptime(data.depart_datetime) }}
                            </span>
                          </div>
                          <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                            <span class="fw-500">
                              {{ data.depart_airport }} </span>,
                            {{ data.depAirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-family airline-date">
                              {{ getarrdate(data.arrival_datetime) }}
                            </span>
                            <span class="f-family airline-time" style="margin-left: 5px">
                              {{ getarrtime(data.arrival_datetime) }}
                            </span>
                          </div>

                          <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                            <span class="fw-500">{{
                              data.arrival_airport
                            }}</span>,

                            {{ data.arrAirportName }}
                          </div>
                        </div>
                      </div>


                    </div>

                    <div class="d-flex position-relative">
                      <hr v-if="depatureData.length - 1 !== index" style="
                         width: 100%;
                         position: absolute;
                         margin-top: 8px;
                         margin-bottom: 0px;
                       " />
                      <div class="d-flex justify-center" v-if="depatureData.length - 1 !== index" style="
                         width: 180px;
                         background: antiquewhite;
                         border-radius: 18px;
                         z-index: 1;
                         margin: auto;
                       ">
                        <div class="d-flex align-center">
                          <span class="f-size-10">{{ data.overlayTime }} Layover Time</span>
                        </div>
                      </div>
                    </div>



                  </div>
                </v-container>
              </v-card>
            </div>



            <div class="mt-1" v-if="rount">
              <v-card class="mt-1 px-2" style="box-shadow: none" v-if="arrivalData.length !== 0">
                <hr class="mb-sm-4 mb-0 mt-0" style="margin-bottom: 0px !important" />
                <div class="row d-flex justify-content-between align-center pt-2 pb-2">
                  <div class="col-md-4 col-6">
                    <h6 class="m-0" style="font-size: 18px">
                      <v-icon color="grey" style="
                              font-size: 24px;
                              transform: rotate(90deg) !important;
                            ">
                        mdi-airplane
                      </v-icon>
                      <span class="ms-2"> {{ getdepcode1(arrivalData) }} </span>-
                      {{ getarrcode1(arrivalData) }}
                      <!-- <span style="font-size: 15px">
                            ( {{ data.Depaturejourney.Time }},
                       {{ getStop(data.Depature) }} Stops )
                          </span> -->
                    </h6>
                  </div>
                  <div class="col-md-6 col-6 d-flex align-center px-sm-3 px-0">

                    <v-icon class="me-1" color="grey">mdi-calendar</v-icon>
                    <h6 class="m-0">{{ getstarttime1(arrivalData) }}</h6>
                  </div>
                </div>
                <hr class="mb-0 mt-sm-4 mt-0" style="margin-top: 0px !important" />
                <v-container style="max-width: 100%">
                  <div v-for="(data, index) of arrivalData" :key="index">
                    <div class="row mt-3 g-0 mb-2">
                      <div class="col-md-4 col-3 mb-3 mb-md-0">
                        <div class="row align-end w-100">
                          <div class="col-md-4 col-12 airline-logo">
                            <v-img :src="data.logo" class="airlogo-size" width="40px"></v-img>
                          </div>
                          <div class="col-md-8 col-12 airline-profile">
                            <p class="f-family airline-name m-0">
                              <span class="f-family airline-name">{{
                                data.flightname
                              }}</span>
                              <br />
                              <span class="f-family airline-name airline-id">
                                {{ data.carrier }} -
                                {{ data.flight_number }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-9">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-family airline-date">
                              {{ getdepdate1(data.depart_datetime) }}
                            </span>
                            <span class="f-family airline-time" style="margin-left: 5px">
                              {{ getdeptime1(data.depart_datetime) }}
                            </span>
                          </div>
                          <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                            <span class="fw-500">
                              {{ data.depart_airport }} </span>,
                            {{ data.depAirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-family airline-date">
                              {{ getarrdate1(data.arrival_datetime) }}
                            </span>
                            <span class="f-family airline-time" style="margin-left: 5px">
                              {{ getarrtime1(data.arrival_datetime) }}
                            </span>
                          </div>

                          <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                            <span class="fw-500">{{
                              data.arrival_airport
                            }}</span>,

                            {{ data.arrAirportName }}
                          </div>
                        </div>
                      </div>

                      <!-- <div
                            class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box"
                          >
                            <div class="d-flex align-center class-type">
                              <v-icon size="15px" color="#ccc">
                                mdi-seat-passenger
                              </v-icon>

                              <span
                                class="f-family"
                                style="
                                  padding-left: 5px;
                                  text-transform: uppercase;
                                "
                              >
                                {{ data.cabin_type }}
                              </span>
                            </div>
                          </div> -->
                    </div>

                    <div class="d-flex position-relative">
                      <hr v-if="arrivalData.length - 1 !== index" style="
                         width: 100%;
                         position: absolute;
                         margin-top: 8px;
                         margin-bottom: 0px;
                       " />
                      <div class="d-flex justify-center" v-if="arrivalData.length - 1 !== index" style="
                         width: 180px;
                         background: antiquewhite;
                         border-radius: 18px;
                         z-index: 1;
                         margin: auto;
                       ">
                        <div class="d-flex align-center">
                          <span class="f-size-10">{{ data.overlayTime }} Layover Time</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-container>
              </v-card>
            </div>



            <div>
              <v-card class="mt-2 px-2" style="box-shadow: none" v-if="multi">
                <hr class="mb-sm-4 mb-0 mt-0" style="margin-bottom: 0px !important" />

                <div v-if="Flight1.length > 0">


                  <div class="row d-flex justify-content-between align-center pt-2 pb-2">
                    <div class="col-md-4 col-12">
                      <h6 class="m-0" style="font-size: 18px">
                        <v-icon color="grey" style="
                              font-size: 24px;
                              transform: rotate(90deg) !important;
                            ">
                          mdi-airplane
                        </v-icon>
                        <span class="ms-2"> {{ multigetdepcode(Flight1) }} </span>-
                        {{ multigetarrcode(Flight1) }}
                        <span style="font-size: 15px">

                        </span>
                      </h6>
                    </div>
                    <div class="col-md-6 col-12 mt-1 mt-sm-0">
                      <h6 class="m-0">
                        <span>
                          <v-icon class="me-1" color="grey">mdi-calendar</v-icon></span>
                        {{ multigetdeptime(Flight1) }}
                      </h6>
                    </div>
                  </div>
                  <hr class="mb-0 mt-sm-4 mt-0" style="margin-top: 0px !important" />
                  <v-container style="max-width: 100%">
                    <div v-for="(data, index) of Flight1" :key="index">
                      <div class="row mt-1 g-0 mb-1">
                        <div class="col-md-4 col-3 mb-1 mb-md-0">
                          <div class="row align-end w-100">
                            <div class="col-md-4 col-12 airline-logo">
                              <v-img :src="data.logo" class="airlogo-size" width="40px"></v-img>
                            </div>
                            <div class="col-md-8 col-12 airline-profile">
                              <p class="f-family airline-name m-0">
                                <span class="f-family airline-name">
                                  {{
                                    data.flightname
                                  }}
                                </span>
                                <br />
                                <span class="f-family airline-name airline-id">
                                  {{ data.carrier }} -
                                  {{ data.flight_number }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-9">
                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-family airline-date">
                                {{ getdepdate(data.depart_datetime) }}
                              </span>
                              <span class="f-family airline-time" style="margin-left: 5px">
                                {{ getdeptime(data.depart_datetime) }}
                              </span>
                            </div>
                            <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">
                                {{ data.depart_airport }} </span>,
                              {{ data.depAirportName }}
                            </div>
                          </div>

                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-family airline-date">
                                {{ getarrdate(data.arrival_datetime) }}
                              </span>
                              <span class="f-family airline-time" style="margin-left: 5px">
                                {{ getarrtime(data.arrival_datetime) }}
                              </span>
                            </div>

                            <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">{{
                                data.arrival_airport
                              }}</span>,

                              {{ data.arrAirportName }}
                            </div>
                          </div>
                        </div>


                      </div>

                      <div class="d-flex position-relative">
                        <hr v-if="depatureData.length - 1 !== index" style="
                         width: 100%;
                         position: absolute;
                         margin-top: 8px;
                         margin-bottom: 0px;
                       " />
                        <div class="d-flex justify-center" v-if="Flight1.length - 1 !== index" style="
                         width: 180px;
                         background: antiquewhite;
                         border-radius: 18px;
                         z-index: 1;
                         margin: auto;
                       ">
                          <div class="d-flex align-center">
                            <span class="f-size-10">{{ data.overlayTime }} Layover Time</span>
                          </div>
                        </div>
                      </div>



                    </div>
                  </v-container>




                </div>




                <div v-if="Flight2.length > 0">


                  <div class="row d-flex justify-content-between align-center pt-2 pb-2">
                    <div class="col-md-4 col-12">
                      <h6 class="m-0" style="font-size: 18px">
                        <v-icon color="grey" style="
                              font-size: 24px;
                              transform: rotate(90deg) !important;
                            ">
                          mdi-airplane
                        </v-icon>
                        <span class="ms-2"> {{ multigetdepcode(Flight2) }} </span>-
                        {{ multigetarrcode(Flight2) }}
                        <span style="font-size: 15px">

                        </span>
                      </h6>
                    </div>
                    <div class="col-md-6 col-12 mt-1 mt-sm-0">
                      <h6 class="m-0">
                        <span>
                          <v-icon class="me-1" color="grey">mdi-calendar</v-icon></span>
                        {{ multigetdeptime(Flight2) }}
                      </h6>
                    </div>
                  </div>
                  <hr class="mb-0 mt-sm-4 mt-0" style="margin-top: 0px !important" />
                  <v-container style="max-width: 100%">
                    <div v-for="(data, index) of Flight2" :key="index">
                      <div class="row mt-1 g-0 mb-1">
                        <div class="col-md-4 col-3 mb-1 mb-md-0">
                          <div class="row align-end w-100">
                            <div class="col-md-4 col-12 airline-logo">
                              <v-img :src="data.logo" class="airlogo-size" width="40px"></v-img>
                            </div>
                            <div class="col-md-8 col-12 airline-profile">
                              <p class="f-family airline-name m-0">
                                <span class="f-family airline-name">
                                  {{
                                    data.flightname
                                  }}
                                </span>
                                <br />
                                <span class="f-family airline-name airline-id">
                                  {{ data.carrier }} -
                                  {{ data.flight_number }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-9">
                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-family airline-date">
                                {{ getdepdate(data.depart_datetime) }}
                              </span>
                              <span class="f-family airline-time" style="margin-left: 5px">
                                {{ getdeptime(data.depart_datetime) }}
                              </span>
                            </div>
                            <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">
                                {{ data.depart_airport }} </span>,
                              {{ data.depAirportName }}
                            </div>
                          </div>

                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-family airline-date">
                                {{ getarrdate(data.arrival_datetime) }}
                              </span>
                              <span class="f-family airline-time" style="margin-left: 5px">
                                {{ getarrtime(data.arrival_datetime) }}
                              </span>
                            </div>

                            <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">{{
                                data.arrival_airport
                              }}</span>,

                              {{ data.arrAirportName }}
                            </div>
                          </div>
                        </div>


                      </div>

                      <div class="d-flex position-relative">
                        <hr v-if="depatureData.length - 1 !== index" style="
                         width: 100%;
                         position: absolute;
                         margin-top: 8px;
                         margin-bottom: 0px;
                       " />
                        <div class="d-flex justify-center" v-if="Flight2.length - 1 !== index" style="
                         width: 180px;
                         background: antiquewhite;
                         border-radius: 18px;
                         z-index: 1;
                         margin: auto;
                       ">
                          <div class="d-flex align-center">
                            <span class="f-size-10">{{ data.overlayTime }} Layover Time</span>
                          </div>
                        </div>
                      </div>



                    </div>
                  </v-container>




                </div>




                <div v-if="Flight3.length > 0">


                  <div class="row d-flex justify-content-between align-center pt-2 pb-2">
                    <div class="col-md-4 col-12">
                      <h6 class="m-0" style="font-size: 18px">
                        <v-icon color="grey" style="
                              font-size: 24px;
                              transform: rotate(90deg) !important;
                            ">
                          mdi-airplane
                        </v-icon>
                        <span class="ms-2"> {{ multigetdepcode(Flight3) }} </span>-
                        {{ multigetarrcode(Flight3) }}
                        <span style="font-size: 15px">

                        </span>
                      </h6>
                    </div>
                    <div class="col-md-6 col-12 mt-1 mt-sm-0">
                      <h6 class="m-0">
                        <span>
                          <v-icon class="me-1" color="grey">mdi-calendar</v-icon></span>
                        {{ multigetdeptime(Flight3) }}
                      </h6>
                    </div>
                  </div>
                  <hr class="mb-0 mt-sm-4 mt-0" style="margin-top: 0px !important" />
                  <v-container style="max-width: 100%">
                    <div v-for="(data, index) of Flight3" :key="index">
                      <div class="row mt-1 g-0 mb-1">
                        <div class="col-md-4 col-3 mb-1 mb-md-0">
                          <div class="row align-end w-100">
                            <div class="col-md-4 col-12 airline-logo">
                              <v-img :src="data.logo" class="airlogo-size" width="40px"></v-img>
                            </div>
                            <div class="col-md-8 col-12 airline-profile">
                              <p class="f-family airline-name m-0">
                                <span class="f-family airline-name">
                                  {{
                                    data.flightname
                                  }}
                                </span>
                                <br />
                                <span class="f-family airline-name airline-id">
                                  {{ data.carrier }} -
                                  {{ data.flight_number }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-9">
                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-family airline-date">
                                {{ getdepdate(data.depart_datetime) }}
                              </span>
                              <span class="f-family airline-time" style="margin-left: 5px">
                                {{ getdeptime(data.depart_datetime) }}
                              </span>
                            </div>
                            <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">
                                {{ data.depart_airport }} </span>,
                              {{ data.depAirportName }}
                            </div>
                          </div>

                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-family airline-date">
                                {{ getarrdate(data.arrival_datetime) }}
                              </span>
                              <span class="f-family airline-time" style="margin-left: 5px">
                                {{ getarrtime(data.arrival_datetime) }}
                              </span>
                            </div>

                            <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">{{
                                data.arrival_airport
                              }}</span>,

                              {{ data.arrAirportName }}
                            </div>
                          </div>
                        </div>


                      </div>

                      <div class="d-flex position-relative">
                        <hr v-if="depatureData.length - 1 !== index" style="
                         width: 100%;
                         position: absolute;
                         margin-top: 8px;
                         margin-bottom: 0px;
                       " />
                        <div class="d-flex justify-center" v-if="Flight3.length - 1 !== index" style="
                         width: 180px;
                         background: antiquewhite;
                         border-radius: 18px;
                         z-index: 1;
                         margin: auto;
                       ">
                          <div class="d-flex align-center">
                            <span class="f-size-10">{{ data.overlayTime }} Layover Time</span>
                          </div>
                        </div>
                      </div>



                    </div>
                  </v-container>




                </div>





                <div v-if="Flight4.length > 0">


                  <div class="row d-flex justify-content-between align-center pt-2 pb-2">
                    <div class="col-md-4 col-12">
                      <h6 class="m-0" style="font-size: 18px">
                        <v-icon color="grey" style="
                              font-size: 24px;
                              transform: rotate(90deg) !important;
                            ">
                          mdi-airplane
                        </v-icon>
                        <span class="ms-2"> {{ multigetdepcode(Flight4) }} </span>-
                        {{ multigetarrcode(Flight4) }}
                        <span style="font-size: 15px">

                        </span>
                      </h6>
                    </div>
                    <div class="col-md-6 col-12 mt-1 mt-sm-0">
                      <h6 class="m-0">
                        <span>
                          <v-icon class="me-1" color="grey">mdi-calendar</v-icon></span>
                        {{ multigetdeptime(Flight4) }}
                      </h6>
                    </div>
                  </div>
                  <hr class="mb-0 mt-sm-4 mt-0" style="margin-top: 0px !important" />
                  <v-container style="max-width: 100%">
                    <div v-for="(data, index) of Flight4" :key="index">
                      <div class="row mt-1 g-0 mb-1">
                        <div class="col-md-4 col-3 mb-1 mb-md-0">
                          <div class="row align-end w-100">
                            <div class="col-md-4 col-12 airline-logo">
                              <v-img :src="data.logo" class="airlogo-size" width="40px"></v-img>
                            </div>
                            <div class="col-md-8 col-12 airline-profile">
                              <p class="f-family airline-name m-0">
                                <span class="f-family airline-name">
                                  {{
                                    data.flightname
                                  }}
                                </span>
                                <br />
                                <span class="f-family airline-name airline-id">
                                  {{ data.carrier }} -
                                  {{ data.flight_number }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6 col-9">
                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-family airline-date">
                                {{ getdepdate(data.depart_datetime) }}
                              </span>
                              <span class="f-family airline-time" style="margin-left: 5px">
                                {{ getdeptime(data.depart_datetime) }}
                              </span>
                            </div>
                            <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">
                                {{ data.depart_airport }} </span>,
                              {{ data.depAirportName }}
                            </div>
                          </div>

                          <div class="row d-flex">
                            <div class="col-sm-3 p-0">
                              <span class="f-family airline-date">
                                {{ getarrdate(data.arrival_datetime) }}
                              </span>
                              <span class="f-family airline-time" style="margin-left: 5px">
                                {{ getarrtime(data.arrival_datetime) }}
                              </span>
                            </div>

                            <div class="col-sm-9 p-0 f-family aircode-name ps-md-2 ps-0" style="margin-bottom: 4px">
                              <span class="fw-500">{{
                                data.arrival_airport
                              }}</span>,

                              {{ data.arrAirportName }}
                            </div>
                          </div>
                        </div>


                      </div>

                      <div class="d-flex position-relative">
                        <hr v-if="depatureData.length - 1 !== index" style="
                         width: 100%;
                         position: absolute;
                         margin-top: 8px;
                         margin-bottom: 0px;
                       " />
                        <div class="d-flex justify-center" v-if="Flight4.length - 1 !== index" style="
                         width: 180px;
                         background: antiquewhite;
                         border-radius: 18px;
                         z-index: 1;
                         margin: auto;
                       ">
                          <div class="d-flex align-center">
                            <span class="f-size-10">{{ data.overlayTime }} Layover Time</span>
                          </div>
                        </div>
                      </div>



                    </div>
                  </v-container>




                </div>





              </v-card>
            </div>




          </div>
        </v-card>
      </div>

      <!-- <div class="mt-3">
         <v-card class="px-2">
 
         </v-card>
       </div> -->

      <div class="mt-1">
        <v-card class="p-2">
          <div>
            <div>
              <div class="row mt-1">
                <h5 class="col-12 col-md-6">
                  <v-icon color="grey">mdi-cash-multiple</v-icon>
                  FARE SUMMARY
                </h5>
              </div>

              <div class="mt-1">
                <v-card class="m-2 px-2">
                  <div class="row mt-3">
                    <div class="col-md-4 d-flex">
                      <span class="fw-bold f-family">Ticket Price per person:
                      </span>
                      <span class="ps-2 f-family">
                        {{ currency }} {{ baseprice }}</span>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-4 d-flex">
                      <h5>Total Price:</h5>
                      <h5 style="margin-left: 5px">
                        {{ currency }} {{ totalprice }}
                      </h5>
                    </div>
                  </div>
                </v-card>
              </div>
            </div>
          </div>
        </v-card>
        <div class="mt-4">
          <v-card class="p-3">
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <h6>TERMS & CONDITIONS</h6>
              </div>
              <div class="col-sm-6 col-md-6 contion">
                <a href="#" style="font-size: 13px">
                  Show Tearms & Conditions</a>
              </div>
            </div>

            <div style="
                    border: 1px solid grey;
                    padding: 10px;
                    border-radius: 10px;
                    font-size:10px;
                  " class="BuyMyTrip">
              <p>
                BuyMyTrip offers you, our customer, access to and use of our
                site subject to your acceptance without modification of the
                Terms and Conditions contained herein. By using or obtaining
                any content, materials, data, information, product
                BuyMyTrip, or services through our site, you agree to the
                following Terms:
              </p>
              <p>
                Unaccompanied Minors: Children age 5 through 14 years may be
                required to use the airline's Unaccompanied Minor service.
                Additionally, you may be required to pay a fee to use this
                service, which is usually charged at check-in with specific
                airlines. Unaccompanied minor policies and fees vary by
                airline; please check with your airline for specific
                policies and fees.
              </p>

              <p>
                Airline Fees: We reserve the right to charge a processing
                fee for issuance of your airline ticket, car rental, hotel
                reservation, cruise, or tour booking.
              </p>
              <p>
                EXCURSION AND PROMOTIONAL FARES. Most discount fares involve
                restrictions. A change in carrier(s), flight(s), time(s), or
                routing(s) could result in a carrier demanding a full fare.
                Obtain agency or airline assistance before making changes.
                If your plans change enroute, apply the value of your
                tickets toward a re-issued one. It is not necessary to
                purchase a new ticket. If a refund is due, obtain a receipt
                from the airline. Departure times, flights and fares are
                based on current tariffs that are subject to change without
                notice.
              </p>

              <p>
                Changes, Voids, Cancellations: BuyMyTrip This is in addition
                to the airlines'exchange fees. The airline rules will be
                quoted to the customer before initiating an exchange.
                Additionally, some airline tickets issued are non-refundable
                and nonexchangeable. There may also be a fee assessed in the
                event of a refund or cancellation of the previously above
                stated, imposed by the Travel Supplier. Refunds from
                suppliers will be made to our customers only after we have
                received the money from those sources (supplier rules and
                restrictions will apply).
              </p>
              <p>
                LIMITATION. You may use our web site for personal and
                non-commercial purposes only. You may not modify, copy,
                distribute, transmit, display, reproduce, publish, license,
                create derivative works from, transfer, upload, post or sell
                any information, software, product BuyMyTrip, or services
                obtained from this web site. Trademarks, logos and service
                marks displayed on our site are ours, and our suppliers'
                registered and common law marks. Use of and access to our
                site does not grant you any license or right to use any of
                the trademarks.
              </p>
              <p>
                LIABILITY DISCLAIMER. ALL INFORMATION, CONTENT, DATA,
                MATERIALS, Product BuyMyTrip, AND SERVICES ARE PROVIDED ON
                AN 'AS IS' BASIS. BuyMyTrip AND ITS SUPPLIERS MAKE NO
                WARRANTY OF ANY KIND REGARDING OUR SITE. ALL INFORMATION,
                PRICES, AND AVAILABILITY ARE SUBJECT TO CHANGE WITHOUT PRIOR
                NOTICE. WE EXPRESSLY DISCLAIM ANY REPRESENTATION OR WARRANTY
                THAT OUR SITE WILL BE ERROR-FREE, OR UNINTERRUPTED. ANY
                WARRANTY AS TO THE ACCURACY, COMPLETENESS AND TIMELINESS OF
                CONTENT ON OUR SITE IS FURTHER DISCLAIMED. WE SHALL NOT BE
                RESPONSIBLE FOR BREACH OF CONTRACT OF ANY INTENTIONAL OR
                CARELESS ACTIONS OR OMISSIONS ON THE PART OF SUCH SUPPLIERS,
                WHICH RESULT IN ANY LOSS, DAMAGE, DELAY, OR INJURY TO YOU OR
                YOUR TRAVELING COMPANIONS OR GROUP MEMBERS. UNLESS THE TERM
                'GUARANTEED' IS SPECIFICALLY STATED IN WRITING ON YOUR
                TICKETS, INVOICE, OR RESERVATION ITINERARY, WE DO NOT
                GUARANTEE ANY OF SUCH SUPPLIERS' RATES, BOOKINGS,
                RESERVATIONS, CONNECTIONS, SCHEDULING, OR HANDLING OF
                PERSONAL EFFEBuyMyTrip AND LUGGAGE. WE SHALL NOT BE
                RESPONSIBLE FOR ANY INJURIES, DAMAGES, OR LOSSES CAUSED TO
                ANY TRAVELER IN CONNECTION WITH TERRORIST ACTIVITIES, SOCIAL
                OR LABOR UNREST, MECHANICAL OR CONSTRUCTION FAILURES OR
                DIFFICULTIES, DISEASES, LOCAL LAWS, CLIMATIC CONDITIONS,
                ABNORMAL CONDITIONS OR DEVELOPMENTS, OR ANY OTHER ACTIONS,
                OMISSIONS, OR CONDITIONS OUTSIDE OUR CONTROL. THE TRAVELER
                ASSUMES COMPLETE AND FULL RESPONSIBILITY FOR, AND HEREBY
                RELEASES THE AGENT FROM, ANY DUTY OF CHECKING AND VERIFYING
                ANY AND ALL PASSPORT, VISA, VACCINATION, OR ANY OTHER ENTRY
                REQUIREMENTS FOR EACH DESTINATION, AND ALL SAFETY AND
                SECURITY CONDITIONS OF SUCH DESTINATIONS, DURING THE LENGTH
                OF THE PROPOSED TRAVEL. FOR INFORMATION CONCERNING POSSIBLE
                DANGERS AT INTERNATIONAL DESTINATIONS, CONTACT THE TRAVEL
                ADVISORY SECTION OF THE U.S. STATE DEPARTMENT AT
                202.647.5225. FOR MEDICAL INFORMATION, CALL THE US CENTER
                FOR DISEASE AT 404.332.4559 OR VISIT THEIR WEBSITE,
                www.cdc.gov. BY EMBARKING UPON HIS/HER TRAVEL, THE TRAVELER
                VOLUNTARILY ASSUMES ALL RISKS INVOLVED IN SUCH TRAVEL,
                WHETHER EXPECTED OR UNEXPECTED. THE TRAVELER IS HEREBY
                WARNED OF THE ABOVE RISKS, AS WELL AS POSSIBLE TRAVEL
                INDUSTRY BANKRUPTCIES AND MEDICAL AND CLIMATIC DISRUPTIONS,
                AND IS ADVISED TO OBTAIN APPROPRIATE INSURANCE COVERAGE
                AGAINST THEM, WHICH IS AVAILABLE AT AN EXTRA COST THROUGH
                BuyMyTrip . THE TRAVELER'S RETENTION OF TICKETS,
                RESERVATIONS, TOUR DOCUMENTS, CRUISE DOCUMENTS, TRAVEL
                AGENCY INVOICE OR BOOKINGS AFTER ISSUANCE SHALL CONSTITUTE A
                CONSENT TO THE ABOVE AND AN AGREEMENT ON HIS/HER PART TO
                CONVEY THE CONTENTS HERETO TO HIS/HER TRAVEL COMPANIONS OR
                GROUP TRAVEL MEMBERS.
              </p>
              <p>
                LINKS. Our site contains links to other sites, which we do
                not control or operate. These links are provided for your
                convenience and reference. You are free to access these
                other sites, but you do so at your own risk.
              </p>
              <p>
                PRIVACY. Use of our site is subject to our Privacy Policy.
                Acceptance of our Terms and Conditions warrants acceptance
                of our Privacy Policy.
              </p>

              <p>
                IMPORTANT. Please check your travel documents when you
                receive them. You may call us at Phone: +1 6592512000 Toll
                Free with any questions.
              </p>
              <p>
                CHECK-IN. Minimum check-in time for domestic flights is 1 to
                1 1/2 hours; 2 hours for international flights. A Picture
                I.D. is required for all domestic flights. Passports and
                visas (when required) are mandatory for all international
                flights.
              </p>
              <p>
                RECONFIRMATION. Domestic flights should be reconfirmed in
                cases of inclement weather situations or if a flight was
                booked some length of time prior to departure and
                international flights at least 72 hours prior to departure.
                . Failure to use any reservations may result in automatic
                cancellation of all continuing and return reservations.
              </p>
              <p>
                EXCURSION AND PROMOTIONAL FARES. Most discount fares involve
                restrictions. A change in carrier(s), flight(s), time(s), or
                routing(s) could result in a carrier demanding a full fare.
                Obtain agency or airline assistance before making changes.
                If your plans change enroute, apply the value of your
                tickets toward a re-issued one. It is not necessary to
                purchase a new ticket. If a refund is due, obtain a receipt
                from the airline. Departure times, flights and fares are
                based on current tariffs that are subject to change without
                notice.
              </p>
              <p>
                TICKETS. Canceled or unused tickets (paper) must be returned
                for proper credit to your account (airline rules and
                restrictions will apply). Unused E-tickets should be
                reported to BuyMyTrip to be logged and used toward future
                travel. Lost, stolen or destroyed tickets (paper) must be
                paid for until refund is received from the issuing carrier,
                subject to an airline imposed service charge
              </p>
              <p>
                HOTELS are usually confirmed on a guaranteed payment basis.
                If you cancel or change plans, please notify your hotel(s)
                within the time period specified. No refunds will be issued
                for late check-in or early check-out. Stay extensions
                require a new reservation. Resort fee is applicable subject
                to hotels discretion. The price shown DOES NOT include any
                applicable hotel service fees, charges for optional
                incidentals (such as minibar, snacks, resort fee, parking
                fee, valet fee or telephone calls), or regulatory
                surcharges. The lodging will assess these fees, charges, and
                surcharges upon check-out. This hotel requires that you are
                at least 21 to check in. Base rates are displayed as per the
                number of occupancy mentioned during search. Unless
                specified otherwise, rates are quoted in US dollars. In case
                of late arrival, you need to notify the hotel prior to your
                check in time. Your credit card will be charged for the
                complete payment at the time of booking. Prices and room
                availability are not guaranteed until full payment is
                received. In case of credit card verification failure the
                reservation will be cancelled without any prior notification
              </p>
            </div>
          </v-card>
        </div>
        <div class="d-flex justify-center my-5">
          <v-btn style="
                  color: green;
                  outline: 1px solid;
                  width: 200px;
                  height: 45px;
                  border-radius: 4px;
                " class="header-btn" prepend-icon="mdi-printer" @click="seleceprint()">Print</v-btn>
        </div>
      </div>
    </div>
    <!-- </v-container> -->
  </v-container>



  <v-dialog v-model="dialog1">
    <div>
      <div class="trip1">
        <v-card style="padding: 50px; margin-top: 50px; overflow: unset" width="600px" rounded="3">
          <div class="d-flex justify-end">
            <v-icon @click="dialog1 = false" size="25" color="red">
              mdi-close-circle-outline
            </v-icon>
          </div>
          <div class="d-flex justify-center">
            <h4>Modify your booking</h4>
          </div>
          <div class="row mt-4">
            <div class="col-md-6">
              <span> Booking Reference </span>
              <br />
              <span style="font-size: 25px; font-weight: 600">{{
                tripId
              }}</span>
            </div>

            <div class="col-md-6">
              <v-select variant="outlined" v-model="bookstatus" :items="[
                'Cancel Booking',
                'Date Change',
                'Upgrade Booking',
                'Request Addons',
              ]"></v-select>
            </div>
          </div>

          <div v-if="datechange">
            <div class="row">
              <div class="col-md-4 d-flex justify-center">
                <h6>Outbound Between</h6>
              </div>
              <div class="col-md-4">
                <VueDatePicker ref="dpRef1" v-model="dedate1" :min-date="new Date()" :six-weeks="sixWeekMode" :max-date="new Date(
                  new Date().getFullYear() + 1,
                  new Date().getMonth(),
                  new Date().getDate()
                )
                  " :format="dateFormat" :hide-navigation="['time']" auto-apply :multi-calendars="multiCalender"
                  class="px-0" />
              </div>
              <div class="col-md-4">
                <VueDatePicker ref="dpRef1" v-model="dedate2" :min-date="dedate1" :six-weeks="sixWeekMode" :max-date="new Date(
                  new Date().getFullYear() + 1,
                  new Date().getMonth(),
                  new Date().getDate()
                )
                  " :format="dateFormat" :hide-navigation="['time']" auto-apply :multi-calendars="multiCalender"
                  class="px-0" />
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-4 d-flex justify-center">
                <h6>Inbound Between</h6>
              </div>
              <div class="col-md-4">
                <VueDatePicker ref="dpRef1" v-model="dedate3" :min-date="dedate1" :six-weeks="sixWeekMode" :max-date="new Date(
                  new Date().getFullYear() + 1,
                  new Date().getMonth(),
                  new Date().getDate()
                )
                  " :format="dateFormat" :hide-navigation="['time']" auto-apply :multi-calendars="multiCalender"
                  class="px-0" />
              </div>
              <div class="col-md-4">
                <VueDatePicker ref="dpRef1" v-model="dedate4" :min-date="dedate3" :six-weeks="sixWeekMode" :max-date="new Date(
                  new Date().getFullYear() + 1,
                  new Date().getMonth(),
                  new Date().getDate()
                )
                  " :format="dateFormat" :hide-navigation="['time']" auto-apply :multi-calendars="multiCalender"
                  class="px-0" />
              </div>
            </div>
          </div>

          <div class="d-flex justify-center" v-if="requstup">
            <p>
              Please mention your request below.Our support team will be in
              touch with you at the soonest posible
            </p>
          </div>

          <div>
            <div>Notes</div>
            <div class="mt-1 col-12">
              <v-textarea variant="outlined"></v-textarea>
            </div>
          </div>

          <div class="d-flex justify-center mt-4">
            <v-btn width="250px" color="#002d5b" @click="modifyreq(bookstatus)"
              style="height: 40px !important">Request</v-btn>
          </div>
        </v-card>
      </div>

      <!-- <div class="d-flex justify-center " style="    flex-flow: wrap;">
 <p>
     We have received your request.Our customer support team will get back to you at the soonest possible
 </p>
 
 <p>
     Please note that  all notification requests are subject to the airline team conditions,
     and may have applicable charges
 </p>
 </div> -->
    </div>
  </v-dialog>

  <v-dialog v-model="dialog2">
    <v-card class="w-50 m-auto py-4">
      <div class="d-flex justify-center flex-wrap">
        <h3>Your modification request has been recorded.</h3>
        <h5>Our customer support executive will get in touch with you soon.</h5>
      </div>

      <v-btn width="160px" color="#002d5b" class="m-auto mt-4" @click="this.dialog2 = false">OK</v-btn>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog3">
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">OOPS!</h3>
        <h5>
          Your request cannot be processed at this moment.

        </h5>
        <h6>Please contact customer support.</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="dialog3 = false" color="#002d5b">Try Again</v-btn>

          <v-btn to="/" color="#002d5b">Return to Home</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <div v-if="overlay">
    <dataloader></dataloader>
  </div>
</template>

<script>
import moment from "moment";
import airLogo from "@/airlinesLogo.json";
import dataloader from "@/components/dataLoder.vue";
import userData from "@/airport_icao.json";
import axios from "axios";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {

  components: {
    VueDatePicker,
    dataloader
  },
  data() {
    return {
      bookid: "",
      lastname: "",

      dialog: false,
      valid: false,
      viewbook: true,
      dateFormat: "dd MMM yyyy",
      overlay: false,
      mybooking: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      bookstatus: "Cancel Booking",
      requstup: false,
      datechange: false,
      dedate1: "",
      dedate2: "",
      dedate3: "",
      dedate4: "",
      retriveData: [],
      itineryData: [],
      searchcabintype: "",
      routtime: [],
      flightsegmet: [],
      flightsegmetkey: [],
      roundOfferId: [],
      airlogodata: [],
      Journey1: [],
      Journey: [],
      rountresult: [],
      returnData: [],
      tripId: "",
      bookingDate: "",
      baseprice: "",
      currency: "",
      totalprice: "",
      passengerDetail: [],
      depatureData: [],
      arrivalData: [],
      fromAirports: [],
      datareurn: false,
      triptype: "",
      mobilebtn: true,
      contactnum: "",
      passEmail: "",
      bookingStatus: "",
      bookingTime: "",
      countrycode: "",
      Flight1: [],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      multicity: [],
      rount: false,
      oneway: false,
      multi: false,

      portal_sitetitle: "",
      retreivebookingApi:"",
    };
  },


  // computed: {
  //   window.history.forward(); 
  //       function noBack() { 
  //           window.history.forward(); 
  //       }
  // },


  methods: {


    seleceprint() {
      this.mobilebtn = false
      const printContent = document.getElementById("printMe");
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContent.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
    },


    multigetdepcode(data) {
      console.log(data, 'ppppp')
      return data[0].depart_airport
    },
    multigetarrcode(data) {
      return data[data.length - 1].arrival_airport

    },
    multigetdeptime(data) {
      return moment(data[0].depart_datetime).format("DD-MMM-YYYY")
    },




    modify(data) {
      this.dialog1 = true;

      if (data == 'cancel') {
        this.bookstatus = 'Cancel Booking'
      }
      else {
        this.bookstatus = 'Date Change'
      }
    },



    modifyreq(data) {
      console.log(data, "ppppp");
      this.overlay = true;
      let result = "";
      if (data == "Cancel Booking") {
        result = "cancel";
      } else if (data == "Date Change") {
        result = "date_change";
      } else if (data == "Upgrade Booking") {
        result = "upgrade";
      } else if (data == "Request Addons") {
        result = "addon";
      }

      let modify = {
        request_type: "modify_request",
        booking_id: this.tripId,
        modify_request_type: result,
        outbound_date_between: "",
        inbound_date_between: "",
        notes: "test",
      };

      modify.outbound_date_between = `${moment(this.dedate1).format(
        "YYYY-MM-DD"
      )},${moment(this.dedate2).format("YYYY-MM-DD")}`;
      modify.inbound_date_between = `${moment(this.dedate3).format(
        "YYYY-MM-DD"
      )},${moment(this.dedate4).format("YYYY-MM-DD")}`;

      console.log(modify, "modifymodify");

      axios
        .post(this.modifyBookingUrl, modify, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response,'response')
          if (response.data.modify_response == "success") {
            setTimeout(() =>{
               this.overlay = false;
            this.dialog1 = false;
            this.dialog2 = true; 
            },2000)
          
          }
          else {
    this.dialog3 = true
      this.overlay = false;
}
          console.log(response.data.modify_response, "success");
        })
        .catch((error) => {
          setTimeout(() => {
              this.dialog3 = true
           this.overlay = false;
          },2000)
        
          console.log(error, "modify");
        });
    },





    getdob($event) {
      return moment($event).format("DD-MMM-YYYY");
    },
    getdepdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getdeptime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getarrtime(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    getdepcode(data) {
      console.log(data, "pppp");
      return data[0].depart_airport;
    },
    getarrcode(data) {
      console.log(data, "pppp");
      return data[data.length - 1].arrival_airport;
    },

    getstarttime(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },

    getdepdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getdeptime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },
    getarrdate1(data) {
      let depdata = data.split("T");
      return moment(depdata[0]).format("MMM DD");
    },
    getarrtime1(data) {
      let depdata = data.split("T");
      return moment(depdata[depdata.length - 1], "HH:mm:ss").format("HH:mm");
    },

    getdepcode1(data) {
      return data[0].depart_airport;
    },
    getarrcode1(data) {
      return data[data.length - 1].arrival_airport;
    },

    getstarttime1(data) {
      let depdata = data[0].depart_datetime.split("T");
      return moment(depdata[0]).format("ddd, DD MMM'YY");
    },


    capitalizeLastName() {
      this.lastname = this.lastname.charAt(0).toUpperCase() + this.lastname.slice(1);
    },



    bookingretrive() {
      this.airlogodata = airLogo;
      this.arrivalData = [];
      this.depatureData = [];
      this.retriveData.forEach((v) => {
        console.log(v, "dtaswww");
        this.baseprice = parseFloat(v.fare_info.price_per_person).toFixed(2);
        this.currency = v.fare_info.itin_currency;
        this.totalprice = parseFloat(v.fare_info.itin_total_fare).toFixed(2);
        this.passengerDetail = v.pax_info;

        this.itineryData = v.itinerary;
        this.contactnum = v.contact_info.paxphone;
        this.passEmail = v.contact_info.paxemail;
        this.tripId = v.booking_id;
        this.bookingStatus = v.status;
        console.log(this.bookingStatus, "bookingStatusbookingStatusbookingStatus")
        this.bookingTime = moment(v.booking_at).format("DD MMM YYYY HH:MM");
        this.searchcabintype = v.search_cabin_type
        this.countrycode = v.contact_info.country_code;

        for (let i = 0; i < this.airlogodata.length; i++) {
          this.itineryData.forEach((a) => {
            if (this.airlogodata[i].id == a.carrier) {
              a.logo = this.airlogodata[i].logo;
              a.flightname = this.airlogodata[i].name;
            }
          });
        }

        console.log(this.datareurn, "this.datareurnthis.datareurn");

        Object.keys(userData).forEach((icao) => {
          const airport = userData[icao];
          if (airport.iata !== "") {
            this.fromAirports.push({
              iata: airport.iata,
              icao: airport.icao,
              places: `${airport.name}`,
            });
          }
        });

        for (let i = 0; i < this.fromAirports.length; i++) {
          this.itineryData.forEach((a) => {
            if (this.fromAirports[i].iata == a.depart_airport) {
              a.depAirportName = this.fromAirports[i].places;
            }
            if (this.fromAirports[i].iata == a.arrival_airport) {
              a.arrAirportName = this.fromAirports[i].places;
            }
          });
        }
        this.itineryData.forEach((j) => {
          let $data = this.itineryData[this.itineryData.length - 1].flight_line
          // console.log(this.itineryData[this.itineryData.length-1].flight_line,'ydgvashyvv')
          if ($data == 2) {
            this.rount = true
            this.triptype = "ROUND TRIP";
            if (j.flight_line == 1) {
              this.depatureData.push(j)
            }
            else if (j.flight_line == 2) {
              this.arrivalData.push(j);
            }

          } else if ($data == 1) {
            this.oneway = true
            this.triptype = "ONEWAY TRIP";
            this.depatureData.push(j);
          }
          else if ($data > 2) {
            this.triptype = "MULTICITY";
            this.multi = true
            if (j.flight_line == 1) {
              // alert('ppp')
              this.Flight1.push(j)
              console.log(this.Flight1, '11')
            }
            if (j.flight_line == 2) {
              this.Flight2.push(j)
              console.log(this.Flight2, '22')
            }
            if (j.flight_line == 3) {
              this.Flight3.push(j)
              console.log(this.Flight3, '33')
            }
            if (j.flight_line == 4) {
              this.Flight4.push(j)
              console.log(this.Flight4, '44')
            }


          }
        });
        // this.itineryData.forEach((s) => {
        //   if (s.flight_line == 2) {

        //   } else if (s.flight_line == 1) {

        //   }
        // });
        console.log(this.depatureData, "this.depatureData");
        console.log(this.arrivalData, "this.depatureData");


        console.log(this.multicity, 'this.multicitythis.multicitythis.multicity')
      });
      this.gettimedep()
    },

    gettimedep() {

      let result = [];

      for (let i = 0; i < this.itineryData.length - 1; i++) {
        console.log(this.itineryData[i].depart_datetime, 'popppopoppopop');

        const time1 = new Date(this.itineryData[i + 1].depart_datetime);
        const time2 = new Date(this.itineryData[i].arrival_datetime);

        const diffInMilliseconds = Math.abs(time1 - time2);
        const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;

        result.push(`${hours.toString().padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`);
      }

      this.itineryData.forEach((v, j) => {
        v.overlayTime = result[j]
      })



      console.log(this.itineryData, 'itineryDataitineryData');
    },



    getColor() {
      if (this.bookingStatus === "Cancel") {
        return 'danger'
      }
      else if (this.bookingStatus === "Ticket issue") {
        return 'success'
      }
      else {
        return 'warning'
      }
    },



    getConfig() {
      // let getConfigData = this.$store.state.data

      const getConfigData = getConfigDataFromLocalStorage();

      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData = JSON.parse(portalLocal)
      if (getConfigData) {
        this.portal_sitetitle = getConfigData.payload.portal_configuration.site_title
        this.modifyBookingUrl = getConfigData.payload.portal_configuration.API_endpoints.modify_booking;
        this.retreivebookingApi = getConfigData.payload.portal_configuration.API_endpoints.retrieve_booking;
      }
    },


  },






  mounted() {



    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    console.log(this.dateOfBirth, "dobdob.....");
  },


  watch: {
    bookstatus(newVal) {
      console.log(newVal, "newVal");
      if (newVal == "Cancel Booking") {
        this.datechange = false;
        this.requstup = false;
      } else if (newVal == "Date Change") {
        this.datechange = true;
        this.requstup = false;
      } else if (newVal == "Upgrade Booking" || newVal == "Request Addons") {
        this.datechange = false;
        this.requstup = true;
      }
    },
  },




  created() {

    console.log(JSON.parse(this.$route.query.booking_id), 'this.$route.query.booking_idthis.$route.query.booking_id')
    console.log('consoleconsoleconsoleconsoleconsole')

    if (this.$route.query.uid) {

      let retreivebooking = {
        request_type: "retreive_booking",
        booking_id: this.$route.query.tripid,
        hit_id: this.$route.query.uid
        // pnr: "KZXASI",
        // last_name: "Ganesh",
        // portal_id: "port5678",
      }


      axios.post(
        this.retreivebookingApi,
        retreivebooking,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          console.log(response.data, "retrievebooking");
          this.retriveData.push(response.data)
        })
        .catch((error) => {
          console.log(error, "retreivebooking4444");
        });


    }
    else {
      this.booking_refId = JSON.parse(this.$route.query.booking_id)
      this.retriveData.push(this.booking_refId)

      this.local_id = this.$route.query.pass_id;
      this.Localoneway_id = this.$route.query.pass_uid;
      localStorage.setItem("delete_Id", this.Localoneway_id);
      localStorage.setItem("delete_Id", this.local_id);

    }

    this.getConfig()
    this.bookingretrive()


  },
};

</script>





<style scoped>
/* .booking-card{
  font-family: sans-serif;
} */

.f-size-16 {
  font-size: 16px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.booking-subtitle {
  font-size: 18px;
  font-weight: 600;
}

/* .booking-text p{
  font-size: 17px;
  font-weight: 400;
} */

.booking-text .book-span {
  color: #0768cf;
  cursor: pointer;
}

.booking-contacts {
  background-color: #f6f6f6;
}

.booking-contacts span {
  font-size: 18px;
}

.Booking-id-head {
  font-size: 14px;
  color: gray;
}

.Booking-id {
  font-size: 26px;
  font-weight: 700;
}

.Booking-status {
  font-size: 12px;
  text-transform: uppercase;
}

.v-card {
  border-radius: 10px !important;
}

.header-btn {
  background-color: transparent;
  text-transform: capitalize;
  box-shadow: none;
  font-size: large;
  margin: 0px 7px;
}

.custom-icon {
  width: 37px;
  height: 37px;
  border: 1px solid orange;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flight-det {
  font-size: 25px;
  font-weight: 600;
}

.trip1 {
  justify-content: center;
  display: flex;
  padding: 15px;
}

/* Departure & Return Section */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size {
  font-size: 16px;
}

.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.contion {
  display: none;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.provide-border {
  border-left: 1px solid lightgray;
}

>>>.v-timeline--vertical.v-timeline {
  grid-row-gap: 3px;
  height: auto !important;
}

.f-family {
  font-family: sans-serif;
  font-size: 15px;
}

.MyAirBudget1 {
  display: none;
}

/* Departure & Return Section */

@media only screen and (max-width: 992px) {
  .check-icon {
    margin-right: 40px;
  }

  .book-detail {
    text-align: left;
    padding-top: 10px;
  }

  .flight-det {
    font-size: 15px;
  }
}


@media only screen and (min-width: 768px) {
  .print-btn-1 {
    display: flex;
  }

  .print-btn-mbl {
    display: none;
  }
}


@media only screen and (max-width: 768px) {
  .provide-border {
    border-left: none;
  }

  .class-box {
    justify-content: flex-end;
  }

  .print-btn-mbl {
    display: block;
  }

  .print-btn-1 {
    display: none;
  }
}



@media only screen and (max-width: 576px) {
  .hide-btn-desk .desk-Btn {
    display: none !important;
  }

  .mbl-Btn {
    display: block !important;
  }

}

@media only screen and (min-width: 577px) {

  .mbl-Btn {
    display: none !important;
  }

}

@media only screen and (min-width: 480px) {

  .mobile-table {
    display: none;
  }


  /* .desk-Btn {
    display: block !important;
  } */
}

@media only screen and (max-width: 480px) {

  .Booking-id {
    font-size: 18px;
    font-weight: 700;
  }

  .Booking-status {
    font-size: 12px;
    text-transform: uppercase;
  }

  .mbl-Btn {
    display: block !important;
  }

  .desk-Btn {
    display: none !important;
  }

  .contion {
    display: block;
  }

  .MyAirBudget {
    display: none;
  }

  .MyAirBudget1 {
    display: block;
  }

  .mobile-table {
    display: block;
  }

  .desktop-table {
    display: none;
  }

  .print-btn-1 {
    display: none;
  }

  .f-family {
    font-size: 12px;
  }

  .airlogo-size {
    width: 25px !important;
  }

  .airline-name {
    font-size: 10px;
  }

  .f-size-16 {
    font-size: 16px;
  }

  .TERMS {
    display: none;
  }

  .f-size-14 {
    font-size: 14px;
  }

  .f-size-13 {
    font-size: 13px;
  }

  .f-size-12 {
    font-size: 12px;
  }

  .f-size-11 {
    font-size: 11px;
  }

  .f-size-10 {
    font-size: 10px;
  }

  .f-size-9 {
    font-size: 10px;
  }

  .fw-500 {
    font-weight: 600;
  }
}

>>>.v-textarea .v-field--no-label textarea,
.v-textarea .v-field--active textarea {
  opacity: 1;
  height: 50px;
}

>>>.dp__pointer {
  cursor: pointer;
  font-size: 13px;
}

.print-btn-1 {
  box-shadow: none;
  font-size: large;
  /* margin: 0px 7px; */
  color: green;
  outline: 1px solid;
  width: 100px;
  height: 35px;
  border-radius: 4px;
}

.print-btn-2 {
  font-size: 25px;
  height: 35px;
  width: 35px;
  box-shadow: none;
  margin-top: 4px;
  color: green;
  outline: 2px solid;
  border-radius: 4px;
}






.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>


<template>

    <div class="preloader d-none">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="load-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
    <div class="pagewrap modify-search">
        <div class="head-wrapper">
            <div class="flight-search">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12">
                            <span class="fw-bold theme-text-white">{{ $t("aboutUsContent.heading") }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- body section -->
        <div class="content-section">
            <!-- flight result page - oneway start -->
            <section class="theme-bg-white pt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-2 mb-4 mb-md-0" data-aos="fade-up">
                           <div class="sidebar d-flex flex-column theme-border-radius theme-bg-white theme-box-shadow" :class="{ 'sticky': isSticky }">
                                <a href="#aboutContent" class="border-bottom p-4 theme-text-accent-one" @click.prevent="scrollToSection('aboutContent')">{{ $t("aboutUsContent.company") }}</a>
                                <a href="#our-team" class="border-bottom p-4 theme-text-accent-one">{{ $t("aboutUsContent.team") }}</a>
                                <a href="#why-us" class="border-bottom p-4 theme-text-accent-one" @click.prevent="scrollToSection('why-us')">{{ $t("aboutUsContent.whyus") }}</a>
                            </div>
                        </div>
                        <div class="col-12 col-md-10" id="aboutContent" data-aos="fade-up" data-aos-delay="200">
                            <section v-html="aboutContent"></section>
                        </div>
                        <!-- <div class="col-12 col-md-12 mt-4" data-aos="fade-down" data-aos-delay="300">
                            <img :src="require('@/assets/images/about-us-bg.jpg')" alt="about us"
                                class="img-fluid w-100">
                        </div> -->
                        <!-- <div class="col-12 col-md-6 mt-5 align-self-center" data-aos="fade-up" data-aos-delay="300">
                            <p class="company-qotes fst-italic">Our work does make sense
                                only if it is a faithful witness
                                of his time.
                                <span class="d-flex mt-5 font-medium">John Doe - Director</span>
                            </p>

                        </div> -->
                        <!-- team meeting collarge -->
                        <!-- <div class="col-12 col-md-6 mt-5" data-aos="fade-down" data-aos-delay="400">
                            <img :src="require('@/assets/images/aboutus-team.jpg')" alt="about us"
                                class="img-fluid w-100 about-pic">
                        </div> -->
                        <!-- team picture collarge -->
                        <!-- <div class="col-12 col-md-6 mt-5" data-aos="fade-up" data-aos-delay="400">
                            <img :src="require('@/assets/images/about-team.png')" alt="about us"
                                class="img-fluid w-100">
                        </div> -->
                        <!-- our team content -->
                        <!-- <div class="col-12 col-md-6 mt-5 align-self-center" id="our-team" data-aos="fade-down"
                            data-aos-delay="300">
                            <h2 class="fs-2 mb-3">Our Team
                            </h2>
                            <p class="mb-3 theme-text-accent-two font-medium lh-lg">Nunc lacinia vestibulum sem id
                                fringilla. Nulla facilisi. Aenean volutpat leo ac nunc laoreet, vel lobortis dolor
                                maximus. Cras sodales sollicitudin felis, eu bibendum ligula blandit non. Nulla non
                                consectetur arcu. Aenean lacinia posuere lacinia. Interdum et malesuada fames ac ante
                                ipsum primis in faucibus.
                                <br><br>
                                Mauris condimentum, arcu ac condimentum congue, purus nisi fringilla orci, bibendum
                                aliquet lorem arcu sed leo. Donec nulla erat, congue vel facilisis a, dapibus ut odio.
                                Curabitur lacinia risus est, in auctor purus viverra eu. Nulla et hendrerit ipsum. In
                                euismod arcu orci, ut vestibulum eros venenatis vitae.
                            </p>
                            <div class="row">
                                <div class="col-12 col-md-3">
                                    <h3 class="fs-1 fw-bold mb-2">600</h3>
                                    <p class="font-medium theme-text-accent-two mb-0">million flight
                                        sustainable work</p>
                                </div>
                                <div class="col-12 col-md-3">
                                    <h3 class="fs-1 fw-bold mb-2">700</h3>
                                    <p class="font-medium theme-text-accent-two mb-0">million flight
                                        sustainable work</p>
                                </div>
                                <div class="col-12 col-md-3">
                                    <h3 class="fs-1 fw-bold mb-2">1.2</h3>
                                    <p class="font-medium theme-text-accent-two mb-0">million flight
                                        sustainable work</p>
                                </div>
                                <div class="col-12 col-md-3">
                                    <h3 class="fs-1 fw-bold mb-2">110</h3>
                                    <p class="font-medium theme-text-accent-two mb-0">million flight
                                        sustainable work</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- why us section -->
                <div class="why-us mt-5 py-5 theme-bg-accent-three" id="why-us">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-md-12 text-center mb-5" data-aos="fade-up" data-aos-delay="500">
                                <h4 class="mb-3 fs-2">{{ $t("aboutUsContent.text1") }} {{ portal_id }}</h4>
                                <p class="mb-0 max-1 theme-text-accent-two">{{ $t("aboutUsContent.text2") }} {{ portal_id }} {{ $t("aboutUsContent.text3") }} {{ $t("aboutUsContent.text4") }}</p>
                            </div>
                            <div v-for="data in gethowitworksData" :key="data" class="col-12 col-md-3 text-center mb-3 mb-md-0" data-aos="fade-up"
                                data-aos-delay="600">
                                <div class="d-inline-flex mb-3 p-4 rounded-circle theme-bg-white theme-box-shadow">
                                    <img :src="data.image" alt="about us"
                                        class="img-fluid">
                                </div>
                                <h2 class="mb-3 fw-bold fs-5">{{ data.headline }}
                                </h2>
                                <p class="mb-0 max-1 theme-text-accent-two font-medium">{{ data.content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    </div>



    <!-- <div style="background-color:#ffffffd9">

        <section class="newsletter-wrapper footer-inner-wrap d-flex position-relative">
            <div class="container mt-3 text-center">
                <h2 class="title text-uppercase">About us</h2>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-center">
                            <p>Thank you for visiting BuyMyTrip.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="footr-content-wrap">
            <div>
                <div class="container">
                    <h4><strong>Company</strong></h4>

                    <p>BuyMyTrip is one of the fastest growing online travel company in Canada. With our dedicated team of
                        skilled software engineers and travel specialist we decided to take our expertise to the web and
                        develop amazing software to provide a full range of travel products online.</p>

                    <p>We provide Instant E-tickets with the cheapest possible price with no hidden fees or no extra
                        charges. We are always there, through your entire journey for any customer support needed.</p>

                    <p>&nbsp;</p>

                    <h4><strong>Technology</strong></h4>

                    <p>BuyMyTrip has direct access to many wholesalers, airlines and hotel suppliers in Canada. The main aim
                        is to simplify the booking procedure and to eliminate the need to compare prices. You can stay up to
                        date on schedule changes, alerts or any details regarding your upcoming booking through your mobile
                        devices. We are constantly working towards getting the best products indicating a right balance of
                        Quality and Cost.</p>

                    <p>&nbsp;</p>

                    <h4><strong>Holidays</strong></h4>

                    <p>We offer an extensive range of great holiday package of unrivalled value and quality, whether you're
                        looking for a City break, a sunshine getaway or a sporting holiday. Our travel packages make sure
                        that every holiday is packed with unparalleled fun with the extraordinary moments especially for you
                        which you will cherish for a life time. We are constantly improvising our packages to keep abreast
                        of the changing times and provide a fun filled holiday.</p>

                    <p>&nbsp;</p>

                    <h4><strong>Stay</strong></h4>

                    <p>By booking on BuyMyTrip you save your valuable time and money. We are connected globally with over
                        110,000 hotels. We cover a wide range of hotels be it a basic value hotel, a 5 star luxury resort or
                        independent boutique.</p>

                    <h4><strong>Insurance Services</strong></h4>

                    <p>We also offer travel insurance across with one of the world's largest insurance providers, AIG.</p>
                </div>
            </div>
        </section>
        <br>
    </div> -->
</template>


<script>

import axios from "axios";
import App from "@/App.vue";


import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    component:{
        App
    },
    data() {
        return {

            aboutContent: "",
            getAboutUrl: "",
            aboutType: "",
            isSticky: false,
            portal_id:"",
            howitworksApi:"",
            gethowitworksData:[],
        }
    },

    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
            },
            handleScroll() {
            this.isSticky = window.scrollY > 0; 
            },

        gethowitworks(){
            console.log(this.howitworksApi, 'getTopdestinationdatagetTopdestinationdata')
            axios.get(this.howitworksApi.url, {
             headers: {
                 "Content-Type": this.howitworksApi.type,
             },
            })
            .then((response) => {
                if(!response.data.error){
                   this.gethowitworksData = response.data;
                   console.log(this.gethowitworksData, 'gethowitworksDatagethowitworksData..0')
                }
                else{
                //  this.gethowitworksData = topdestinationsdata;
                 console.log(this.gethowitworksData, 'gethowitworksDatagethowitworksData..1')
                }
            })
            .catch((error) => {
             //  this.getTopdestinationdata = topdestinationsdata;
                console.log(error, 'erroroor.....')
            })
        },
        

        getConfig() {
            let locale = this.getLangname;

            const getConfigData = getConfigDataFromLocalStorage();
            if (getConfigData) {
                // this.getAboutUrl = getConfigData.payload.portal_configuration.content_data.aboutus.url
                // this.aboutType = getConfigData.payload.portal_configuration.content_data.aboutus.type
                this.portal_id = getConfigData.payload.portal_configuration.portal_id
                // console.log(getConfigData, 'head...about..1..')
                
                
                let siteContentApi = getConfigData.payload.portal_configuration.site_content_data[locale];
                if(siteContentApi){
                    this.getAboutUrl = siteContentApi.aboutus.url
                    this.aboutType = siteContentApi.aboutus.type
                    this.howitworksApi = siteContentApi.whyaboutcontent;
                    this.getabout();
                  if(this.howitworksApi) this.gethowitworks();
                }
            }
        },

        getabout() {
            axios.get(this.getAboutUrl, {
                headers: {
                    "Content-Type": this.aboutType,
                },
            })
                .then((response) => {
                    this.aboutContent = response.data
                })

                .catch((error) => {
                    console.log(error, 'erroroor.....')
                })
        },
        updateContent() {
            this.getLangname = this.$i18n.locale;
            console.log(this.getLangname,'getcontententUrl...1');
            this.getConfig();
        }
    },
    watch: {
    "$i18n.locale": {
      handler: function () {
        this.updateContent();
      },
      deep: true,
    },
  },

    created() {
        this.updateContent();
        this.getConfig();
    },
     mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
}

</script>
<style scoped>
.sticky {
  position: sticky;
  top: 15%;
  background-color:#ffffff !important;
}
</style>
<template>
  <div>
    <v-text-field
      variant="outlined"
      class="login-form"
      v-model="billingDetails.name"
      :rules="[(v) => !!v || 'Name is required']"
      :disabled="billingDetailsData"
    ></v-text-field>

    <v-btn @click="toggleDisable">Toggle Disable</v-btn>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      billingDetails: {
        name: "",
      },
      billingDetailsData: true,
    };
  },
  methods: {
    toggleDisable() {
      this.billingDetailsData = !this.billingDetailsData;
    },
  },
};
</script>
